import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import SecondaryDropdown from '@src/components/SecondaryDropdown/Index'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { getRegionNameByCode } from '@src/utils/tools'

const styles = theme => ({
  select: {
    width: 100,
    height: 30,
    background: '#fff',
    marginLeft: 20,
    borderRadius: 4,
    '@global': {
      'div:first-child': {
        '@global': {
          'div:nth-of-type(1)': {
            '@global': {
              'div:first-child': {
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
                paddingLeft: 20,
                height: 30,
                width: 70,
                lineHeight: 2
              }
            }
          }
        }
      }
    }
  },
  selectContainer: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center'
  },
  dropdownContainer: {
    position: 'relative',
    padding: '8px 20px',
    borderRadius: '4px',
    background: '#fff',
    marginLeft: 10,
    color: '#000',
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
})

@inject(
  'authInfo',
  'contentManage',
  'videoFilter',
  'newsFilter',
  'categoryManage',
  'maskWordManage'
)
@observer
class OtherMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isShowDropdown: false
    }
  }

  componentDidMount () {
    this.setDefaultRegions()
  }

  componentDidUpdate (prevProps, prevState) {
    this.setDefaultRegions()
  }

  setDefaultRegions () {
    const { authInfo } = this.props
    const { user } = authInfo

    if (authInfo.region && authInfo.region.code) {
      return
    }

    const code = (user.manageRegion || [])[0]
    const region = {
      name: getRegionNameByCode(code),
      code
    }
    console.log('USER Manage Region>>>', code)

    authInfo.changeRegion(region)
  }

  handleDropdownChange = (contentType, path) => {
    const {
      contentManage,
      videoFilter,
      newsFilter,
      history,
      categoryManage,
      maskWordManage
    } = this.props

    this.setState({
      isShowDropdown: false
    })

    contentManage.updateContentType(contentType)
    if (path === '/newsFeed/contentManage') {
      if (contentType.toUpperCase() === 'VIDEO') {
        videoFilter.resetFilters()
      } else {
        newsFilter.resetFilters()
      }
    }

    if (path === '/categoryManage') {
      categoryManage.resetPage()
    }

    if (path === '/maskWordManage') {
      maskWordManage.resetPage()
    }

    if (history.location.pathname === path) {
      history.push('/test')
      setTimeout(() => {
        history.replace(path)
      })
    } else {
      history.push(path)
    }
  }

  changeReion = e => {
    const { authInfo, history } = this.props
    authInfo.changeRegion({
      name: e.target.key,
      code: e.target.value
    })

    let currentPath = history.location.pathname

    history.push('/test')
    setTimeout(() => {
      history.replace(currentPath)
    })
  }

  render () {
    const { authInfo, contentManage, classes } = this.props
    const { isShowDropdown } = this.state

    if (!authInfo.showMenus) {
      return null
    }
    return (
      <div className={classes.selectContainer}>
        <TextField
          className={classes.select}
          // margin="none"
          // variant="standard"
          value={authInfo.region.code || ''}
          select
          onChange={this.changeReion}
        >
          {authInfo.regionList &&
            authInfo.regionList.length &&
            authInfo.regionList.map(region => {
              // 如果角色不是admin，然后会对region地区进行判断是否包含。如果是admin，即角色是2，则将全部展示出来。
              if (
                authInfo.user.role !== 2 &&
                authInfo.user.manageRegion.includes(region.code)
              ) {
                return (
                  <MenuItem key={region.name} value={region.code}>
                    {region.name}
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem key={region.name} value={region.code}>
                    {region.name}
                  </MenuItem>
                )
              }
            })}
        </TextField>
        <div
          className={classes.dropdownContainer}
          onMouseEnter={() => {
            this.setState({ isShowDropdown: true })
          }}
          onMouseLeave={() => {
            this.setState({ isShowDropdown: false })
          }}
        >
          {contentManage.contentType}
          {authInfo.menus &&
            isShowDropdown && (
            <SecondaryDropdown
              menus={authInfo.menus}
              ChangeHandle={this.handleDropdownChange}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(OtherMenu))

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

const styles = theme => ({
  root: {
    height: 'calc(100vh - 124px)',
    backgroundColor: '#fff',
    overflow: 'auto',
    borderTop: '1px solid #c7c7c7',
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  table: {
    width: 'calc(100vw - 280px)',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    tableLayout: 'fixed'
  },
  headTableRow: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing.unit * 5,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: '#EEEEEF'
  },
  head: {
    color: '#000',
    fontSize: 14,
    borderBottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2
  },
  thbodyRow: {
    display: 'flex',
    minHeight: theme.spacing.unit * 6,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '@global': {
      td: {
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        overflow: 'hidden',
        paddingRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        maxHeight: theme.spacing.unit * 6,
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderBottom: 0
      },
      'td:last-child': {
        paddingRight: theme.spacing.unit
      }
    }
  },
  operate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  operateBtn: {
    width: 30,
    height: 30,
    lineHeight: '35px',
    fontSize: 14,
    color: 'rgba(0, 131, 143, .9)',
    padding: 0,
    cursor: 'pointer'
  },
  noContent: {
    width: '100%',
    textAlign: 'center',
    fontSize: 24,
    margin: '10px 0'
  }
})

@inject('statistical')
@observer
class StatiticalList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    this.props.statistical.resetInfo()
  }
  render () {
    const { classes, statistical } = this.props
    return (
      <div className={classes.root}>
        {statistical.infoList && statistical.infoList.length ? (
          <div className={classes.tableContent}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headTableRow}>
                  <TableCell className={classes.head} style={{ width: '15%' }}>
                    startTime
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '15%' }}>
                    endTime
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '20%' }}>
                    provider
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '15%' }}>
                    region
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '15%' }}>
                    language
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '10%' }}>
                    type
                  </TableCell>
                  <TableCell className={classes.head} style={{ width: '10%' }}>
                    count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistical.infoList.map((row, index) => {
                  return (
                    <TableRow key={index} className={classes.thbodyRow}>
                      <TableCell style={{ width: '15%' }}>
                        {moment(row.startTime).format('YYYY-MM-DD HH:mm')}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {moment(row.endTime).format('YYYY-MM-DD HH:mm')}
                      </TableCell>
                      <TableCell style={{ width: '20%' }}>
                        {row.provider}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {row.region}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {row.language}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>{row.type}</TableCell>
                      <TableCell style={{ width: '10%' }}>
                        {row.count}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className={classes.noContent}>No Content Found</p>
        )}
      </div>
    )
  }
}

StatiticalList.PropTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(StatiticalList)

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { GameConf } from '@src/utils/constant'
import OptionSort from '@src/components/OptionSort'

const styles = theme => ({
  root: {
    height: 60,
    minHeight: 60,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0
  },
  sort: {
    display: 'flex',
    alignItems: 'center'
  },
  menu: {
    padding: '11px 8px',
    width: 200
  },
  sortType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing.unit
  },
  up: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    cursor: 'pointer'
  },
  down: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    cursor: 'pointer'
  }
})

@inject('gameManage', 'gameFilter')
@observer
class GameOperate extends React.Component {
  handleSortOption = (currentChange, result) => {
    const { gameManage, gameFilter } = this.props
    let attr, value

    if (currentChange === 'topActive') {
      // 改变升序降序
      attr = 'sortType'
      value = result.topActive ? 'ASC' : 'DESC'
    } else {
      // 改变排序字段
      attr = 'sortField'
      value = result.currentValue
    }

    gameFilter.updateFilter(attr, value)
    gameManage.getGameList()
  }
  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Typography style={{ fontSize: 18 }}>
          Game/
          <span style={{ color: 'blue', display: 'inline', fontSize: 18 }}>
            View List
          </span>
        </Typography>
        <div className={classes.sort}>
          <OptionSort
            currentOptions={GameConf.gameReviewSortOptions}
            handleSortOption={this.handleSortOption}
          />
        </div>
      </div>
    )
  }
}

GameOperate.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles, { withTheme: true })(GameOperate)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import GameFilter from './GameFilter'
import ReviewContent from './ReviewContent'

const styles = () => ({
  root: {
    display: 'inline-flex'
  }
})

class GameReview extends React.Component {
  // constructor (props) {
  //   super(props)
  // }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <GameFilter />
        <ReviewContent />
      </div>
    )
  }
}

GameReview.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GameReview)

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import { Input } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import PassIcon from '@material-ui/icons/CheckCircleOutline'
import RejectIcon from '@material-ui/icons/HighlightOff'
import DetailIcon from '@material-ui/icons/Pageview'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import moment from 'moment'

import TablePagination from '@src/components/TablePaginationActions'
import DeleteDialog from '@src/components/DeleteDialog'
import GameEdit from './GameEdit'
import { GameConf } from '@src/utils/constant'
import Toast from '@src/components/Toast/index'

const styles = theme => ({
  root: {
    width: 'calc(100vw - 250px)',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    background: '#fff',
    borderLeft: '1px solid #c7c7c7',
    paddingBottom: 50
  },
  header: {
    position: 'relative',
    height: 60,
    minHeight: 60
  },
  rowHeader: {
    color: '#000',
    fontSize: 14,
    fontWeight: 700
  },
  item: {
    position: 'absolute',
    top: '50%',
    paddingLeft: 10,
    transform: 'translateY(-50%)',

    '& > em': {
      color: '#52b3d8',
      fontWeight: 'bold'
    }
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: 10,
    margin: 5,
    border: '1px solid rgba(0, 0, 0, .2)',
    cursor: 'pointer'
  },
  sort: {
    display: 'flex',
    float: 'right',
    right: 5,
    top: 0,
    alignItems: 'center'
  },
  menu: {
    padding: '11px 8px',
    width: 200
  },
  sortType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing.unit
  },
  up: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    cursor: 'pointer'
  },
  down: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    cursor: 'pointer'
  },
  operateBtn: {
    width: 30,
    height: 30,
    lineHeight: '35px',
    textAlign: 'center',
    outline: 'none',
    background: '#fff',
    fontSize: 14,
    color: 'rgba(0, 131, 143, .9)',
    padding: 0,
    margin: 5,
    cursor: 'pointer'
  },
  customContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  table: {
    '& th': {
      padding: '0px 8px'
    },
    '& td': {
      padding: '0px 8px'
    },
    '& th:last-child': {
      paddingRight: 0
    },
    '& td:last-child': {
      paddingRight: 0
    }
  },
  page: {
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    width: 'calc(100% - 250px)',
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid  #c7c7c7',
    backgroundColor: '#fff'
  },
  picView: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

@inject('gameReview', 'gameManage', 'authInfo')
@observer
class ReviewContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gameInfo: null,
      openEdit: false,
      openDelete: false,
      deleteMsg: '',
      openReject: false,
      rejectReason: '',
      picView: false,
      picSelectedURL: '',
      pageSize: 10
    }
    this.rejectId = null
    this.deleteId = null
    this.region = ''
  }

  componentDidMount() {
    this.getList()
  }

  getList() {
    const { gameReview } = this.props
    gameReview.getGameList()
  }

  handleSortOption = (currentChange, result) => {
    const { gameReview } = this.props
    let attr, value

    if (currentChange === 'topActive') {
      // 改变升序降序
      attr = 'sortType'
      value = result.topActive ? 'ASC' : 'DESC'
    } else {
      // 改变排序字段
      attr = 'sortField'
      value = result.currentValue
    }

    gameReview.updateFilter(attr, value)
    gameReview.getGameList()
  }

  handleChange = event => {
    this.setState({
      currentValue: event.target.value
    })
  }
  setSortType = type => {
    this.setState(
      {
        topActive: !!type
      },
      () => { }
    )
  }

  handleOperation = (clickType, item) => e => {
    switch (clickType) {
      case 'pass':
        this.gamePass(item)
        break
      case 'edit':
        this.editGameInfo(item)
        break
      case 'preview':
        this.gamePreview(item)
        break
      case 'reject':
        this.openRejectDialog(item)
        break
      case 'delete':
        this.deleteGame(item)
        break
    }
  }

  async gamePass(item) {
    const { gameReview } = this.props

    let result = await gameReview.reviewPass({
      docid: item.docId,
      region: item.region
    })
    const { data } = result

    if (data && data.status) {
      Toast.success('Pass Success.')
      this.getList()
    } else {
      const msg = result.data && result.data.msg
      Toast.error(msg)
    }
  }

  editGameInfo = async item => {
    const { gameManage } = this.props
    let result = await gameManage.getGameDetail(
      item.docId,
      item.audit,
      item.region
    )
    if (result) {
      if (result.data.status && result.data.result) {
        this.setState({
          openEdit: true,
          gameInfo: result.data.result
        })
      } else {
        Toast.error('获取信息失败...')
      }
    } else {
      Toast.error('获取信息失败...')
    }
  }

  gamePreview(item) {
    window.open(item.url)
  }

  openRejectDialog(item) {
    this.rejectId = item.docId
    this.region = item.region
    this.setState({
      openReject: true,
      rejectReason: ''
    })
  }

  gameReject = async () => {
    const { gameReview } = this.props
    const { rejectReason } = this.state
    const reason = rejectReason.trim()

    if (reason === '') {
      Toast.warning('审核不通过原因不可以为空.')
      return
    }

    let result = await gameReview.reviewReject({
      docid: this.rejectId,
      region: this.region,
      reason: rejectReason
    })
    const { data } = result

    if (data && data.status) {
      Toast.success('Rejected Success.')
      this.setState({
        openReject: false
      })
      this.getList()
    } else {
      const msg = result.data && result.data.msg
      Toast.error(msg)
    }
  }

  deleteGame(item) {
    this.deleteId = item.docId
    this.region = item.region
    this.setState({
      openDelete: true,
      deleteMsg: item.title
    })
  }

  handleChangePage = (event, page) => {
    const { gameReview } = this.props

    if (page + 1 === gameReview.filters.pageNum) {
      return
    }

    gameReview.updateFilter('pageNum', page + 1)
    gameReview.getGameList()
  }

  handleChangePageSize = pageSize => {
    const { gameReview } = this.props
    gameReview.filters.pageSize = pageSize
    gameReview.getGameList()
  }

  handlePageSizeInput = event => {
    const value = event.target.value
    this.setState({
      pageSize: value
    })
  }

  handlePageSizeInputEnter = event => {
    if (event.keyCode === 13) {
      let pageSize = Number(this.state.pageSize)
      const { count } = this.props
      if (pageSize > count) {
        pageSize = count
      } else if (pageSize < 0) {
        pageSize = 0
      }
      this.setState({
        pageSize
      })
      this.handleChangePageSize(pageSize)
    }
  }

  alterGameInfo = editItem => {
    const { gameInfo } = this.state
    this.setState({
      gameInfo: Object.assign({}, gameInfo, {
        [editItem.name]: editItem.value
      })
    })
  }

  submitGameInfo = async info => {
    const { gameReview, authInfo } = this.props
    // const { gameInfo } = this.state;
    const { title, url, startTime, endTime, provider, source, tags } = info
    const _title = title
    const _url = url
    const _tags = []
    if (authInfo.region.code === 'RU') {
      tags.forEach(element => {
        _tags.push(element)
      })
    } else {
      tags.forEach(d => {
        if (typeof d === 'string') {
          _tags.push(d)
        } else if (typeof d === 'object') {
          _tags.push(d.label)
        }
      })

      if (_tags.length === 0 || _tags.length > 3) {
        Toast.warning('标签最少一个，最多三个！')
        return
      }
      if (_tags.length === 3) {
        if (_tags[0] === _tags[1]) {
          Toast.warning('标签选择应该不一样！')
          return
        }
      }
    }

    // 对于异步组件，他的optionlist是有原始数据组装的，用户在修改相应的字段提交时
    // 需要提供原始数据
    const _provider = provider._origin ? provider._origin : provider
    const _source = source._origin ? source._origin : source
    if (!_title || !_url || !startTime || !endTime) {
      Toast.warning('字段不可以为空哦！')
      return
    }

    if (startTime > endTime) {
      Toast.warning('开始时间不可以大于结束时间哦！')
      return
    }

    if (!_provider || !_provider.pid) {
      Toast.warning('Provider不可以为空哦！')
      return
    }

    const params = Object.assign({}, info, {
      title: _title.trim(),
      url: _url.trim(),
      provider: _provider,
      source: _source,
      tags: _tags
    })

    const response = await gameReview.editGame(params)
    const data = response.data

    if (data && data.status) {
      Toast.success('Edit Success.')
      this.setState({
        openEdit: false
      })
      this.getList()
    } else {
      Toast.error('保存失败...')
    }
  }

  handleClose = openType => () => {
    this.setState({
      [openType]: false
    })
  }

  ensureDeletePost = async () => {
    const { gameReview } = this.props

    let result = await gameReview.deleteGame({
      docid: this.deleteId,
      region: this.region
    })
    const { data } = result

    if (data && data.status) {
      Toast.success('Delete Success.')
      this.setState({
        openDelete: false
      })
      this.getList()
    } else {
      const msg = result.data && result.data.msg
      Toast.error(msg)
    }
  }

  handleZoomIn = e => {
    this.setState({
      picView: true,
      picSelectedURL: e.target.src
    })
  }

  handleZoomOut = e => {
    if (e.target.nodeName === 'DIV') {
      this.setState({
        picView: false
      })
    }
  }

  render() {
    const { classes, gameReview } = this.props
    const {
      gameInfo,
      openEdit,
      openDelete,
      deleteMsg,
      openReject,
      rejectReason,
      picView,
      picSelectedURL
    } = this.state

    return (
      <div className={classes.root}>
        <Paper className={classes.header}>
          <span className={classes.item}>
            Game/
            <em>Reviewed</em>
          </span>
          {/* <OptionSort
            currentOptions={GameConf.gameReviewSortOptions}
            handleSortOption={this.handleSortOption}
          /> */}
        </Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {GameConf.gameReviewHead.map(item => (
                <TableCell key={item} className={classes.rowHeader}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {gameReview.gameList.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.docId}
                </TableCell>
                <TableCell align="right">
                  <img
                    className={classes.image}
                    src={row.images && row.images[0] && row.images[0].url}
                    onClick={this.handleZoomIn}
                  />
                </TableCell>
                <TableCell align="right">{row.title}</TableCell>
                <TableCell align="right">{row.tags.join(',')}</TableCell>
                <TableCell align="right">
                  {moment(row.addTime).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell align="right">
                  {row.state === 1 ? '未审核' : '驳回'}
                </TableCell>
                <TableCell align="right">
                  {(row.provider && row.provider.name) || ''}
                </TableCell>
                <TableCell align="right">
                  {(row.source && row.source.name) || ''}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Pass" placement="top">
                    <PassIcon
                      className={classes.operateBtn}
                      onClick={this.handleOperation('pass', row)}
                    />
                  </Tooltip>
                  <Tooltip title="Edit" placement="top">
                    <EditIcon
                      className={classes.operateBtn}
                      onClick={this.handleOperation('edit', row)}
                    />
                  </Tooltip>
                  <Tooltip title="Preview" placement="top">
                    <DetailIcon
                      className={classes.operateBtn}
                      onClick={this.handleOperation('preview', row)}
                    />
                  </Tooltip>
                  <Tooltip title="Reject" placement="top">
                    <RejectIcon
                      className={classes.operateBtn}
                      onClick={this.handleOperation('reject', row)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <DeleteIcon
                      className={classes.operateBtn}
                      onClick={this.handleOperation('delete', row)}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <GameEdit
          open={openEdit}
          gameInfo={gameInfo}
          editGameInfo={this.alterGameInfo}
          submitGameInfo={this.submitGameInfo}
          handleClose={this.handleClose('openEdit')}
        />
        <DeleteDialog
          openDelete={openDelete}
          handleCloseDialog={this.handleClose('openDelete')}
          deleteMsg={deleteMsg}
          handleDelete={this.ensureDeletePost}
        />
        <div className={classes.page}>
          <TablePagination
            rowsPerPage={gameReview.filters.pageSize}
            count={Number(gameReview.filters.sum)}
            page={gameReview.filters.pageNum - 1}
            onChangePage={this.handleChangePage}
          />
          <div className={classes.customContainer}>
            <span>perPageSize：</span>
            <Input
              id="customPage"
              value={this.state.pageSize}
              onChange={this.handlePageSizeInput}
              type="number"
              onKeyUp={this.handlePageSizeInputEnter}
              classes={{
                inputType: classes.pageNumberInput
              }}
            />
          </div>
        </div>
        <Dialog
          open={openReject}
          onClose={this.handleClose('openReject')}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reject</DialogTitle>
          <DialogContent style={{ width: 600 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Reject Reason"
              fullWidth
              multiline
              value={rejectReason}
              onChange={event =>
                this.setState({ rejectReason: event.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.gameReject} color="primary">
              确定
            </Button>
            <Button onClick={this.handleClose('openReject')} color="primary">
              取消
            </Button>
          </DialogActions>
        </Dialog>

        <div
          className={classes.picView}
          style={picView ? { visibility: 'visible' } : { visibility: 'hidden' }}
          onClick={this.handleZoomOut}
        >
          <img src={picSelectedURL} alt="picSelected" />
        </div>
      </div>
    )
  }
}
ReviewContent.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(ReviewContent)

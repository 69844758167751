import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import GameFilter from './GameFilter'
import GameList from './GameList'
import GameOperate from './GameOperate'

const styles = {
  root: {
    display: 'flex'
  },
  leftPannel: {
    width: 250,
    height: '100%',
    backgroundColor: 'cyan'
  },
  content: {
    width: 'calc(100vw - 250px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignSelf: 'stretch',
    background: '#ccc',
    justifyContent: 'space-between',
    borderLeft: '1px solid #c7c7c7'
  }
}

class GameAdmin extends React.Component {
  // constructor (props) {
  //   super(props)
  // }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.leftPannel}>
          <GameFilter />
        </div>
        <div className={classes.content}>
          <GameOperate />
          <GameList />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GameAdmin)

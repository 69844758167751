import { observable, action } from 'mobx'
import { fetchData, deleteAttribute } from '@src/utils/tools'
import gameFilter from './GameFilter'
import authInfo from '../authInfo'
import Toast from '../../components/Toast'

// API连接
var GameManageAPI = {
  fetchList: data =>
    fetchData({
      url: `game/list`,
      data: data,
      method: 'POST'
    }),
  getGameInfo: data =>
    fetchData({
      url: `game/info?docid=${data.docId}&audit=${data.audit}&region=${data.region}`,
      method: 'GET'
    }),
  lineApi: data => {
    if (data.type === 'online') {
      return fetchData({
        url: `doc/online?docId=${data.docId}&region=${data.region}&type=GAME`,
        method: 'GET'
      })
    }
    if (data.type === 'offline') {
      return fetchData({
        url: `doc/offline?docId=${data.docId}&region=${data.region}&type=GAME&reason=111`,
        method: 'GET'
      })
    }
  },
  deleteOnlineData: data =>
    fetchData({
      url: `game/delete`,
      method: 'POST',
      data: data
    }),
  changeWeight: (data) => {
    return fetchData({
      url: `game/area/edit`,
      data: data,
      method: 'POST'
    })
  }
    
}

class GameManage {
  @observable
  gameList = [] // 游戏列表
  @observable
  pageSize = 10
  @observable
  pageNum = 1
  @observable
  sum = 0

  @action
  resetPageNum = () => {
    this.pageNum = 1
  }
  @action
  updatePageNum = (attr, value) => {
    this[attr] = value
  }
  // 获取表格列表信息
  @action
  getGameList = async pageNum => {
    if (pageNum && pageNum !== this.pageNum) {
      this.pageNum = pageNum
    }
    let searchOptions = Object.assign(
      {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        region: authInfo.region.code
      },
      gameFilter.filters
    )

    let tagsArr = []
    searchOptions.tags.forEach(item => {
      if (
        ['history_', 'recommended_', 'trending_', 'feed_'].findIndex(
          age => age === item
        ) < 0
      ) {
        tagsArr.push(item)
      }
    })
    searchOptions.tags = tagsArr
    if (searchOptions.location === -1) {
      deleteAttribute(searchOptions, ['location'])
    }

    searchOptions.publishTimeStart =
      searchOptions.publishTimeStart === null
        ? 0
        : searchOptions.publishTimeStart
    searchOptions.publishTimeEnd =
      searchOptions.publishTimeEnd === null ? 0 : searchOptions.publishTimeEnd

    if (
      searchOptions.publishTimeStart !== 0 &&
      searchOptions.publishTimeEnd !== 0 &&
      searchOptions.publishTimeStart > searchOptions.publishTimeEnd
    ) {
      Toast.warning('please select the correct time...')
      return
    }
    let result = await GameManageAPI.fetchList(searchOptions)
    if (result.data) {
      if (result.data.status) {
        this.gameList = result.data.result.list || []
        this.sum = result.data.result.count || 0
      }
    }
  }

  // 更新 list 中某条数据的 权重
  @action
  updateListBoutiques = (gameId, newWeight) => {
    for (let i = 0; i < this.gameList.length; i++) {
      const item = this.gameList[i]
      if (item.docId === gameId) {
        item['boutiques'] = newWeight
        break
      }
    }
  }

  // 获取详情页的信息
  @action
  getGameDetail = (docId, audit, region) => {
    return GameManageAPI.getGameInfo({ docId, audit, region })
  }
  @action
  whetherLine = async (type, docId, region, cb) => {
    let res = await GameManageAPI.lineApi({ type, docId, region })
    if (res.data) {
      if (res.data.status) {
        Toast.success('操作成功')
        if (cb) {
          cb()
        }
      } else {
        Toast.error('操作失败')
      }
    } else {
      Toast.error('操作失败')
    }
  }
  @action
  deleteOnline = (docId, addTime, region) => {
    return GameManageAPI.deleteOnlineData({ docId, addTime, region })
  }
  @action
  changeWeight = (gameId, area, sort) => {
    // 区域  当更新全局权重时，该值为空
    if (area === -1) {
      area = ''
    }
    return GameManageAPI.changeWeight({ gameId, area, sort })
  }
}
const gameManage = new GameManage()
export default gameManage

import { observable, action } from 'mobx'
import { fetchData, deleteAttribute } from '@src/utils/tools'
import authInfo from '../authInfo'
import Toast from '@src/components/Toast'

const api = {
  getReviewList: data => {
    return fetchData({
      url: `game/list`,
      method: 'POST',
      data: data
    })
  },
  editGame: data => {
    return fetchData({
      url: `game/edit`,
      method: 'POST',
      data: data
    })
  },
  deleteGame: data => {
    return fetchData({
      url: `game/audit/delete`,
      method: 'GET',
      data: data
    })
  },
  reviewPass: data => {
    return fetchData({
      url: `game/audit/pass`,
      method: 'GET',
      data: data
    })
  },
  reviewReject: data => {
    return fetchData({
      url: `game/audit/reject`,
      method: 'GET',
      data: data
    })
  }
}

class GameReview {
  constructor(api) {
    this.gameApi = api
  }
  @observable
  filters = {
    docId: '',
    title: '',
    sourceId: [],
    providerIds: [],
    tags: [],
    audit: 0,
    state: -1,
    publishTimeStart: null,
    publishTimeEnd: null,
    sortType: 'DESC',
    sortField: 'publishTime',
    pageSize: 10,
    sum: 0,
    pageNum: 1,
    location: -1
  }
  @observable
  gameList = []

  @action
  updateFilter = (key, value) => {
    this.filters[key] = value
  }
  @action
  resetFilter = () => {
    this.filters = {
      docId: '',
      title: '',
      sourceId: [],
      providerIds: [],
      tags: [],
      audit: 0,
      state: -1,
      publishTimeStart: null,
      publishTimeEnd: null,
      sortType: 'DESC',
      sortField: 'publishTime',
      pageSize: 10,
      sum: 0,
      pageNum: 1,
      location: -1
    }
  }
  @action
  resetPageNum = () => {
    this.filters.pageNum = 1
  }
  @action
  async getGameList () {
    const params = Object.assign({}, this.filters, {
      region: authInfo.region.code
    })

    let tagsArr = []
    params.tags.forEach(item => {
      if (
        ['history_', 'recommended_', 'trending_', 'feed_'].findIndex(
          age => age === item
        ) < 0
      ) {
        tagsArr.push(item)
      }
    })
    params.tags = tagsArr
    if (params.location === -1) {
      deleteAttribute(params, ['location'])
    }

    params.publishTimeStart =
      params.publishTimeStart === null ? 0 : params.publishTimeStart
    params.publishTimeEnd =
      params.publishTimeEnd === null ? 0 : params.publishTimeEnd
    if (
      params.publishTimeStart !== 0 &&
      params.publishTimeEnd !== 0 &&
      params.publishTimeStart > params.publishTimeEnd
    ) {
      Toast.warning('please select the correct time...')
      return
    }
    const response = await this.gameApi.getReviewList(params)
    const data = response && response.data
    if (data.status) {
      const result = data.result || {}
      this.filters.sum = Number.parseInt(result.count, 10)
      this.gameList = result.list
    } else {
      const msg = (data.result && data.result.msg) || '获取列表数据出错。'
      Toast.error(msg)
    }
  }

  @action
  async reviewPass (data) {
    return this.gameApi.reviewPass(data)
  }

  @action
  async reviewReject (data) {
    return this.gameApi.reviewReject(data)
  }

  @action
  async editGame (data) {
    return this.gameApi.editGame(data)
  }

  @action
  async deleteGame (data) {
    return this.gameApi.deleteGame(data)
  }
}

const gameReview = new GameReview(api)

export default gameReview

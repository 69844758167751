import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import StatisticalFilter from './statisticalFilter'
import StatisticalList from './statisticalList'
const styles = {
  root: {
    display: 'flex'
  },
  leftPannel: {
    width: 250,
    height: '100%',
    backgroundColor: 'cyan'
  },
  rightPannel: {
    width: 'calc(100vw - 250px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignSelf: 'stretch',
    background: '#ccc',
    justifyContent: 'space-between',
    boderLeft: '1px solid #c7c7c7'
  }
}

class Statistical extends React.Component {
  // constructor (props) {
  //   super(props)
  // }
  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.leftPannel}>
          <StatisticalFilter />
        </div>
        <div className={classes.rightPannel}>
          <StatisticalList />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Statistical)

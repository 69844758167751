import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from '../ContentManage/ContentManage'
import Toast from '@src/components/Toast'

class MaskWordManageAPI {
  getMaskWordList (params) {
    return fetchData({
      url: 'shield/word/list',
      method: 'get',
      data: {
        region: authInfo.region.code,
        language: '',
        type: contentManage.contentType.toUpperCase(),
        ...params
      }
    })
  }
  addMaskWord (params) {
    return fetchData({
      url: `shield/word/add`,
      data: {
        region: authInfo.region.code,
        language: '',
        type: contentManage.contentType.toUpperCase(),
        ...params
      }
    })
  }
  deleteMaskWord (params) {
    return fetchData({
      url: `shield/word/delete`,
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...params
      }
    })
  }
  handleTitle (param) {
    return fetchData({
      url: `shield/word/check`,
      method: 'get',
      data: {
        region: authInfo.region.code,
        target: param
      }
    })
  }
}

class MaskWordManage {
  constructor (api) {
    this.maskWordManageApi = api
  }

  @observable
  list = []
  @observable
  pageNum = 1
  @observable
  pageSize = 10
  @observable
  total = 0

  @action
  resetPage = () => {
    this.pageNum = 1
  }
  @action
  getMaskWordList () {
    this.maskWordManageApi
      .getMaskWordList({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      .then(
        action(res => {
          if (res.data.status) {
            let result = res.data.result || {}
            this.list = result.list || []
            this.total = result.count || 0
          } else {
            Toast.error(res.data.msg)
          }
        })
      )
  }

  @action
  addMaskWord (words) {
    return this.maskWordManageApi.addMaskWord(words).then(res => {
      return res.data
    })
  }
  @action
  handleTitle (data) {
    return this.maskWordManageApi.handleTitle(data)
  }
  @action
  deleteMaskWord (params) {
    return this.maskWordManageApi.deleteMaskWord(params).then(res => {
      return res.data
    })
  }

  @action
  updatePageNum = pageNum => {
    if (pageNum === this.pageNum) {
      return
    }

    this.pageNum = pageNum
  }
}

const maskWordManageAPI = new MaskWordManageAPI()
const maskWordManage = new MaskWordManage(maskWordManageAPI)

export default maskWordManage

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Paper,
  MenuItem,
  Button
} from '@material-ui/core'
import Toast from '@src/components/Toast'
import CircularProgress from '@material-ui/core/CircularProgress'
import AsyncSelect from 'react-select/lib/Async'

const styles = theme => ({
  content: {
    background: '#fff',
    height: 'calc(100vh - 64px - 49px)'
  },
  paperContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 30vh)'
  },
  addContent: {
    padding: theme.spacing.unit * 3,
    paddingTop: 0,
    width: '60%'
  },
  selectTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: theme.spacing.unit * 2
  },
  upload: {
    display: 'flex',
    justifyContent: 'center'
  },
  uploadBtn: {
    marginTop: theme.spacing.unit * 2
  },
  select: {
    width: '100%'
  },
  menu: {
    padding: '11px 8px'
  },
  fileUpload: {
    position: 'relative',
    top: theme.spacing.unit,
    display: 'inline - block',
    background: '#D0EEFF',
    border: '1px solid #99D3F5',
    borderRadius: '4px',
    padding: '4px 12px',
    overflow: 'hidden',
    color: '#1E88C7',
    textDecoration: 'none',
    textIndent: '0',
    lineHeight: '20px',
    '&:hover': {
      background: '#AADFFD',
      borderColor: '#78C3F3',
      color: '#004974',
      textDecoration: 'none'
    }
  },
  fileInput: {
    position: 'absolute',
    fontSize: '100px',
    right: 0,
    top: 0,
    opacity: 0
  }
})

@inject('gameAdd')
@observer
class GameBatchUp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectValue: ''
    }
  }
  componentDidMount () {
    this.props.gameAdd.resetBacthInfo()
    this.props.gameAdd.getLanguages()
    // this.emptyState()
  }
  componentWillUnmount () {
    clearInterval(this.timer)
  }
  emptyState = () => {
    this.setState({
      selectValue: ''
    })
  }
  handleInputChange = newValue => {
    // const inputValue = newValue.replace(/\W/g, '');
    return newValue
  }
  getOptions = idParams => async inputValue => {
    if (typeof inputValue === 'object') {
      inputValue = ''
    }
    // if (inputValue === '') {
    //   return
    // }
    let res = await this.props.gameAdd.searchFilters(inputValue, idParams)
    let resultArr = []
    if (res.data.status && res.data.result) {
      res.data.result.forEach(value => {
        let obj = {}
        obj.value = value.pid
        obj.label = value.name
        resultArr.push(obj)
      })
    }
    return resultArr || []
  }
  searchCb = value => {
    const { gameAdd } = this.props
    value.forEach((item, index) => {
      if (parseInt(index) === 0 && item) {
        this.setState({
          selectValue: item
        })
        gameAdd.updateBatchInfo('appId', item.value)
      }
    })
  }
  selectHandle = event => {
    this.props.gameAdd.updateBatchInfo('lan', event.target.value)
  }
  upload = () => {
    const { gameAdd } = this.props
    if (!this.textInput.files[0]) {
      Toast.warning('填写相关的值')
      return
    }
    if (!gameAdd.uploadBtn) {
      Toast.warning('正在上传中...')
      return
    }
    let emptyState = this.emptyState
    this.props.gameAdd.updateBatchInfo('file', this.textInput.files[0])
    this.props.gameAdd.saveBatch(emptyState)
  }
  progressBar = () => {
    if (this.textInput.files[0]) {
      Toast.success('文件上传成功')
    }
  }
  render () {
    const { classes, uploadStyle, theme, gameAdd } = this.props
    const { selectValue } = this.state
    const selectStyles = theme
    return (
      <div className={classes.content}>
        <div className={classes.paperContent}>
          <Paper className={classes.addContent} elevation={3}>
            <Typography className={classes.selectTitle}>Types of</Typography>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.select}
              select
              value={uploadStyle}
              onChange={this.props.handleChangeSelect}
              SelectProps={{ classes: { outlined: classes.menu } }}
            >
              <MenuItem value={'Batch'}>Batch upload</MenuItem>
              <MenuItem value={'Single'}>A single upload</MenuItem>
            </TextField>
            <Typography className={classes.selectTitle}>
              Choose Provider
            </Typography>
            <AsyncSelect
              value={selectValue}
              cacheOptions
              defaultOptions
              isMulti
              isSearchable
              classes={classes}
              styles={selectStyles}
              className={classes.select}
              onInputChange={this.handleInputChange}
              loadOptions={this.getOptions('provider')}
              onChange={this.searchCb}
            />
            <Typography className={classes.selectTitle}>
              Choose Language
            </Typography>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.select}
              select
              value={gameAdd.BatchInfo.lan}
              onChange={this.selectHandle}
              SelectProps={{ classes: { outlined: classes.menu } }}
            >
              {gameAdd.languagesList.map(item => {
                return (
                  <MenuItem value={item.code} key={item.id}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </TextField>
            <Typography className={classes.selectTitle}>
              Choose files
            </Typography>
            <form encType="multipart/form-data" action="">
              <a href="javascript:;" className={classes.fileUpload}>
                upload
                <input
                  type="file"
                  className={classes.fileInput}
                  id="File"
                  onChange={this.progressBar}
                  ref={el => (this.textInput = el)}
                  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  name="file"
                />
              </a>
            </form>
            <div className={classes.upload}>
              {gameAdd.uploadBtn ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.upload}
                  className={classes.uploadBtn}
                >
                  upload
                </Button>
              ) : (
                <CircularProgress disableShrink />
              )}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}
GameBatchUp.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(GameBatchUp)

import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { withStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import { inject } from 'mobx-react'

const actionsStyles = theme => ({
  root: {
    display: 'flex',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  },
  jumpContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  // customContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginRight: 20
  // },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > ul > li': {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: 30,
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      marginRight: 8,
      textAlign: 'center',
      cursor: 'pointer'
    },
    '& > ul > li:hover': {
      color: '#0097a7',
      border: '1px solid #0097a7',
      borderRadius: '4px'
    }
  },
  pageNumberInput: {
    width: 80,
    textAlign: 'center'
  },
  pageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  spanStyle: {
    display: 'inline-block',
    cursor: 'pointer',
    padding: '0 10px',
    lineHeight: '28px'
  },
  doubleArrowRight: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    lineHeight: '28px',
    display: 'inline-block',
    textAlign: 'center',
    width: '30px',
    '&:after': {
      content: '"···"',
      fontSize: '16px',
      verticalAlign: 'text-bottom'
    },
    '&:hover': {
      '&:after': {
        content: '"»"',
        fontSize: '18px',
        verticalAlign: 'text-bottom'
      },
      color: '#0097a7'
    }
  },
  doubleArrowLeft: {
    color: theme.palette.text.secondary,
    padding: '0 10px',
    cursor: 'pointer',
    display: 'inline-block',
    lineHeight: '28px',
    textAlign: 'center',
    width: '30px',
    '&:after': {
      content: '"···"',
      fontSize: '16px',
      verticalAlign: 'text-bottom'
    },
    '&:hover': {
      '&:after': {
        content: '"«"',
        fontSize: '18px',
        verticalAlign: 'text-bottom'
      },
      color: '#0097a7'
    }
  }
})

const PaginationButton = ({
  current = 1,
  count = 20,
  handlePageClick,
  classes
}) => {
  let result = []
  if (count <= 5) {
    for (let i = 1; i <= count; i++) {
      result.push(i)
    }
  } else {
    if (current <= 3) {
      for (let i = 1; i <= 4; i++) {
        result.push(i)
      }
      result.push('···')
      result.push(count)
    } else if (current >= count - 2) {
      result.push(1)
      result.push('···')
      for (let i = count - 3; i <= count; i++) {
        result.push(i)
      }
    } else {
      result.push(1)
      result.push('···')
      for (let i = current - 1; i <= current + 1; i++) {
        result.push(i)
      }
      result.push('···')
      result.push(count)
    }
  }

  return result.map((i, index) => (
    <li
      key={index}
      style={
        current === i
          ? {
            color: '#0097a7',
            border: '1px solid #0097a7',
            borderRadius: '4px'
          }
          : {}
      }
    >
      {i === '···' ? (
        <span
          className={
            index === 1 ? classes.doubleArrowLeft : classes.doubleArrowRight
          }
          onClick={handlePageClick(index === 1 ? current - 3 : current + 3)}
          key={i}
        />
      ) : (
        <span
          className={classes.spanStyle}
          onClick={handlePageClick(i)}
          key={i}
        >
          {i}
        </span>
      )}
    </li>
  ))
}

@inject('contentManage')
class TablePaginationActions extends React.PureComponent {
  state = {
    inputValue: 1,
    pageSize: 10
  }

  componentWillMount () {
    this.setState({
      inputValue: 1
    })
    // const { contentManage } = this.props
    // let pageSize = 0
    // if (contentManage.contentType === 'Video') {
    //   pageSize = 20
    // } else {
    //   pageSize = 40
    // }
    // this.setState({
    //   inputValue: 1,
    //   pageSize
    // })
    this.setState({
      inputValue: 1,
      pageSize: 10
    })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.page !== this.props.page) {
      this.setState({
        inputValue: nextProps.page + 1
      })
    }
  }

  handleBackButtonClick = event => {
    this.setState(
      {
        inputValue: this.props.page
      },
      () => {
        if (document.getElementById('Container')) {
          document.getElementById('Container').scrollTo(0, 0)
        }
        this.props.onChangePage(event, this.props.page - 1)
      }
    )
  }

  handleNextButtonClick = event => {
    this.setState(
      {
        inputValue: this.props.page + 2
      },
      () => {
        if (document.getElementById('Container')) {
          document.getElementById('Container').scrollTo(0, 0)
        }
        this.props.onChangePage(event, this.props.page + 1)
      }
    )
  }

  handlePageInput = event => {
    const value = event.target.value
    this.setState({
      inputValue: value
    })
  }

  handlePageInputEnter = event => {
    if (event.keyCode === 13) {
      let pageNum = Number(this.state.inputValue)
      this.handlePageChange(event, pageNum)
    }
  }

  // handlePageSizeInput = event => {
  //   const value = event.target.value
  //   this.setState({
  //     pageSize: value
  //   })
  // }

  // handlePageSizeInputEnter = event => {
  //   if (event.keyCode === 13) {
  //     let pageSize = Number(this.state.pageSize)
  //     const { count } = this.props
  //     if (pageSize > count) {
  //       pageSize = count
  //     } else if (pageSize < 0) {
  //       pageSize = 0
  //     }
  //     this.setState({
  //       pageSize
  //     })
  //     console.log('handlePageSizeInputEnter=>', this.state.pageSize)
  //     this.props.onChangePageSize(event, pageSize)
  //   }
  // }

  handlePageClick = pageNum => event => {
    this.handlePageChange(event, pageNum)
  }

  handlePageChange = (event, pageNum) => {
    const { count, rowsPerPage } = this.props
    const max = Math.ceil(count / rowsPerPage)
    if (pageNum <= 0) {
      pageNum = 1
    } else if (pageNum > max) {
      pageNum = max
    }
    this.setState({
      inputValue: pageNum
    })
    if (document.getElementById('Container')) {
      document.getElementById('Container').scrollTo(0, 0)
    }
    this.props.onChangePage(event, pageNum - 1)
  }

  render () {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.jumpContainer} />
        <div className={classes.buttonContainer}>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <ul>
            <PaginationButton
              current={page + 1}
              count={Math.ceil(count / rowsPerPage)}
              handlePageClick={this.handlePageClick}
              classes={classes}
              className={classes.pageContainer}
            />
          </ul>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </div>
        <div className={classes.jumpContainer}>
          <span>跳至</span>
          <Input
            id="page"
            value={this.state.inputValue}
            onChange={this.handlePageInput}
            type="number"
            onKeyUp={this.handlePageInputEnter}
            classes={{
              inputType: classes.pageNumberInput
            }}
          />
          <span>页</span>
        </div>
        {/* <div className={classes.customContainer}>
          <span>perPageSize：</span>
          <Input
            id="customPage"
            value={this.state.pageSize}
            onChange={this.handlePageSizeInput}
            type="number"
            onKeyUp={this.handlePageSizeInputEnter}
            classes={{
              inputType: classes.pageNumberInput
            }}
          />
        </div> */}
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

export default withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions
)

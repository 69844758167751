import videoManage from './ContentManage/VideoManage'
import videoFilter from './ContentManage/VideoFilter'
import contentManage from './ContentManage/ContentManage'
import newsFilter from './ContentManage/NewsFilter'
import newsManage from './ContentManage/NewsManage'
import authInfo from './authInfo'
import userManage from './UserManage/UserManage'
import areaManage from './AreaManage/areaMange'
import categoryManage from './CategoryManage/categoryManage'
import maskWordManage from './MaskWordManage/MaskWordManage'
import cpManage from './CPManage/CPManage'
import taskManage from './TaskManage/TaskManage'
import publisherManage from './PublisherManage/PublisherManage'
import gameFilter from './BrowserGames/GameFilter'
import gameManage from './BrowserGames/GameManage'
import gameAdd from './BrowserGames/GameAdd'
import gameReview from './BrowserGames/GameReview'
import statistical from './statistical'
import tagManage from './TagManage/TagManage'
import webCrawler from './WebCrawler/WebCrawler'
import batchOffline from './batchoffline'
import themeManage from './ThemeManage/ThemeManage'

export default {
  videoManage,
  videoFilter,
  authInfo,
  contentManage,
  userManage,
  newsManage,
  newsFilter,
  areaManage,
  categoryManage,
  maskWordManage,
  cpManage,
  taskManage,
  publisherManage,
  gameFilter,
  gameManage,
  gameAdd,
  gameReview,
  tagManage,
  statistical,
  webCrawler,
  batchOffline,
  themeManage
}

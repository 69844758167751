import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import DataBase from '@material-ui/icons/Language'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import { intl } from '@src/IntlGlobalProvider'
import Toast from '@src/components/Toast/index'
import OtherMenu from './OtherMenu'
import AdminMenu from './AdminMenu'
import { Database } from '@src/utils/constant'
import ContentManage from './../models/ContentManage/ContentManage'

const styles = theme => ({
  homeIcon: {
    transform: 'translate(0, -6px)',
    fontSize: 18
  },
  headOptions: {
    display: 'flex'
  },
  userOperate: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '20px'
  },
  userName: {
    fontSize: '16px',
    maxWidth: '150px',
    marginRight: '16px'
  },
  linkStyleRemove: {
    textDecoration: 'none',
    color: '#fff'
  },
  selectDatabase: {
    cursor: 'pointer',
    marginRight: 10
  },
  logoutBtn: {
    cursor: 'pointer',
    padding: '8px 16px',
    border: '1px solid white',
    borderRadius: '4px',

    '&:hover': {
      color: '#1C244E',
      background: 'white',
      border: '1px solid rgba(0, 0, 0, 0.23)'
    }
  }
})

@inject('authInfo')
@observer
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  componentDidMount() {
    const { authInfo } = this.props
    authInfo.getUserInfo()
    authInfo.fetchRegions()
  }

  handleLoginOut = async () => {
    this.props.authInfo.loginOutFn()
    window.location.hash = '/login'
    // const result = await this.props.authInfo.logout()
    // const data = result.data
    // if (data && data.status) {
    //   window.localStorage.clear()
    //   let url = data.result
    //   let redirectUrl = url.split('=')[1]
    //   let newUrl = encodeURIComponent(redirectUrl)
    //   url = url.replace(redirectUrl, newUrl)
    //   window.location.href = url
    // } else {
    //   // 失败后弹出toast提示退出失败，但是界面无变化，产品认为是bug，
    //   // 延时reload去登录界面
    //   Toast.error(intl.formatMessage({ id: 'logoutFail' }))
    //   setTimeout(() => location.reload(), 1000)
    // }
  }

  _menuRender = authInfo => {
    if (!!authInfo.user && authInfo.user.role === 2) {
      return <AdminMenu />
    }
    return null
  }

  showDatabase = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  chooseDatabase = conf => e => {
    this.setState({ anchorEl: null })
    if (!conf) {
      return
    }

    const { authInfo } = this.props
    const isOnline = authInfo.isOnline
    const { domain } = conf

    if (isOnline && domain !== window.location.origin) {
      window.location.href = domain + process.env.PUBLIC_URL + `/index.html`
    }
  }

  render() {
    const { classes, authInfo } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <AppBar position="fixed" style={{ backgroundColor: '#1C244E' }}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            <Link to="/" replace className={classes.linkStyleRemove}>
              Global Content Pool
              <HomeIcon className={classes.homeIcon} />
            </Link>
          </Typography>
          {ContentManage.paramsPkgType !== 'statistical' ? (
            <div className={classes.headOptions}>
              {this._menuRender(authInfo)}
              {authInfo.user && <OtherMenu />}
            </div>
          ) : (
            ''
          )}
          <div className={classes.userOperate}>
            <DataBase
              className={classes.selectDatabase}
              onClick={this.showDatabase}
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.chooseDatabase()}
            >
              {Database.map(d => (
                <MenuItem key={d.code} onClick={this.chooseDatabase(d)}>
                  {d.name}
                </MenuItem>
              ))}
            </Menu>
            <React.Fragment>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.userName}
              >
                {intl.formatMessage({ id: 'user' })} :{' '}
                {authInfo.user && authInfo.user.name}
              </Typography>
              <a
                onClick={() => this.handleLoginOut()}
                className={classes.logoutBtn}
              >
                {intl.formatMessage({ id: 'logout' })}
              </a>
            </React.Fragment>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Header)

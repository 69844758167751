import React from 'react'
import { Switch, Route } from 'react-router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBarHeader from './Header'
import withRoot from '../utils/withRoot'
import NotFound from '../pages/NotFound/Index'
import indexRoutes from '../routes/index'
import { addLocaleData, IntlProvider } from 'react-intl'
import IntlGlobalProvider from '../IntlGlobalProvider'
import en_US from '../locale/en_US'
import en from 'react-intl/locale-data/en'

import ProductSelect from './ProductSelect'
import { ProductConf } from '@src/utils/constant'

addLocaleData([...en])

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
    background: '#fff'
  },
  main: {
    marginTop: 64
  }
})

class Layout extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <IntlProvider locale={'en'} messages={en_US}>
        <IntlGlobalProvider>
          <div className={classes.root}>
            <AppBarHeader />
            <main className={classes.main}>
              <Switch>
                <Route
                  path="/"
                  exact
                  component={() => <ProductSelect productConf={ProductConf} />}
                />
                {indexRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  )
                })}
                <Route component={NotFound} />
              </Switch>
            </main>
          </div>
        </IntlGlobalProvider>
      </IntlProvider>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRoot(withStyles(styles, { withTheme: true })(Layout))

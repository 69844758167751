import { observable, action } from 'mobx'

class GameFilter {
  @observable
  filters = {
    docId: '', // 游戏iDgameID
    title: '', // 游戏名称gamename
    sourceId: [], // 发布者publisher
    providerIds: [], // 提供方Provider
    tags: [], // Tag
    state: -1, // status，默认-1，选择是ALL
    publishTimeStart: null, // publish time
    publishTimeEnd: null,
    sortType: 'DESC',
    sortField: 'addTime',
    boutiques: -1,
    audit: 1,
    location: -1
  }
  @action
  updateFilter = (attr, value) => {
    this.filters[attr] = value
  }
  @action
  resetFilter = () => {
    this.filters = {
      docId: '', // 游戏iDgameID
      title: '', // 游戏名称gamename
      sourceId: [], // 发布者publisher
      providerIds: [], // 提供方Provider
      tags: [], // Tag
      state: -1, // status，默认-1，选择是ALL
      publishTimeStart: null, // publish time
      publishTimeEnd: null,
      sortType: 'DESC',
      sortField: 'addTime',
      boutiques: -1,
      audit: 1,
      location: -1
    }
  }
}

const gameFilter = new GameFilter()
export default gameFilter

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'

class AreaMangeAPI {
  getAllCountry = () =>
    fetchData({
      url: 'region/all',
      method: 'GET'
    })
  addArea = data =>
    fetchData({
      url: 'region/add',
      method: 'POST',
      data: data
    })
  deleteArea = data =>
    fetchData({
      url: 'region/delete',
      method: 'POST',
      data: data
    })
  getAddedArea = () =>
    fetchData({
      url: 'region/list',
      method: 'GET'
    })
}

class AreaManage {
  @observable
  areaRegions = JSON.parse(window.localStorage.getItem('regions')) || []
  constructor (api) {
    this.areaApi = api
  }
  @action
  getAllCountry = () => {
    return this.areaApi.getAllCountry()
  }
  @action
  addArea = data => {
    return this.areaApi.addArea(data)
  }

  @action
  deleteArea = data => {
    return this.areaApi.deleteArea(data)
  }
  @action
  getAddedArea = async () => {
    const result = await this.areaApi.getAddedArea()
    if (result && result.data.status) {
      this.areaRegions = result.data.result
    }
  }
}

const areaMangeAPI = new AreaMangeAPI()
const areaManage = new AreaManage(areaMangeAPI)

export default areaManage

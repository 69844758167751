export {
  CRAWL_TASK_TYPES,
  CRAWL_TASK_TYPE_VALUES
} from '../pages/AddContent/constants'
export const videoConf = {
  sortOptions: [
    {
      value: 'publishTime',
      label: 'Publish Time'
    },
    {
      value: 'addTime',
      label: 'Storage Time'
    },
    {
      value: 'updateTime',
      label: 'Update Time'
    },
    {
      value: 'views',
      label: 'Impression'
    },
    {
      value: 'clicks',
      label: 'View'
    },
    {
      value: 'clickRate',
      label: 'PV CTR'
    },
    {
      value: 'likes',
      label: 'Likes'
    },
    {
      value: 'duration',
      label: 'Duration'
    },
    {
      value: 'Finish Rate',
      label: 'Finish Rate'
    },
    {
      value: 'Like Rate',
      label: 'Like Rate'
    }
  ]
}
export const Database = [
  {
    name: 'Russia',
    code: 'RU',
    domain: 'http://cms.pt.intl.miui.com'
  },
  {
    name: 'India',
    code: 'IN',
    domain: 'http://in.cms.pt.intl.miui.com'
  },
  {
    name: 'Singapore',
    code: 'SP',
    domain: 'http://sgp.cms.pt.intl.miui.com'
  }
]

// 各产品相关配置
export const ProductConf = [
  {
    category: 'News Feed',
    value: 'BROWSER',
    subPath: '/newsFeed',
    icon: require('../assets/browser.png'),
    regions: Database
  },
  {
    category: 'Contents Check',
    value: 'CONTENTSCHECK',
    subPath: '/contentsCheck',
    icon: require('../assets/parseIds.png')
  },
  {
    category: 'Statistical',
    value: 'STATISTICAL',
    subPath: '/statistical',
    icon: require('../assets/done.png')
  },
  {
    category: 'BatchOffline',
    value: 'BATCHOFFLINE',
    subPath: '/batchoffline',
    icon: require('../assets/batchoffline.png')
  }
]

// 游戏表头内容配置项
export const gameTableHead = [
  'Game ID',
  'Game Name',
  'Icon',
  'Tag',
  'publish time',
  // 'Status',
  'Provider',
  // 'PV_CTR',
  '权重',
  'operation'
]

// 详情页面的内容配置项
export const gameDatailContent = [
  'Game Name',
  'provider',
  'publish time',
  'online time',
  'Link',
  'Tag',
  'Status',
  'Impression',
  'Click',
  'CTR'
]

// 游戏审核列表配置项
export const GameConf = {
  gameReviewHead: [
    'Game ID',
    'Icon',
    'Name',
    'Tags',
    'publish time',
    'Status',
    'Provider',
    'Publisher',
    'Operation'
  ],
  gameReviewSortOptions: [
    {
      value: 'addTime',
      label: 'addTime'
    },
    {
      value: 'cpClicks',
      label: 'Original Views'
    },
    {
      value: 'views',
      label: 'Impression in newsfeed'
    },
    {
      value: 'clicks',
      label: 'click in newsfeed'
    },
    {
      value: 'boutiques',
      label: '权重'
    }
  ]
}

// 用户的内容管理频道标签

export const ManageContentList = [
  // 'Video',
  'Game'
  // 'News',
  // 'Gif',
  // 'Picture',
  // 'Theme'
]
if (process.env.PUBLIC_URL === '/content/cms') {
  ManageContentList.push('News')
}

// 批量修改category
export const CategoryList = [
  'entertainment',
  'lifestyle',
  'education',
  'career',
  'humor',
  'vlog',
  'horoscope',
  'health',
  'sports',
  'technology',
  'gadgets',
  'games',
  'auto',
  'society',
  'politics',
  'world',
  'offbeat',
  'business',
  'military',
  'law',
  'weather',
  'history',
  'art & culture'
]

export const SELECT_VK_PUBLISH_TIME = [
  {
    label: 'Today',
    value: 1
  },
  {
    label: 'One week',
    value: 2
  },
  {
    label: 'One month',
    value: 3
  },
  {
    label: 'Half a year',
    value: 5
  },
  {
    label: 'One year',
    value: 4
  }
]

export const SELECT_PUBLISH_TIME = [
  {
    label: 'Today',
    value: 1
  },
  {
    label: 'This week',
    value: 2
  },
  {
    label: 'This month',
    value: 3
  },
  {
    label: 'This year',
    value: 4
  }
]

export const SELECT_VK_SORT = [
  { value: 1, label: 'By relevance' },
  { value: 2, label: 'By date uploaded' },
  { value: 3, label: 'By duration' }
]

export const SELECT_SORT = [
  { value: 1, label: 'relevance' },
  { value: 2, label: 'Upload date' }
]

export const VIEW_TASKS_LIST = [
  {
    value: 1,
    label: 'ChannelID'
  },
  {
    value: 8,
    label: 'Playlist Link'
  },
  {
    value: 2,
    label: 'Keywords'
  },
  {
    value: 4,
    label: 'Super Title Link'
  },
  {
    value: 32,
    label: 'Instagram_account'
  },
  {
    value: 16,
    label: 'Instagram_hashtag'
  },
  {
    value: 64,
    label: 'VK_account'
  },
  {
    value: 128,
    label: 'VK_keyword'
  },
  {
    value: 256,
    label: 'Tiktok_account'
  },
  {
    value: 512,
    label: 'Tiktok_keyword'
  }
]

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import { getCookie } from '@src/utils/cookie'

class UserManageAPI {
  getUserList = (pageIndex, pageSize) =>
    fetchData({
      url: 'list',
      method: 'POST'
    })

  addUser = data =>
    fetchData({
      url: `create`,
      data: data,
      method: 'POST'
    })

  editUser = data =>
    fetchData({
      url: `update`,
      data: data,
      method: 'POST'
    })

  deleteUser = name =>
    fetchData({
      url: `delete`,
      data: {
        name
      },
      method: 'POST'
    })
}

class UserManage {
  constructor (api) {
    this.userApi = api
  }

  @observable
  pageSize = 50
  @observable
  pageIndex = 1
  @observable
  users = []
  @observable
  sum = 0

  @action
  updatePageSize = pageSize => {
    if (this.pageSize === pageSize || isNaN(pageSize)) {
      return
    }

    this.pageSize = pageSize
  }

  @action
  updatePageIndex = pageIndex => {
    if (this.pageIndex === pageIndex || isNaN(pageIndex)) {
      return
    }

    this.pageIndex = pageIndex
  }

  @action
  getUserList = async () => {
    const result = await this.userApi.getUserList(this.pageIndex, this.pageSize)
    const data = result.data

    const curentName = getCookie('name')

    if (data && data.status) {
      const users = data.result || []
      if (curentName !== 'admin') {
        this.users = users.filter(v => v == curentName)
      } else {
        this.users = users
      }

      this.sum = this.users.length
    }
  }

  @action
  addUser = data => {
    return this.userApi.addUser(data)
  }

  @action
  editUser = data => {
    return this.userApi.editUser(data)
  }

  @action
  deleteUser = userId => {
    return this.userApi.deleteUser(userId)
  }
}

const userManageAPI = new UserManageAPI()
const userManage = new UserManage(userManageAPI)

export default userManage

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './style/Notice.css'
import {
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  CheckCircle as SuccessIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import JssProvider from 'react-jss/lib/JssProvider'
import generateClassName from './generateClassName'

function noop () {}
const animationDuration = 300

const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  contentMessage: {
    flex: 1
  },
  contentAction: {
    paddingLeft: 0
  }
})

class Notice extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      closing: false
    }
  }
  componentDidMount () {
    this.startCloseTimer()
  }
  componentWillUnmount () {
    this.clearCloseTimer()
  }
  startCloseTimer = () => {
    if (this.props.duration > 0) {
      this.closeTimer = setTimeout(() => {
        this.close()
      }, this.props.duration)
    }
  }
  clearCloseTimer = () => {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer)
      this.closeTimer = null
    }
  }

  close = () => {
    this.clearCloseTimer()
    this.setState(
      {
        closing: true
      },
      () => {
        this.animateTimer = setTimeout(() => {
          clearTimeout(this.animateTimer)
          this.props.onClose()
        }, animationDuration)
      }
    )
  }

  render () {
    const { prefixCls, type, content, classes } = this.props
    const { closing } = this.state
    const Icon = variantIcon[type]
    return (
      <JssProvider generateClassName={generateClassName}>
        <SnackbarContent
          onMouseEnter={this.clearCloseTimer}
          onMouseLeave={this.startCloseTimer}
          className={classNames(classes[type], prefixCls, {
            closing: closing
          })}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {content}
            </span>
          }
          classes={{
            message: classes.contentMessage,
            action: classes.contentAction
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </JssProvider>
    )
  }
}

Notice.propTypes = {
  classes: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired, // 显示时间，为零则不自动消失
  prefixCls: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']), // Notice的类型
  content: PropTypes.any, // Notice显示的内容
  onClose: PropTypes.func
}

Notice.defaultProps = {
  prefixCls: 'zhj-notice',
  duration: 2000,
  onClose: noop
}

export default withStyles(styles1, { generateClassName })(Notice)

import React from 'react'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { intl } from '@src/IntlGlobalProvider'

const styles = theme => ({
  dialogWrapper: {
    minWidth: 400,
    maxWidth: 600
  }
})

class AlertDialog extends React.Component {
  render () {
    const { classes } = this.props
    const confirmDisable = this.props.disabled || false

    return (
      <Dialog
        open={this.props.open}
        // onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.dialogWrapper}>
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.content}
            </DialogContentText>
            {this.props.children}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              variant="outlined"
              color="secondary"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              onClick={this.props.handleConfirm}
              variant="outlined"
              color="primary"
              disabled={confirmDisable}
              autoFocus
            >
              {intl.formatMessage({ id: 'ensure' })}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AlertDialog)

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from '../ContentManage/ContentManage'
import Toast from '@src/components/Toast/index'

let API = {
  addWeb: data =>
    fetchData({
      url: 'news/add',
      method: 'POST',
      data: data
    }),
  getLanguageList: () =>
    fetchData({
      url: `region/language/list?region=${authInfo.region.code}`,
      method: 'GET'
    })
}

class WebCrawler {
  @observable
  languageList = []
  @observable
  lan = 0
  @observable
  addInput = []
  @observable
  urlValue = {
    id: -1,
    url: '',
    reason: ''
  }

  @action
  resetData = () => {
    this.languageList = []
    this.lan = 0
    this.addInput = []
    this.urlValue = {
      id: -1,
      url: '',
      reason: ''
    }
  }

  @action
  getLanguageList = async () => {
    let res = await API.getLanguageList()
    if (res.data) {
      if (res.data.status && res.data.result) {
        this.languageList = res.data.result || []
      }
    }
  }
  @action
  handleWrawler = async () => {
    if (!this.lan || !this.urlValue.url || !this.urlValue.reason) {
      Toast.warning('请补充完信息!')
      return
    }
    let data = {
      lan: this.lan,
      urls: [this.urlValue.url],
      reasons: [this.urlValue.reason],
      region: authInfo.region.code,
      type: contentManage.contentType.toUpperCase()
    }
    if (this.addInput && !!this.addInput.length) {
      this.addInput.forEach(element => {
        data.urls.push(element.url)
        data.reasons.push(element.reason)
      })
    }
    if (this.addInput.length) {
      if (data.urls[this.addInput.length] === '') {
        Toast.warning('请补充完信息!')
        return
      }
      if (data.reasons[this.addInput.length] === '') {
        Toast.warning('请补充完信息!')
        return
      }
    }
    let res = await API.addWeb(data)
    if (res.data) {
      if (res.data.status) {
        Toast.success('爬取成功...')
      } else {
        Toast.error('爬取失败...')
      }
    } else {
      Toast.error('爬取失败...')
    }
  }
}

const webCrawler = new WebCrawler()

export default webCrawler

import { observable, action } from 'mobx'
import { fetchData, deleteAttribute } from '@src/utils/tools'
import Toast from '@src/components/Toast'
import authInfo from './../authInfo'
import contentManage from '../ContentManage/ContentManage'
import defaultImage from '../../assets/default.png'
// API
var GameAddAPI = {
  searchFilters: data => {
    if (data.type === 'source') {
      return fetchData({
        url:
          `${data.type}/match?name=${data.name}` +
          `&region=${authInfo.region.code
          }&type=${contentManage.contentType.toUpperCase()}` +
          `&pn=1&ps=50`,
        method: 'GET'
      })
    }
    if (data.type === 'tag') {
      return fetchData({
        url:
          `${data.type}/edit/list?name=${data.name}` +
          `&type=${contentManage.contentType.toUpperCase()}&region=${authInfo.region.code
          }` +
          `&pn=1&ps=50`,
        method: 'GET'
      })
    }
    return fetchData({
      url:
        `${data.type}/match?name=${data.name}` +
        `&region=${authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}`,
      method: 'GET'
    })
  },
  addGame: data => {
    return fetchData({
      url: `game/add`,
      data: data,
      type: 'POST'
    })
  },
  addBatchGame: data => {
    return fetchData({
      url: `game/batch/add`,
      data: data,
      type: 'POST',
      timeout: 500000,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  saveEdit: data => {
    return fetchData({
      url: `game/edit`,
      data: data,
      type: 'POST'
    })
  },
  getLanguages: () => {
    return fetchData({
      url: `region/language/all`,
      method: 'GET'
    })
  },
  getTagList: data => {
    return fetchData({
      url: 'tag/edit/list',
      method: 'GET',
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...data
      }
    })
  }
}

class GameAdd {
  @observable
  languagesList = []
  @observable
  uploadBtn = true
  @observable
  BatchInfo = {
    appId: '',
    file: '',
    lan: ''
  }
  @observable
  defaultImg = {
    icon: defaultImage,
    gif: defaultImage,
    banner: defaultImage
  }
  @observable
  AddInfo = {
    type: 'GAME', // 类型
    title: '', // 名称
    subTitle: [],
    url: '', // 图片地址
    // desc: '', // 图片描述
    startTime:
      new Date().getFullYear() +
      '-' +
      (new Date().getMonth() + 1) +
      '-' +
      new Date().getDate(), // 开始时间
    endTime:
      new Date().getFullYear() +
      2 +
      '-' +
      (new Date().getMonth() + 1) +
      '-' +
      new Date().getDate(),
    // style: 6, // 横竖屏
    boutiques: 0, // 加权
    providerIds: [],
    sourceId: [],
    images: [],
    originalImages: [],
    icons: [],
    tags: [],
    sName: 'Game',
    sDesc: 'hame',
    sIcon: '3',
    sid: '123456',
    lan: '', // 语言
    tagsFirstValue: '',
    tagsSecondValue: '',
    areas: [],
    themeColor: '' // 主题颜色
  }
  @observable
  tagList = []
  @observable
  originalImagesStr = ''
  @action
  updateDefaultImg = (attr, value) => {
    this.defaultImg[attr] = value
  }
  @action
  getTagList = async () => {
    const response = await GameAddAPI.getTagList({
      pageNum: 1,
      pageSize: 100
    })

    const { data } = response
    if (data && data.status) {
      const { result } = data
      this.tagList = result.list || []
    } else {
      Toast.error(data.msg || '获取任务列表出错')
      this.tagList = []
    }
  }
  @action
  searchFilters = (name, type) => {
    return GameAddAPI.searchFilters({ name, type })
  }
  @action
  updateBtn = () => {
    this.uploadBtn = !this.uploadBtn
  }
  @action
  updateAddInfo = (attr, value) => {
    if (attr === 'originalImagesStr') {
      this.originalImagesStr = value
    }
    if (value === 0) {
      this.AddInfo[attr] = ''
      return
    }
    if (attr === 'tagsFirstValue') {
      if (
        value === this.AddInfo.tagsSecondValue &&
        this.AddInfo.tagsSecondValue !== ''
      ) {
        Toast.warning(
          '选择的标签应该不一样,请重新选择或者删除，否则默认是原来的标签'
        )
        return
      }
    }
    if (attr === 'tagsSecondValue') {
      if (
        value === this.AddInfo.tagsFirstValue &&
        this.AddInfo.tagsFirstValue !== ''
      ) {
        Toast.warning(
          '选择的标签应该不一样,请重新选择或者删除，否则默认是原来的标签'
        )
        return
      }
    }
    this.AddInfo[attr] = value
  }

  @action
  updateIcon = (style, value) => {
    const {
      AddInfo: { images }
    } = this
    let index
    let isUploaded = false

    for (let i in images) {
      if (images[i].type === style) {
        index = i
        isUploaded = true
        break
      }
    }
    isUploaded
      ? (images[index] = value)
      : style === 'GAME_ICON_PIC'
        ? images.unshift(value)
        : images.push(value)
  }

  @action
  deleteUploadGif = () => {
    let image = []
    this.defaultImg.gif = defaultImage
    this.AddInfo.images.forEach(item => {
      if (item.type !== 'GAME_ICON_GIF') {
        image.push(item)
      }
    })
    this.AddInfo.images = image
  }

  @action
  deleteUploadBanner = () => {
    this.defaultImg.banner = defaultImage
    this.AddInfo.images = this.AddInfo.images.filter(
      item => item.type !== 'GAME_ICON_BANNER'
    )
  }

  @action
  updateBatchInfo = (attr, value) => {
    this.BatchInfo[attr] = value
  }
  @action
  resetBacthInfo = () => {
    this.BatchInfo = {
      appId: '',
      file: '',
      lan: ''
    }
  }
  @action
  resetAddInfo = () => {
    this.tagList = []
    this.AddInfo = {
      type: 'GAME', // 类型
      title: '', // 名称
      subTitle: [],
      url: '', // 图片地址
      // desc: '', // 图片描述
      startTime:
        new Date().getFullYear() +
        '-' +
        (new Date().getMonth() + 1) +
        '-' +
        new Date().getDate(), // 开始时间
      endTime:
        new Date().getFullYear() +
        2 +
        '-' +
        (new Date().getMonth() + 1) +
        '-' +
        new Date().getDate(),
      // style: 6, // 横竖屏
      boutiques: 0, // 加权
      providerIds: [],
      sourceId: [],
      images: [],
      originalImages: [],
      tags: [],
      sName: 'Game',
      sDesc: 'hame',
      sIcon: '3',
      sid: '123456',
      lan: '',
      tagsFirstValue: '',
      tagsSecondValue: '',
      areas: [],
      themeColor: ''
    }
    this.defaultImg = {
      icon: defaultImage,
      gif: defaultImage,
      banner: defaultImage
    }
    this.originalImagesStr = ''
  }
  @action
  getLanguages = async () => {
    let res = await GameAddAPI.getLanguages()
    if (res.data) {
      if (res.data.status && res.data.result) {
        this.languagesList = res.data.result || []
      }
    }
  }
  @action
  saveBatch = async emptyState => {
    if (this.BatchInfo.appId === '' && this.BatchInfo.file === '') {
      Toast.warning('填写相关的值...')
      return
    }
    let data = {
      appId: this.BatchInfo.appId,
      file: this.BatchInfo.file,
      region: authInfo.region.code,
      lan: this.BatchInfo.lan
    }
    if (data.lan === '' || data.appId === '' || data.file === '') {
      Toast.warning('请补全信息')
      return
    }
    this.updateBtn()
    let tmp = new FormData()
    tmp.append('appId', data.appId)
    tmp.append('file', data.file)
    tmp.append('region', data.region)
    tmp.append('lan', data.lan)
    data = tmp
    let result = await GameAddAPI.addBatchGame(data)
    if (result.data) {
      if (result.data.status) {
        this.updateBtn()
        Toast.success('上传成功...')
      } else {
        this.updateBtn()
        this.resetBacthInfo()
        emptyState()
        Toast.error(result.data.msg)
      }
    } else {
      this.updateBtn()
      this.resetBacthInfo()
      Toast.error('上传失败...')
    }
  }
  @action
  saveEdit = data => {
    return GameAddAPI.saveEdit(data)
  }
  @action
  saveSuccess = async () => {
    // 获取地区语言
    let regionLan = {
      region: authInfo.region.code
    }
    let options = {
      audit: false,
      pkgs: ['com.android.browser'],
      cats: ['Game']
    }
    this.AddInfo.providerIds.forEach(value => {
      options.cpid = value.value
      options.cpName = value.label
    })
    this.AddInfo.sourceId.forEach(value => {
      options.sName = value.name
      options.sDesc = value.desc
      options.sIcon = value.icon
      options.sid = value.sid
    })
    if (
      this.AddInfo.images[0] &&
      this.AddInfo.images[0].type !== 'GAME_ICON_PIC'
    ) {
      Toast.warning('必须选择image...')
      return
    } else {
      let images = this.AddInfo.images
      var temp = {}
      images.forEach((item, index) => {
        if (item.type === 'GAME_ICON_GIF') {
          temp = item
          images.splice(index, 1)
          images.unshift(temp)
        }
      })
    }
    // 处理 originalImages
    this.AddInfo.originalImages = JSON.parse(JSON.stringify(this.AddInfo.images))
    this.AddInfo.originalImages.forEach((item, index) => {
      if (item.type === 'GAME_ICON_PIC') {
        item.url = this.originalImagesStr
        this.AddInfo.originalImages[index] = item
      }
    })
    // tag标签
    if (!this.AddInfo.tagsFirstValue && !!this.AddInfo.tagsSecondValue) {
      Toast.warning('请按照顺序选择标签')
      this.AddInfo.tags = []
      return
    }
    if (
      this.AddInfo.tagsFirstValue &&
      this.AddInfo.tagsSecondValue &&
      this.AddInfo.tagsFirstValue === this.AddInfo.tagsSecondValue
    ) {
      Toast.warning('选择的标签应该不一样')
      this.AddInfo.tags = []
      return
    }
    if (this.AddInfo.tagsFirstValue) {
      this.AddInfo.tags.push(this.AddInfo.tagsFirstValue)
    }
    if (this.AddInfo.tagsSecondValue) {
      this.AddInfo.tags.push(this.AddInfo.tagsSecondValue)
    }
    if (this.AddInfo.tags.length === 0) {
      Toast.warning('标签必须选择一个')
      this.AddInfo.tags = []
      return
    }
    if (this.AddInfo.tags.length > 2) {
      Toast.warning('标签最多选择两个')
      this.AddInfo.tags = []
      return
    }

    let searchOptions = Object.assign(options, regionLan, this.AddInfo)
    searchOptions.startTime =
      searchOptions.startTime === null ? 0 : searchOptions.startTime
    searchOptions.endTime =
      searchOptions.endTime === null ? 0 : searchOptions.endTime
    searchOptions.startTime = isNaN(searchOptions.startTime)
      ? Date.parse(searchOptions.startTime)
      : searchOptions.startTime
    searchOptions.endTime = isNaN(searchOptions.endTime)
      ? Date.parse(searchOptions.endTime)
      : searchOptions.endTime
    deleteAttribute(searchOptions, [
      'providerIds',
      'sourceId',
      'tagsFirstValue',
      'tagsSecondValue',
      'originalImagesStr'
    ])
    for (let prop in searchOptions) {
      if (searchOptions[prop] === '') {
        Toast.warning('请填写完必填信息')
        return
      }
    }

    // 事件检测
    if (searchOptions.endTime < searchOptions.startTime) {
      Toast.warning('发布时间必须在下线时间之前')
      return
    }

    let result = await GameAddAPI.addGame(searchOptions)
    if (result.data) {
      if (result.data.status && result.data.result) {
        this.AddInfo.tags = []
        Toast.success('上传成功')
        this.resetAddInfo()
        return true
      }
      if (!result.data.status) {
        this.AddInfo.tags = []
        Toast.error(result.data.msg)
      }
    } else {
      this.AddInfo.tags = []
      Toast.error('上传失败')
    }
  }
}

const gameAdd = new GameAdd()
export default gameAdd

import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function EnsureDeleteDialog (props) {
  const { openDelete, handleCloseDialog, deleteMsg, handleDelete } = props
  return (
    <Dialog
      open={openDelete}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'删除操作'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`确定删除条目为《${deleteMsg}》的项吗`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          取消
        </Button>
        <Button variant="outlined" color="primary" onClick={handleDelete}>
          确定
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnsureDeleteDialog

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography, MenuItem, Select } from '@material-ui/core'
import DateTimePicker from 'react-datetime'
import { intl } from '@src/IntlGlobalProvider'
import Toast from '@src/components/Toast'
import { observer, inject } from 'mobx-react'
import AsyncSelect from 'react-select/lib/Async'

const styles = theme => ({
  root: {
    width: 250,
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    background: '#fff',
    padding: theme.spacing.unit,
    borderRight: '1px solid #000'
  },
  filterHeader: {
    display: 'flex',
    position: 'sticky',
    top: -8,
    zIndex: 999,
    justifyContent: 'space-between',
    borderBottom: '1px solid #999',
    alignItems: 'center',
    height: 50,
    backgroundColor: 'white',
    marginBottom: '10px'
  },
  filterItem: {
    marginBottom: theme.spacing.unit
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    maiginBottom: 5
  },
  select: {
    width: '100%'
  },
  menu: {
    padding: '11px 8px'
  }
})

function getStyles (params, name, that) {
  return {
    fontWeight:
      that.state[params].indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  }
}

@inject('statistical')
@observer
class statisticalFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      regions: [],
      language: [],
      region: [],
      type: []
    }
  }
  componentDidMount () {
    this.props.statistical.resetInfo()
    this.getContry()
  }
  handleDateChange = key => date => {
    this.props.statistical.setValue(key, new Date(date).getTime())
  }
  handleChangeSelect = params => event => {
    const options = event.target.value
    if (params === 'region') {
      let data = []
      options.forEach(item => {
        data.push(item.slice(item.indexOf('(') + 1, item.indexOf(')')))
      })
      this.props.statistical.setValue(params, data)
      this.props.statistical.getCountryLanguage(data)
    } else {
      this.props.statistical.setValue(params, options)
    }
    this.setState({
      [params]: options
    })
  }
  getContry = async () => {
    let res = await this.props.statistical.getAddedArea()
    if (res.data) {
      if (res.data.status) {
        this.setState({
          regions: res.data.result
        })
      } else {
        let msg = res.data.msg
        Toast.warning(msg)
      }
    } else {
      Toast.error('fail...')
    }
  }
  handleInputChange = newValue => {
    // const inputValue = newValue.replace(/\W/g, '')
    return newValue
  }
  getOptions = keyValue => async inputValue => {
    const { region, type } = this.state
    if (inputValue === '') {
      return
    }
    let result = ''
    let res = []
    let area = []
    region.forEach(item => {
      area.push(item.slice(item.indexOf('(') + 1, item.indexOf(')')))
    })
    let data = {
      name: inputValue,
      region: area,
      keyValue: keyValue,
      type: type
    }
    result = await this.props.statistical.searchFilters(data)
    if (result.data) {
      if (result.data.status) {
        result.data.result.map(value => {
          let obj = {}
          if (keyValue === 'provider') {
            obj.value = value.pid
            obj.label = value.name
          }
          if (keyValue === 'source') {
            obj.value = value.sid
            obj.label = value.name
          }
          res.push(obj)
        })
      } else {
        var msg = result.data.msg
        Toast.warning(msg)
      }
    } else {
      Toast.error(result.head.msg)
    }
    return res || []
  }
  searchCb = params => value => {
    const { statistical } = this.props
    if (params === 'source') {
      statistical.setValue('source', value.label)
    } else {
      statistical.setValue('appId', value.value)
      statistical.setValue('providerName', value.label)
    }
  }
  searchGames = () => {
    this.props.statistical.searchFn()
  }
  render () {
    const { classes, theme, statistical } = this.props
    const { regions } = this.state
    const selectStyles = theme
    const searchInfo = statistical.searchInfo
    return (
      <div className={classes.root}>
        <div className={classes.filterHeader}>
          <Typography variant="h6" style={{ color: 'green' }}>
            Filters
          </Typography>
          <Button
            className={classes.submit}
            color="secondary"
            variant="outlined"
            onClick={this.searchGames}
          >
            {intl.formatMessage({ id: 'search' })}
          </Button>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>StartTime</Typography>
          </div>
          <DateTimePicker
            dateFormat="MM-DD"
            timeFormat="HH:mm:ss"
            closeOnSelect
            value={searchInfo.startTime}
            onChange={this.handleDateChange('startTime')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>EndTime</Typography>
          </div>
          <DateTimePicker
            dateFormat="MM-DD"
            timeFormat="HH:mm:ss"
            closeOnSelect
            value={searchInfo.endTime}
            onChange={this.handleDateChange('endTime')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>Region</Typography>
          </div>
          <Select
            multiple
            displayEmpty
            value={this.state.region}
            className={classes.select}
            onChange={this.handleChangeSelect('region')}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>please select region...</em>
              }

              return selected.join(',')
            }}
          >
            <MenuItem disabled value="">
              <em>please select region...</em>
            </MenuItem>
            {regions.map(item => {
              return (
                <MenuItem
                  value={`${item.name}(${item.code})`}
                  key={item.id}
                  style={getStyles('region', item.name, this)}
                >
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>Language</Typography>
            <Typography style={{ color: 'red', fontSize: '12px' }}>
              (please select the region first)
            </Typography>
          </div>
          <Select
            multiple
            displayEmpty
            value={this.state.language}
            className={classes.select}
            onChange={this.handleChangeSelect('language')}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>please select language...</em>
              }

              return selected.join(',')
            }}
          >
            <MenuItem disabled value="">
              <em>please select language...</em>
            </MenuItem>
            {statistical.languages.map((item, index) => {
              return (
                <MenuItem
                  value={item.value}
                  key={index}
                  // style={getStyles('language', item.value, this)}
                >
                  {item.label}
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>Type</Typography>
          </div>
          <Select
            multiple
            displayEmpty
            value={this.state.type}
            className={classes.select}
            onChange={this.handleChangeSelect('type')}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>please select type...</em>
              }

              return selected.join(',')
            }}
          >
            <MenuItem disabled value="">
              <em>please select type...</em>
            </MenuItem>
            {['NEWS', 'VIDEO', 'GAME', 'GIF', 'PICTURE'].map((item, index) => {
              return (
                <MenuItem
                  value={item}
                  key={index}
                  style={getStyles('type', item, this)}
                >
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>Provider</Typography>
          </div>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isSearchable
            classes={classes}
            styles={selectStyles}
            onInputChange={this.handleInputChange}
            loadOptions={this.getOptions('provider')}
            onChange={this.searchCb('appId')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>
            <Typography style={{ color: 'green' }}>Publisher</Typography>
          </div>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isSearchable
            classes={classes}
            styles={selectStyles}
            onInputChange={this.handleInputChange}
            loadOptions={this.getOptions('source')}
            onChange={this.searchCb('source')}
          />
        </div>
      </div>
    )
  }
}

statisticalFilter.PropTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(statisticalFilter)

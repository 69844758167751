/*
  UploadFile

  @props
    uploadApi     string    上传路径
    id            string    唯一id,指向属性htmlFor
    fileTypes     array     接收文件类型
    maxSize       number    接收文件最大值，单位B
    minSize       number    接收文件最小值，单位B
    uploadChange  function  上传成功回调
    forwardedRef  ref       父级可自定义ref，便于访问组件本身
    inputStyle    object    自定义样式

  @example
    <UploadFile
      uploadApi="/trend/upload/file"
      id="uploadFile"
      fileTypes={['image/png', 'image/jpeg']}
      maxSize={5 * 1024 * 1000}
      uploadChange={data => this.uploadChange(data)}
    />
*/

import React from 'react'
import PropTypes from 'prop-types'
import { fetchData } from '@src//utils/tools'
import Toast from '@src/components/Toast'
import { intl } from '@src/IntlGlobalProvider'

const uploadContainer = {
  position: 'relative',
  // width: 130,
  overflow: 'hidden',
  height: 39,
  marginTop: 10,
  minWidth: 130,
  maxWidth: 300
}

const buttonStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  border: '1px solid rgba(0, 0, 0, .23)',
  padding: '8px 16px',
  fontSize: 14,
  background: '#fff',
  cursor: 'pointer',
  borderRadius: 5
}

const inputStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  padding: '8px 0',
  zIndex: 99,
  opacity: 0
}

class UploadFile extends React.Component {
  constructor(props) {
    super(props)
    this.fileRef = React.createRef()
  }
  uploadFile() {
    const {
      forwardedRef,
      fileTypes,
      maxSize,
      minSize,
      needUploadWebp = false,
      needCheckWHSize = false,
      uploadApi,
      uploadFileName
    } = this.props
    const formData = new FormData()
    const cRef = forwardedRef || this.fileRef
    const file = cRef.current.files[0]
    formData.append('file', file)

    const getWebpFileByImageFile = imageFile => {
      const base64ToFile = (base64, fileName) => {
        let arr = base64.split(','),
          type = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], fileName, {
          type
        })
      }
      return new Promise((resolve, reject) => {
        const imageFileReader = new FileReader()
        imageFileReader.onload = function (e) {
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            const canvas = document.createElement("canvas")
            const defaultSize = 512
            const imageWidth = image.width
            const imageHeight = image.height
            let imgWidthStart = 0
            let imgHeightStart = 0
            let keepImageWidth = imageWidth
            let keepImageHeight = imageHeight
            // 图片为 长方形
            if (imageWidth !== imageHeight) {
              // 以短边为标准 切割为正方形后 然后再判断是否还要进一步压缩
              const min = Math.min(imageWidth, imageHeight)
              if (min > defaultSize) {
                canvas.width = defaultSize
                canvas.height = defaultSize
              } else {
                canvas.width = min
                canvas.height = min
              }
              if (imageWidth > imageHeight) {
                imgWidthStart = parseInt((imageWidth - imageHeight) / 2)
                keepImageWidth = imageHeight
              }
              if (imageWidth < imageHeight) {
                imgHeightStart = parseInt((imageHeight - imageWidth) / 2)
                keepImageHeight = imageWidth
              }
            } else {
              // 正方形
              if (imageWidth > defaultSize) {
                canvas.width = defaultSize
                canvas.height = defaultSize
              } else {
                canvas.width = imageWidth
                canvas.height = imageWidth
              }
            }
            canvas.getContext("2d").drawImage(image, imgWidthStart, imgHeightStart, keepImageWidth, keepImageHeight, 0, 0, canvas.width, canvas.height)

            resolve(base64ToFile(canvas.toDataURL("image/webp"), imageFile.name.split('.')[0] + `-${canvas.width}x${canvas.height}.webp`))
          }
        }
        imageFileReader.readAsDataURL(imageFile)
      })
    }
    const uploadWebpFile = async (file) => {
      const wfile = await getWebpFileByImageFile(file)
      const formData = new FormData()
      formData.append('file', wfile)
      // 上传原始图片
      fetchData({
        url: uploadApi,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        if (res.data.status) {
          Toast.success(intl.formatMessage({ id: 'uploadSuccess' }))
          this.props.uploadChange(res.data)
          // 返回文件名
          if (uploadFileName) {
            uploadFileName(file.name)
          }
        } else {
          // 清空文件名，后期可添加失败回调
          if (uploadFileName) {
            uploadFileName('')
          }
          Toast.error(intl.formatMessage({ id: 'uploadFail' }))
        }
      })
    }
    const checkWHSize = async (imageFile) => {
      const result = await new Promise((resolve, reject) => {
        const imageFileReader = new FileReader()
        imageFileReader.onload = function (e) {
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            const imageWidth = image.width
            const imageHight = image.width
            resolve(imageWidth === imageHight)
          }
        }
        imageFileReader.readAsDataURL(imageFile)
      })
      console.log('size ' + result)
      return result
    }
    // 校验
    if (fileTypes && fileTypes.length) {
      let fileType = file.type
      if (!fileTypes.includes(fileType)) {
        Toast.error(
          `${intl.formatMessage({ id: 'fileType' })}${fileTypes.join()}`
        )
        return
      }
    }

    if (needCheckWHSize && !checkWHSize()) {
      Toast.error('请上传正方形图片')
      return
    }

    if (maxSize !== undefined) {
      let filesSize = file.size
      if (filesSize > maxSize) {
        Toast.error(`${intl.formatMessage({ id: 'maxSize' })}${maxSize}`)
        return
      }
    }

    if (minSize !== undefined) {
      let filesSize = file.size
      if (filesSize < minSize) {
        Toast.error(`${intl.formatMessage({ id: 'minSize' })}}${minSize}`)
        return
      }
    }

    // 上传原始图片
    fetchData({
      url: uploadApi,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      if (res.data.status) {
        Toast.success(intl.formatMessage({ id: 'uploadSuccess' }))
        // let data = {...res.data, fileName: file.name}
        // this.props.uploadChange(data)
        if (!needUploadWebp) {
          this.props.uploadChange(res.data)
        } else {
          this.props.uploadOriginSuccess(res.data)
        }
        // 返回文件名
        if (uploadFileName) {
          uploadFileName(file.name)
        }
      } else {
        // 清空文件名，后期可添加失败回调
        if (uploadFileName) {
          uploadFileName('')
        }
        Toast.error(intl.formatMessage({ id: 'uploadFail' }))
      }
    })

    if (needUploadWebp) {
      uploadWebpFile(file)
    }
  }

  handleFileName(e) {
    if (!e.target.value) {
      return
    }
    this.uploadFile()
  }

  render() {
    const { forwardedRef, id, fileTypes, textProp } = this.props
    return (
      <div style={uploadContainer}>
        <button style={buttonStyle}>
          {textProp || intl.formatMessage({ id: 'uploadImg' })}
        </button>
        <input
          accept={fileTypes.join()}
          style={inputStyle}
          id={id}
          type="file"
          ref={forwardedRef || this.fileRef}
          onChange={e => this.handleFileName(e)}
        />
      </div>
    )
  }
}

UploadFile.propTypes = {
  id: PropTypes.string.isRequired,
  uploadApi: PropTypes.string.isRequired,
  uploadChange: PropTypes.func.isRequired
}

UploadFile.defaultProps = {
  fileTypes: []
}

function correctRef(Component) {
  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />
  })
}

export default correctRef(UploadFile)

import Notification from './Notification'

let notification
const getNotification = () => {
  if (!notification) {
    notification = Notification.reWrite()
  }

  return notification
}
function notice (type, content, duration) {
  let notification = getNotification()
  notification.notice({
    type,
    content,
    duration
  })
}
const types = ['info', 'success', 'warning', 'error']
const Toast = {}

types.forEach(type => {
  Toast[type] = (content, duration) => {
    notice(type, content, duration)
  }
})

export default Toast

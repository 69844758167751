import { Types } from './lang'
/**
 * object -> query string
 * @param {Object} query
 * @return {String} a=1&b=2
 */
export function encodeQuery (query = {}) {
  return Object.keys(query).reduce((sum, key, idx) => {
    let value = query[key]
    if (Types.isObject(value) || Types.isArray(value)) {
      value = JSON.stringify(value)
    }
    return sum + (idx > 0 ? '&' : '') + key + '=' + encodeURIComponent(value)
  }, '')
}

import XLSX from 'xlsx'

// excel 转 json
function excelToJson (e, cb) {
  const reader = new FileReader()
  const file = e.target.files[0]

  reader.onload = function (loadEvent) {
    const data = new Uint8Array(loadEvent.target.result)
    const workbook = XLSX.read(data, { type: 'array' })
    const json = XLSX.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]]
    )

    if (typeof cb === 'function') {
      cb(json)
    }
  }
  reader.readAsArrayBuffer(file)
}

// json 转 excel 并下载
/**
 * 生成excel并下载
 * @param {Array<Object>} json 数据列表
 * @param {any | undefined} listHead
 * @param {Object} options
 * @param {String} options.filename
 */
function downloadExl (json, listHead, options = {}) {
  const filename = options.filename || 'download.xlsx'
  const wsName = 'SheetJS'
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(json, {
    header: listHead
  })
  const colWidth = []

  json.forEach(data => {
    Object.entries(data).forEach(([key, value], index) => {
      colWidth[index] = Math.max(
        key.length,
        String(value).length,
        colWidth[index] || 0
      )
    })
  })
  ws['!cols'] = colWidth.flatMap(item => ({ wch: item + 2 }))
  XLSX.utils.book_append_sheet(wb, ws, wsName)
  XLSX.writeFile(wb, filename)
}

/**
 * 生成excel并下载
 * @param {Array<Object>} dataArray 数据列表
 * @param {Object} options
 * @param {String} options.filename
 * @param {any|undefined} options.listHead
 */
function downloadExl2 (dataArray, options = {}) {
  return downloadExl(dataArray, options.listHead, options)
}

export { excelToJson, downloadExl, downloadExl2 }

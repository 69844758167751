import { intlShape } from 'react-intl'

let INTL
let instance

const IntlGlobalProvider = (props, context) => {
  INTL = context.intl
  return props.children
}

class IntlTranslator {
  constructor () {
    if (!instance) {
      instance = this
    }

    return instance
  }

  formatMessage (message, values) {
    return INTL.formatMessage(message, values)
  }
}

IntlGlobalProvider.contextTypes = {
  intl: intlShape.isRequired
}

export const intl = new IntlTranslator()
export default IntlGlobalProvider

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from './ContentManage'

class NewsFilterAPI {
  searchFilters = data => {
    return fetchData({
      url:
        `${data.type}/match?name=${data.name}` +
        `&region=${
          authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}&pn=1&ps=50`,
      method: 'GET'
    })
  }
  getCountryLanguage = data => {
    return fetchData({
      url: `region/language/list?region=${data}`,
      method: 'GET'
    })
  }
}

class NewsFilter {
  constructor (api) {
    this.newsApi = api
  }

  @observable
  filters = {
    title: '',
    docId: '',
    sources: [],
    language: '',
    lans: [],
    state: -1,
    publishTimeStart: null,
    publishTimeEnd: null,
    storeTimeStart: null,
    storeTimeEnd: null,
    cpCates: [],
    cpTags: [],
    cates: [],
    tags: [],
    providerIds: [],
    audit: -1,
    edit: -1,
    ad: -1,
    style: -1,
    minCpClick: '',
    maxCpClick: '',
    minViews: '',
    maxViews: '',
    sortType: 'DESC',
    sortField: 'publishTime',
    boutiques: -1,
    editor: ''
  }
  @observable
  languages = []

  @action
  searchFilters = (name, type) => {
    return this.newsApi.searchFilters({ name, type })
  }

  @action
  updateFilter = (attr, value) => {
    this.filters[attr] = value
  }
  @action
  getCountryLanguage = async () => {
    let data = {
      region: authInfo.region.code
    }
    let result = await this.newsApi.getCountryLanguage(data.region)
    if (!!result.data && result.data.status) {
      let arr = []
      result.data.result.forEach(element => {
        let datas = {
          value: element,
          label: element
        }
        arr.push(datas)
      })
      arr.unshift(
        { value: 'all', label: 'All' },
        { value: 'others', label: 'Others' }
      )
      this.languages = arr
    }
  }
  @action
  resetFilters = () => {
    this.filters = {
      title: '',
      docId: '',
      sources: [],
      language: '',
      lans: [],
      state: -1,
      publishTimeStart: null,
      publishTimeEnd: null,
      storeTimeStart: null,
      storeTimeEnd: null,
      cpCates: [],
      cpTags: [],
      cates: [],
      tags: [],
      providerIds: [],
      audit: -1,
      edit: -1,
      ad: -1,
      style: -1,
      minCpClick: '',
      maxCpClick: '',
      minViews: '',
      maxViews: '',
      sortType: 'DESC',
      sortField: 'publishTime',
      boutiques: -1,
      editor: ''
    }
  }
}

const newsFilterAPI = new NewsFilterAPI()
const newsFilter = new NewsFilter(newsFilterAPI)

export default newsFilter

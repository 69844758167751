import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from './../ContentManage/ContentManage'
import Toast from '@src/components/Toast'

let themeApi = {
  getThemeList: data => {
    return fetchData({
      url: 'theme/list',
      method: 'POST',
      data: data
    })
  },
  getDetail: docId => {
    return fetchData({
      url: 'theme/info',
      method: 'GET',
      data: {
        docId: docId
      }
    })
  },
  editTheme: data => {
    return fetchData({
      url: 'theme/edit',
      method: 'POST',
      data: data
    })
  },
  addTheme: data => {
    return fetchData({
      url: 'theme/add',
      method: 'POST',
      data: data
    })
  },
  lineTheme: options => {
    let data
    if (options.lineStyle === 'offline') {
      data = {
        docId: options.docId,
        reason: options.reason
      }
    }
    if (options.lineStyle === 'online') {
      data = {
        docId: options.docId
      }
    }
    return fetchData({
      url: `doc/${options.lineStyle}`,
      method: 'GET',
      data: Object.assign(data, {
        type: contentManage.contentType.toUpperCase()
      })
    })
  }
}

class ThemeManage {
  @observable
  themeList = []
  @observable
  pageSize = 10
  @observable
  pageNum = 1
  @observable
  sum = 0

  @action
  resetPageNum = () => {
    this.pageNum = 1
  }
  @action
  updatePageNum = (attr, value) => {
    this[attr] = value
  }
  @action
  getThemeList = async pageNum => {
    if (pageNum && pageNum !== this.pageNum) {
      this.pageNum = this.pageNum
    }
    let options = {
      pageNum: this.pageNum,
      pageSize: this.languages,
      region: authInfo.region.code,
      sortType: 'DESC',
      sortField: 'addTime'
    }
    try {
      let result = await themeApi.getThemeList(options)
      if (result.data) {
        if (result.data.status) {
          this.themeList = result.data.result.list || []
          this.sum = result.data.result.count || 0
        }
      }
    } catch (e) {
      Toast.error(e)
    }
  }
  @action
  getThemeDetail = async docId => {
    return themeApi.getDetail(docId)
  }
  @action
  addTheme = async data => {
    let options = Object.assign(data, {
      region: authInfo.region.code
    })
    try {
      let result = await themeApi.addTheme(options)
      if (result.data && result.data.status) {
        Toast.success('success')
      }
    } catch (e) {
      Toast.error(e)
    }
  }
  @action
  editTheme = data => {
    let postData = Object.assign(data, { region: authInfo.region.code })
    return themeApi.editTheme(postData)
  }
  // 上线下线
  @action
  lineTheme = data => {
    return themeApi.lineTheme(data)
  }
}

const themeManage = new ThemeManage()
export default themeManage

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'

class ContentManage {
  @observable
  contentType =
    window.localStorage.getItem('contentType') ||
    (authInfo.user &&
      authInfo.user.manageContent &&
      authInfo.user.manageContent[0]) ||
    'Video'

  @observable
  paramsPkgType = window.localStorage.getItem('paramsPkgType')
  @action
  updateContentType = type => {
    if (type === this.contentType) {
      return
    }

    this.contentType = type
    window.localStorage.setItem('contentType', this.contentType)
  }

  @action
  updateparamsPkgType = type => {
    if (type === this.paramsPkgType) {
      return
    }

    this.paramsPkgType = type
    window.localStorage.setItem('paramsPkgType', this.paramsPkgType)
  }
  @action
  isEditing = (docId, flag) =>
    fetchData({
      url: 'doc/action/edit',
      data: {
        docid: docId,
        flag: flag
      },
      method: 'GET'
    })

  @action
  addBoutique = data => {
    data = Object.assign({ type: this.contentType.toUpperCase() }, data)
    return fetchData({
      url: 'doc/boutiques',
      data: data,
      method: 'GET'
    })
  }

  @action
  cropImage = data =>
    fetchData({
      url: 'crop/image',
      data: data,
      method: 'GET'
    })

  @action
  downloadImage = data =>
    fetchData({
      url: 'download/file',
      data: data,
      method: 'GET'
    })

  @action
  getEditCatList = lan =>
    fetchData({
      url: 'category/edit/list',
      data: {
        region: authInfo.region.code,
        lan: lan || authInfo.region.code.toLowerCase(),
        type: this.contentType.toUpperCase()
      },
      method: 'GET'
    })
}

const contentManageStore = new ContentManage()

export default contentManageStore

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import Toast from '@src/components/Toast'

var API = {
  searchFilters: data => {
    if (data.keyValue === 'source') {
      return fetchData({
        url: `source/matches`,
        method: 'POST',
        data: {
          regions: data.region,
          types: data.type,
          name: data.name,
          ps: 10,
          pn: 1
        }
      })
    }
    return fetchData({
      url: `provider/matches`,
      method: 'POST',
      data: {
        regions: data.region,
        name: data.name
      }
    })
  },
  search: data => {
    return fetchData({
      url: 'statistics/multi',
      data: data,
      type: 'POST'
    })
  },
  getAddedArea: () => {
    return fetchData({
      url: 'region/list',
      method: 'GET'
    })
  },
  getCountryLanguage: data => {
    return fetchData({
      url: `region/language/list/regions`,
      method: 'POST',
      data: data
    })
  }
}

class Statistical {
  @observable
  searchInfo = {
    startTime:
      new Date().getFullYear() +
      '-' +
      (new Date().getMonth() + 1 > 10
        ? new Date().getMonth() + 1
        : '0' + (new Date().getMonth() + 1)) +
      '-' +
      (new Date().getDate() - 1) +
      ' ' +
      new Date().getHours() +
      ':' +
      new Date().getMinutes(),
    endTime: new Date().getTime(),
    appId: '',
    providerName: '',
    region: [],
    language: [],
    type: [],
    source: ''
  }
  @observable
  infoList = []
  @observable
  languages = []

  @action
  searchFilters = data => {
    return API.searchFilters(data)
  }
  @action
  getCountryLanguage = async region => {
    let data = {
      regions: region
    }
    let result = await API.getCountryLanguage(data)
    if (!!result.data && result.data.status) {
      let arr = []
      result.data.result.forEach(element => {
        let datas = {
          value: element,
          label: element
        }
        arr.push(datas)
      })
      arr.unshift(
        { value: 'all', label: 'All' },
        { value: 'others', label: 'Others' }
      )
      this.languages = arr
    }
  }
  @action
  searchFn = async () => {
    let searchOptions = Object.assign({}, this.searchInfo)
    searchOptions.startTime =
      searchOptions.startTime == null ? 0 : searchOptions.startTime
    searchOptions.endTime =
      searchOptions.endTime == null ? 0 : searchOptions.endTime
    searchOptions.startTime = isNaN(searchOptions.startTime)
      ? Date.parse(searchOptions.startTime)
      : searchOptions.startTime
    searchOptions.endTime = isNaN(searchOptions.endTime)
      ? Date.parse(searchOptions.endTime)
      : searchOptions.endTime
    if (searchOptions.startTime > searchOptions.endTime) {
      Toast.warning('请选择正确的时间...')
      return
    }
    let res = await API.search(searchOptions)
    if (res.data) {
      if (res.data.status) {
        this.infoList = res.data.result
      } else {
        let msg = res.data.msg
        Toast.warning(msg)
      }
    } else {
      Toast.error('query fail...')
    }
  }
  @action
  setValue = (Params, value) => {
    this.searchInfo[Params] = value
  }
  @action
  resetInfo = () => {
    this.searchInfo = {
      startTime:
        new Date().getFullYear() +
        '-' +
        (new Date().getMonth() + 1 > 10
          ? new Date().getMonth() + 1
          : '0' + (new Date().getMonth() + 1)) +
        '-' +
        (new Date().getDate() - 1) +
        ' ' +
        new Date().getHours() +
        ':' +
        new Date().getMinutes(),
      endTime: new Date().getTime(),
      appId: '',
      providerName: '',
      region: [],
      language: [],
      type: [],
      source: ''
    }
  }
  @action
  getAddedArea = () => {
    return API.getAddedArea()
  }
}
const statistical = new Statistical()
export default statistical

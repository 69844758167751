import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Notice from './Notice'

let noticeNumber = 0
function guid () {
  return `notification-${new Date().getTime()}-${noticeNumber++}`
}
class Notification extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      notices: []
    }
  }

  add (notice) {
    const { notices } = this.state
    !notice.key && (notice.key = guid())
    if (notices.indexOf(notice) === -1) {
      notices.push(notice)
      this.setState({
        notices: notices
      })
      // this.state.notices.push(notice);
      // this.forceUpdate();
    }
  }
  remove (key) {
    this.setState(prevState => ({
      notices: prevState.notices.filter(notice => notice.key !== key)
    }))
  }

  render () {
    const { prefixCls } = this.props
    const { notices } = this.state
    return (
      <div className={prefixCls}>
        {notices.map(notice => {
          const closeCallback = () => {
            this.remove(notice.key)
          }
          return <Notice {...notice} key={notice.key} onClose={closeCallback} />
        })}
      </div>
    )
  }

  static reWrite = function (props = {}) {
    let div = document.createElement('div')
    document.body.prepend(div)

    const notification = ReactDOM.render(<Notification {...props} />, div)
    return {
      notice (noticeProps) {
        notification.add(noticeProps)
      },
      removeNotice (key) {
        notification.remove(key)
      },
      destroy () {
        ReactDOM.unmountComponentAtNode(div)
        document.body.removeChild(div)
      },
      component: notification
    }
  }
}

Notification.propTypes = {
  prefixCls: PropTypes.string
}

Notification.defaultProps = {
  prefixCls: 'zhj-notification'
}

export default Notification

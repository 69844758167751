import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Typography,
  Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { inject } from 'mobx-react'
import Toast from '@src/components/Toast/index'

const styles = theme => ({
  root: {
    textAlign: 'center',
    height: '100vh',
    width: '100%',
    position: 'absolute',
    'z-index': '100000000',
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'center',
    'justify-content': 'center',
    'background': 'linear-gradient(to right, #cfdef3, #e0eafc)',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    'flex-direction': 'column',
    'align-items': 'center',
  },
  textField: {
    width: 300,
  },
  helperText: {
    color: 'red',
  },
  button: {
    margin: theme.spacing.unit,
    width: '300px'
  },
})

@inject('authInfo')
class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      password: '',
      loading: false,
      tips: '',
      loginBtnText: '登录',
    }
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  };
  LoginAPI = async () => {
    const { authInfo } = this.props
    const data = {
      name: this.state.name,
      password: this.state.password,
    }
    this.setState({
      loading: true,
      tips: ''
    })
    const timer = setInterval(() => {
      const text = this.state.loginBtnText
      if (text.length === 2) {
        this.setState({
          loginBtnText: '登录中'
        })
      } else if (text.length === 3) {
        this.setState({
          loginBtnText: '登录中.'
        })
      } else if (text.length === 4) {
        this.setState({
          loginBtnText: '登录中..'
        })
      } else if (text.length === 5) {
        this.setState({
          loginBtnText: '登录中...'
        })
      } else if (text.length === 6) {
        this.setState({
          loginBtnText: '登录中'
        })
      }
    }, 500)
    const result = await authInfo.loginFn(data)

    clearInterval(timer)
    if (result !== true) {
      Toast.error(result)
      this.setState({
        loading: false,
        tips: result,
        loginBtnText: '登录'
      })
    } else {
      authInfo.getUserInfo()
      window.location.hash = '/'
    }
  };
  keyupLoginAPI = (e) => {
    if (e.keyCode === 13) {
      // console.log('登录')
    }
  }
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Typography variant="h4">Please Login</Typography>
        <div className={classes.container}>
          <TextField
            required
            label="用户名"
            autoFocus={true}
            placeholder="请输入用户名"
            margin="normal"
            variant="outlined"
            disabled={this.state.loading}
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange('name')}
            type="text"
          />
          <TextField
            required
            label="密码"
            placeholder="请输入密码"
            margin="normal"
            variant="outlined"
            disabled={this.state.loading}
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange('password')}
            type="password"
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            helperText={this.state.tips}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={this.state.name == '' || this.state.password == '' || this.state.loading}
          className={classes.button}
          onClick={this.LoginAPI}
          size="large"
        >
          {this.state.loginBtnText}
        </Button>
      </div>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Index)

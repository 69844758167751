import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  product: {
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    paddingLeft: 40,
    marginTop: 30,
    fontSize: '36px'
  },
  cardStyle: {
    width: 300,
    height: 200,
    margin: 10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #00c1de',
      boxShadow: '0 0 20px rgba(0, 131, 143, .5)'
    }
  },
  content: {
    textAlign: 'center'
  },
  icon: {
    width: 100,
    height: 100,
    marginBottom: 10
  },
  formActionMenu: {
    width: 480,
    display: 'flex',
    padding: '20px',
    justifyContent: 'space-around'
  },
  paperRoot: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  tips: {
    paddingLeft: 20
  }
})

@inject('authInfo', 'contentManage')
@observer
class ProductSelect extends Component {
  componentDidMount() {
    const { authInfo } = this.props
    authInfo.showProductMenu(false)
  }

  handleClick = ({ category, subPath }) => () => {
    const { authInfo, history, contentManage } = this.props
    const manageContent =
      (authInfo.user && authInfo.user.manageContent[0]) || 'Game'
    const contentType = subPath === '/panipuri' ? 'Video' : manageContent
    authInfo.changeProduct(category)

    // Panipuri 只有Video频道，点击进入时需要设置contentType为Video
    // News Feed默认选择第一个
    contentManage.updateContentType(contentType)
    // 选择不同的框，里面每个接口连接这个字符串

    // const paramsPkgType = subPath === '/panipuri' ? 'panipuri' : 'newsFeed';
    let paramsPkgType = ''
    if (subPath === '/panipuri') {
      paramsPkgType = 'panipuri'
    }
    if (subPath === '/newsFeed') {
      paramsPkgType = 'newsFeed'
    }
    if (subPath === '/contentsCheck') {
      paramsPkgType = 'contentsCheck'
    }
    if (subPath === '/statistical') {
      paramsPkgType = 'statistical'
    }
    if (subPath === '/batchoffline') {
      paramsPkgType = 'batchoffline'
    }
    contentManage.updateparamsPkgType(paramsPkgType)
    if (category === 'ContentsCheck') {
      history.push(`/contentsCheck`)
      return
    }
    if (category === 'Statistical') {
      history.push(`/statistical`)
      return
    }
    if (category === 'BatchOffline') {
      history.push('/batchoffline')
      return
    }
    history.push(`/regionSelect`)
  }

  renderProduction(productConf, classes) {
    const { authInfo } = this.props
    if (!authInfo.user) {
      return null
    }
    const manageProduct = (authInfo.user && authInfo.user.manageProduct) || []
    console.log(productConf, manageProduct)
    let indexArr = []
    let result = productConf.reduce((acc, item) => {
      if (manageProduct.includes(item.value) || authInfo.user.role === 2) {
        indexArr.push(item.value)
        acc.push(
          <Card
            className={classes.cardStyle}
            key={item.category}
            onClick={this.handleClick(item)}
          >
            <CardContent className={classes.content}>
              <img className={classes.icon} src={item.icon} />
              <h3>{item.category}</h3>
            </CardContent>
          </Card>
        )
      }
      if (
        (item.value === 'STATISTICAL' && !indexArr.includes(item.value)) ||
        (item.value === 'CONTENTSCHECK' && !indexArr.includes(item.value))
      ) {
        acc.push(
          <Card
            className={classes.cardStyle}
            key={item.category}
            onClick={this.handleClick(item)}
          >
            <CardContent className={classes.content}>
              <img className={classes.icon} src={item.icon} />
              <h3>{item.category}</h3>
            </CardContent>
          </Card>
        )
      }
      return acc
    }, [])

    if (result.length === 0) {
      return <p className={classes.tips}>请确认你的权限中勾选了对应的产品！</p>
    }
    return result
  }

  render() {
    const { productConf, classes } = this.props
    return (
      <React.Fragment>
        <h2 className={classes.title}>Product Selection</h2>
        <div className={classes.product}>
          {this.renderProduction(productConf, classes)}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(ProductSelect))

export const CRAWL_TASK_TYPES = {
  videoId: {
    label: 'VideoID',
    value: 0
  },
  channelId: {
    label: 'ChannelID',
    value: 1
  },
  keywords: {
    label: 'Keywords',
    value: 2
  },
  playListLink: {
    label: 'Playlist Link',
    value: 3
  },
  superTitleLink: {
    label: 'Super Title Link',
    value: 4
  },
  instagramAccount: {
    label: 'Instagram_account',
    value: 5 // TODO
  },
  instagramHashTag: {
    label: 'Instagram_hashtag',
    value: 6 // TODO
  },
  vkAccount: {
    label: 'VK_account',
    value: 7 // TODO
  },
  vkKeywords: {
    label: 'VK_keyword',
    value: 8 // TODO
  },
  tiktokAccount: {
    label: 'Tiktok_account',
    value: 9 // TODO
  },
  tiktokKeywords: {
    label: 'Tiktok_keyword',
    value: 10 // TODO
  }
}

export const CRAWL_TASK_TYPE_VALUES = Object.values(CRAWL_TASK_TYPES)

export const SELECT_PUBLISH_TIME = [
  {
    label: 'Today',
    value: 1
  },
  {
    label: 'This week',
    value: 2
  },
  {
    label: 'This month',
    value: 3
  },
  {
    label: 'This year',
    value: 4
  }
]

import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import DatePicker from 'react-datetime'
import AsyncSelect from 'react-select/lib/Async'
import classNames from 'classnames'
import commonApi from '@src/models/CommonApi/index.js'
import UploadFile from '@src/components/UploadFile'
import Toast from '@src/components/Toast'
import GameEditTitle from './../GameAdd/GameEditTitle'
import AlertDialog from '@src/components/AlertDialog'
const styles = theme => ({
  table: {
    width: 550,
    '& td': {
      paddingRight: 24
    },
    '& td>em': {
      color: 'red',
      fontSize: 20,
      display: 'inline-block',
      lineHeight: '20px',
      transform: 'translate(-5px, 7px)'
    }
  },
  lastCellRu: {
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  lastCellIn: {
    minHeight: '110px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  textField: {
    width: 300,
    height: 40,
    margin: 0,
    '@global': {
      input: {
        padding: '11px 14px'
      }
    }
  },
  textFieldTime: {
    width: 300,
    height: 40,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > input': {
      padding: '11px 14px'
    }
  },
  titleBtn: {
    width: '300px',
    marginRight: '10px',
    backgroundColor: '#eee',
    textTransform: 'initial'
  },
  onTime: {
    '& > div': {
      left: 0,
      top: 35
    }
  },
  offTime: {
    '& > div': {
      left: 0,
      top: 35
    }
  },
  textFieldSelect: {
    width: 300,
    height: 40,
    margin: 0,
    '& > div': {
      '& > div': {
        '& > div:first-child': {
          padding: '11px 14px'
        }
      }
    }
  },
  posterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    marginTop: 5
  },
  closeBtn: {
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    position: 'absolute',
    top: '-5px',
    right: '2px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      width: '14px',
      height: '14px',
      position: 'relative',
      marginTop: '1px'
    }
  },
  closeRight: {
    position: 'absolute',
    top: '5px',
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(45deg)',
    '-webkit-transform': 'rotate(45deg)'
  },
  closeLeft: {
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    display: 'inline-block',
    marginBottom: '4px',
    transform: 'rotate(-45deg)',
    '-webkit-transform': 'rotate(-45deg)'
  },
  poster: {
    marginRight: 10,
    width: 75,
    height: 75,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  posterOperate: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: 16,
      height: 16
    }
  },
  providerSelect: {
    width: '300px',
    height: '40px'
  },
  iconBarColorBtn: {
    width: '180px',
    backgroundColor: 'aliceblue',
    boxSizing: 'content-box',
    marginRight: '5px',
    '& + ul': {
      width: 250,
      height: 250,
      backgroundColor: '#ffffff',
      position: 'absolute',
      top: '38px',
      left: '0',
      zIndex: 9,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > li': {
        width: 80,
        height: 80,
        borderRadius: 10,
        cursor: 'pointer',
        textIndent: '-999px'
      }
    }
  },
  first_themeColor: {
    background: 'linear-gradient(to right,#ef5e38,#f08b63)'
  },
  second_themeColor: {
    background: 'linear-gradient(to right,#f06b2d,#f1a35d)'
  },
  third_themeColor: {
    background: 'linear-gradient(to right,#e69413,#eec243)'
  },
  fourth_themeColor: {
    background: 'linear-gradient(to right,#15be69,#2ce285)'
  },
  fifth_themeColor: {
    background: 'linear-gradient(to right,#59b223,#7ad353)'
  },
  sixth_themeColor: {
    background: 'linear-gradient(to right,#2882ec,#4eb2f8)'
  },
  seventh_themeColor: {
    background: 'linear-gradient(to right,#3243ff,#52a0ff)'
  },
  eighth_themeColor: {
    background: 'linear-gradient(to right,#9e37ff,#eb5aff)'
  },
  ninth_themeColor: {
    background: 'linear-gradient(to right,#682aff,#894be8)'
  },
  preview: {
    width: 90,
    height: 130,
    borderRadius: 10,
    '& > img': {
      width: 90,
      height: 90,
      borderRadius: 10
    },
    '& > div': {
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 3,
      marginTop: 4,
      height: 30,
      '& > p': {
        lineHeight: '14px'
      }
    }
  },
  rightSelectIcon: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  }
})
const TAG_TYPE = [
  // 'recommend',
  'history_',
  'must play_',
  'hot games_',
  'game card_',
  'new game_',
  'app vault_'
]

const AREAS = [
  { value: 'top_picks', label: 'TOP PICKS' },
  { value: 'new_games', label: 'NEW GAMES' },
  { value: 'favorite_recommend', label: 'FAVORITE RECOMMEND' },
  { value: 'recent_recommend', label: 'RECENT RECOMMEND' },
  { value: 'detail_recommend', label: 'DETAIL RECOMMEND' },
  { value: 'mobile_all', label: 'MOBILE ALL' }
]

@inject('gameAdd', 'authInfo')
@observer
class FormDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gameInfo: props.gameInfo,
      tagsFirstValue: '',
      tagsSecondValue: '',
      icon: '' || require('./../../../assets/default.png'),
      gif: '' || require('./../../../assets/default.png'),
      banner: '' || require('./../../../assets/default.png'),
      themeSwitch: false,
      themeColorClass: '',
      themePreview: false,
      gifDeleteBtn: false,
      bannerDeleteBtn: false,
      titleSwtch: false,
      dialogChildren: null,
      dialogTitle: ''
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.gameInfo !== this.props.gameInfo) {
      this.setState(
        {
          gameInfo: nextProps.gameInfo,
          icon: '' || require('./../../../assets/default.png'),
          gif: '' || require('./../../../assets/default.png'),
          banner: '' || require('./../../../assets/default.png')
        },
        () => {
          if (
            ['ru', 'es'].findIndex(
              item =>
                item === this.props.authInfo.region.code.toLocaleLowerCase()
            ) > -1
          ) {
            let firstValue = ''
            let secondValue = ''
            this.state.gameInfo.tags.forEach(item => {
              if (TAG_TYPE.findIndex(age => age === item) < 0) {
                if (firstValue === '') {
                  firstValue = item
                  return
                }
                if (firstValue && secondValue === '') {
                  secondValue = item
                }
              }
            })
            this.setState({
              tagsFirstValue: firstValue,
              tagsSecondValue: secondValue
            })
          }
          this.updateImg(this.state.gameInfo.images)
        }
      )
    }
  }
  componentDidMount() {
    const { gameAdd } = this.props
    gameAdd.getTagList()
  }

  updateImg = images => {
    images.forEach(item => {
      switch (item.type) {
        case 'GAME_ICON_PIC':
          this.setState({
            icon: item.url
          })
          break
        case 'GAME_ICON_GIF':
          this.setState({
            gif: item.url,
            gifDeleteBtn: true
          })
          break
        case 'GAME_ICON_BANNER':
          this.setState({
            banner: item.url,
            bannerDeleteBtn: true
          })
          break
      }
    })
  }

  submitEdit = () => {
    const { tagsFirstValue, tagsSecondValue, gameInfo } = this.state
    if (
      ['ru', 'es'].findIndex(
        item => item === this.props.authInfo.region.code.toLocaleLowerCase()
      ) > -1
    ) {
      let arr = []
      if (!tagsFirstValue && !!tagsSecondValue) {
        Toast.warning('请按照顺序选择标签')
        return
      }
      if (
        !!tagsFirstValue &&
        !!tagsSecondValue &&
        tagsFirstValue === tagsSecondValue
      ) {
        Toast.warning('选择的标签应该不一样')
        return
      }
      if (tagsFirstValue) {
        arr.push(tagsFirstValue)
      }
      if (tagsSecondValue) {
        arr.push(tagsSecondValue)
      }
      if (arr.length === 0 || arr.length > 2) {
        Toast.warning('标签最少一个，最多两个!')
        return
      }
      this.setState(
        {
          gameInfo: { ...gameInfo, tags: arr }
        },
        () => {
          this.submit()
        }
      )
    } else {
      let arr = []
      this.state.gameInfo.tags.forEach(value => {
        if (TAG_TYPE.findIndex(age => age === value) < 0) {
          if (typeof value === 'object') {
            arr.push(value.label)
          } else {
            arr.push(value)
          }
        }
      })
      if (arr.length === 2 && arr[0] === arr[1]) {
        Toast.warning('选择的标签应该不一样')
        return
      }
      if (arr.length > 2) {
        Toast.warning('标签最少一个，最多两个')
        return
      }
      if (arr.length === 0 || arr.length > 2) {
        Toast.warning('标签最多有两个普通标签')
        return
      }
      this.setState(
        {
          gameInfo: { ...gameInfo, tags: arr }
        },
        () => {
          this.submit()
        }
      )
    }
  }

  submit = () => {
    const { submitGameInfo } = this.props
    const { gameInfo } = this.state
    if (typeof submitGameInfo === 'function') {
      let images = gameInfo.images
      var temp = {}
      if (images.length !== 0) {
        if (images.length >= 1) {
          images.forEach((item, index) => {
            if (item.type === 'GAME_ICON_GIF') {
              temp = item
              images.splice(index, 1)
              images.unshift(temp)
            }
          })
        } else if (!images[0].type) {
          images[0].type = 'GAME_ICON_PIC'
        }
        this.setState(
          {
            themePreview: false,
            themeColorClass: '',
            gameInfo: Object.assign(gameInfo, images)
          },
          () => {
            submitGameInfo(gameInfo)
          }
        )
      } else {
        this.setState({
          themePreview: false,
          themeColorClass: ''
        })
        submitGameInfo(gameInfo)
      }
    }
  }

  handleClose = () => {
    const { handleClose } = this.props

    if (handleClose) {
      this.setState({
        themePreview: false,
        themeColorClass: ''
      })
      handleClose()
    }
  }
  // deleteUploadGif = () => {
  //   const { icon, gameInfo } = this.state
  //   if (icon === '' || icon === require('./../../../assets/default.png')) {
  //     this.setState({
  //       themePreview: false
  //     })
  //   }
  //   let imagesL = []
  //   imagesL.push(gameInfo.images[0])
  //   this.setState({
  //     gifDeleteBtn: false,
  //     gif: require('./../../../assets/default.png'),
  //     gameInfo: Object.assign(gameInfo, { images: imagesL })
  //   })
  //   document.getElementById('uploadGif').value = ''
  // }

  deleteUploadImage = type => e => {
    const { gameInfo } = this.state
    const imagesL = gameInfo.images.filter(item => item.type !== type)
    const defaultImg = require('./../../../assets/default.png')

    if (type === 'GAME_ICON_GIF') {
      this.setState({
        gifDeleteBtn: false,
        gif: defaultImg
      })
      document.getElementById('uploadGif').value = ''
    } else if (type === 'GAME_ICON_BANNER') {
      this.setState({
        bannerDeleteBtn: false,
        banner: defaultImg
      })
      document.getElementById('uploadBanner').value = ''
    }
    this.setState({
      gameInfo: Object.assign(gameInfo, { images: imagesL }, () => {
        const images = this.state.gameInfo.images
        if (!!images && images.length <= 0) {
          this.setState({
            themePreview: false
          })
        }
      })
    })
  }

  handleInputChange = name => event => {
    this.editGameInfo({ name, value: event.target.value })
  }

  handleDateChange = name => date => {
    this.editGameInfo({ name, value: new Date(date).getTime() })
  }

  getOptions = keyValue => async inputValue => {
    if (typeof inputValue === 'object') {
      inputValue = ''
    }

    let res = await commonApi.searchFilters({
      type: keyValue,
      name: inputValue
    })
    const result = res.data && res.data.result
    let optionList = []
    if (result) {
      if (Array.isArray(result)) {
        result.forEach(value => {
          let item = {}
          if (typeof value === 'object') {
            item.value = value.sid || value.pid
            item.label = value.name
            item._origin = value // 保存一份原始副本，在编辑提交的时候使用
          } else if (typeof value === 'string') {
            item.value = value
            item.label = value
          }
          optionList.push(item)
        })
      } else {
        result.list.forEach(value => {
          let obj = {}
          obj.value = value.id
          obj.label = value.name
          optionList.push(obj)
        })
      }
    }
    return optionList
  }
  setAddInfoHandle = key => event => {
    this.setState({
      [key]: event.target.value
    })
  }

  updateGameInfoSelect = attr => value => {
    this.editGameInfo({ name: attr, value })
  }

  editGameInfo = editItem => {
    const { gameInfo } = this.state
    this.setState({
      gameInfo: Object.assign({}, gameInfo, {
        [editItem.name]: editItem.value
      })
    })
  }

  // origin 地址
  uploadOriginSuccess = () => data => {
    let originalImages = this.state.gameInfo.originalImages || []
    if (originalImages) {
      let image = new Image()
      image.src = data.result.url
      image.onload = () => {
        let imageWidth = image.naturalWidth
        let imageHeight = image.naturalHeight
        let tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_PIC'
        }
        const imagesL = originalImages.filter(item => item.type !== 'GAME_ICON_PIC')
        this.setState({
          gameInfo: Object.assign(this.state.gameInfo, { originalImages: [...imagesL, tempImage] })
        })
      }
    } else {
      let image = new Image()
      image.src = data.result.url
      image.onload = () => {
        let imageWidth = image.naturalWidth
        let imageHeight = image.naturalHeight
        let tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_PIC'
        }
        this.setState({
          gameInfo: Object.assign(this.state.gameInfo, { originalImages: [tempImage] })
        })
      }
    }
  }
  uploadSuccess = type => data => {
    let image = new Image()
    let images = this.state.gameInfo.images
    let originalImages = this.state.gameInfo.originalImages || []
    image.src = data.result.url
    image.onload = () => {
      let imageWidth = image.naturalWidth
      let imageHeight = image.naturalHeight
      if (imageWidth > 320) {
        imageWidth = 320
        imageHeight = ~~(320 / (image.naturalWidth / image.naturalHeight))
      }
      let tempImage = {
        url: data.result.url,
        width: imageWidth,
        height: imageHeight,
        type
      }
      const imageSL = images.filter(item => item.type !== type)
      imageSL.push(tempImage)
      this.setState(
        {
          gameInfo: Object.assign(this.state.gameInfo, { images: imageSL })
        },
        () => {
          this.updateImg(this.state.gameInfo.images)
        }
      )

      // 处理 originImages

      if (originalImages) {
        const imagesL = originalImages.filter(item => item.type !== type)
        this.setState({
          gameInfo: Object.assign(this.state.gameInfo, { originalImages: [...imagesL, tempImage] })
        })
      } else {
        this.setState({
          gameInfo: Object.assign(this.state.gameInfo, { originalImages: [tempImage] })
        })
      }

      // if (type === 'GAME_ICON_GIF') {
      //   this.setState({ gifDeleteBtn: true })
      // }
      // if (type === 'GAME_ICON_BANNER') {
      //   this.setState({ bannerDeleteBtn: true })
      // }
    }
  }

  handleIconBarColorSwitch = () => {
    this.setState({
      themeSwitch: !this.state.themeSwitch
    })
  }

  handlePreviewSwitch = () => {
    if (!this.state.gameInfo.images.length) {
      Toast.warning('Please select icon first!')
      return
    }
    this.setState({
      themePreview: !this.state.themePreview
    })
  }

  handleSelectIconBarColor = e => {
    if (e.target.tagName === 'LI') {
      let themeColorValue = ''
      if (e.target.innerText === 'first_themeColor') {
        themeColorValue = '#ef5e38,#f08b63'
      } else if (e.target.innerText === 'second_themeColor') {
        themeColorValue = '#f06b2d,#f1a35d'
      } else if (e.target.innerText === 'third_themeColor') {
        themeColorValue = '#e69413,#eec243'
      } else if (e.target.innerText === 'fourth_themeColor') {
        themeColorValue = '#15be69,#2ce285'
      } else if (e.target.innerText === 'fifth_themeColor') {
        themeColorValue = '#59b223,#7ad353'
      } else if (e.target.innerText === 'sixth_themeColor') {
        themeColorValue = '#2882ec,#4eb2f8'
      } else if (e.target.innerText === 'seventh_themeColor') {
        themeColorValue = '#3243ff,#52a0ff'
      } else if (e.target.innerText === 'eighth_themeColor') {
        themeColorValue = '#9e37ff,#eb5aff'
      } else if (e.target.innerText === 'ninth_themeColor') {
        themeColorValue = '#682aff,#894be8'
      }
      let info = Object.assign(this.state.gameInfo, {
        themeColor: themeColorValue
      })
      this.setState({
        themeColorClass: e.target.innerText,
        themeSwitch: false,
        gameInfo: info
      })
    }
  }
  renderImg = () => {
    const {
      gameInfo: { images }
    } = this.state
    return !!images && !!images.length ? (
      <img src={images[images.length - 1].url} />
    ) : null
  }
  enterTitleHandle = () => {
    const { gameInfo } = this.state
    const { gameAdd } = this.props
    let subTitle = gameInfo.subTitle || []
    gameAdd.updateAddInfo('title', gameInfo.title)
    gameAdd.updateAddInfo('subTitle', subTitle)
    this.setState({
      titleSwtch: true,
      dialogTitle: 'Enter title',
      dialogChildren: <GameEditTitle />
    })
  }
  confirmHandle = () => {
    const { gameAdd } = this.props
    const { gameInfo } = this.state
    if (
      gameAdd.AddInfo.title &&
      gameAdd.AddInfo.subTitle.length === 4 &&
      gameAdd.AddInfo.subTitle[0].title !== '' &&
      gameAdd.AddInfo.subTitle[1].title !== '' &&
      gameAdd.AddInfo.subTitle[2].title !== '' &&
      gameAdd.AddInfo.subTitle[3].title !== ''
    ) {
      let gameInfo_ = Object.assign({}, gameInfo, {
        title: gameAdd.AddInfo.title,
        subTitle: gameAdd.AddInfo.subTitle
      })
      this.setState(
        {
          titleSwtch: false,
          gameInfo: gameInfo_
        },
        () => {
          gameAdd.updateAddInfo('title', '')
          gameAdd.updateAddInfo('subTitle', [])
        }
      )
    } else {
      Toast.warning('You need all the input...')
    }
  }
  render() {
    const { open, classes, theme, authInfo, gameAdd } = this.props
    const {
      gameInfo,
      tagsFirstValue,
      tagsSecondValue,
      icon,
      gif,
      banner,
      themeColorClass,
      themeSwitch,
      themePreview,
      gifDeleteBtn,
      titleSwtch,
      dialogChildren,
      dialogTitle,
      bannerDeleteBtn
    } = this.state
    if (!gameInfo) {
      return null
    }
    const selectStyles = theme
    return (
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Game Edit</DialogTitle>
        <DialogContent>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  游戏ID
                </TableCell>
                <TableCell>{gameInfo.docId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  游戏名称
                </TableCell>
                <TableCell>
                  {/* <TextField
                    id="outlined-bare"
                    className={classes.textField}
                    value={gameInfo.title}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleInputChange('title')}
                  /> */}
                  <Button
                    variant="outlined"
                    className={classes.titleBtn}
                    onClick={this.enterTitleHandle}
                  >
                    {gameInfo.title ? gameInfo.title : 'Enter the title'}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  游戏图片
                </TableCell>
                <TableCell>
                  <div className={classes.posterContainer}>
                    <img className={classes.poster} src={icon} />
                    <div className={classes.posterOperate}>
                      <img
                        className={classes.startImg}
                        src={require('./../../../assets/mandatory.png')}
                      />
                      <UploadFile
                        id="uploadFile"
                        fileTypes={['image/png', 'image/jpeg', 'image/webp']}
                        maxSize={5 * 1024 * 1000}
                        uploadApi="upload/file"
                        needUploadWebp={true}
                        uploadChange={this.uploadSuccess('GAME_ICON_PIC')}
                        uploadOriginSuccess={this.uploadOriginSuccess()}
                      />
                    </div>
                  </div>
                  <div className={classes.posterContainer}>
                    <div style={{ position: 'relative' }}>
                      {gifDeleteBtn ? (
                        <div
                          className={classes.closeBtn}
                          onClick={this.deleteUploadImage('GAME_ICON_GIF')}
                        >
                          <div>
                            <span className={classes.closeRight} />
                            <span className={classes.closeLeft} />
                          </div>
                        </div>
                      ) : null}
                      <img className={classes.poster} src={gif} />
                    </div>
                    <div className={classes.posterOperate}>
                      <UploadFile
                        id="uploadGif"
                        fileTypes={['image/gif']}
                        maxSize={5 * 1024 * 1000}
                        uploadApi="upload/file"
                        uploadChange={this.uploadSuccess('GAME_ICON_GIF')}
                        textProp="Upload Gif"
                      />
                    </div>
                  </div>

                  <div className={classes.posterContainer}>
                    {/* 加载显示图片 */}
                    <div style={{ position: 'relative' }}>
                      {bannerDeleteBtn ? (
                        <div
                          className={classes.closeBtn}
                          onClick={this.deleteUploadImage('GAME_ICON_BANNER')}
                        >
                          <div>
                            <span className={classes.closeRight} />
                            <span className={classes.closeLeft} />
                          </div>
                        </div>
                      ) : null}
                      <img
                        className={classes.poster}
                        src={banner}
                      // ref={el => (this.uploadBanner = el)}
                      />
                    </div>
                    <div className={classes.posterOperate}>
                      <UploadFile
                        id="uploadBanner"
                        fileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/webp',
                          'image/gif'
                        ]}
                        maxSize={5 * 1024 * 1000}
                        uploadApi="upload/file"
                        uploadChange={this.uploadSuccess('GAME_ICON_BANNER')}
                        textProp="Upload Banner"
                      />
                    </div>
                  </div>

                  {themePreview & (themeColorClass !== '') ? (
                    <div
                      className={classNames(
                        classes.preview,
                        classes[themeColorClass]
                      )}
                    >
                      {this.renderImg()}
                      <div>
                        <p>mint games</p>
                        <p>19930 players</p>
                      </div>
                    </div>
                  ) : null}

                  {themePreview & (themeColorClass === '') ? (
                    <div
                      className={classes.preview}
                      style={{
                        background: `linear-gradient(to right,${gameInfo.themeColor.slice(
                          0,
                          7
                        )},${gameInfo.themeColor.slice(8)})`
                      }}
                    >
                      {this.renderImg()}
                      <div>
                        <p>mint games</p>
                        <p>19930 players</p>
                      </div>
                    </div>
                  ) : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  Icon Bar Color
                </TableCell>
                <TableCell>
                  <div style={{ position: 'relative' }}>
                    <Button
                      variout="outlined"
                      className={classes.iconBarColorBtn}
                      onClick={this.handleIconBarColorSwitch}
                    >
                      {!gameInfo.themeColor ? (
                        'please select themeColor'
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <p style={{ height: 20, lineHeight: '20px' }}>
                            {gameInfo.themeColor}
                          </p>
                          <p
                            style={{
                              width: '70px',
                              height: '20px',
                              marginLeft: '5px',
                              background: `linear-gradient(to right,${gameInfo.themeColor.slice(
                                0,
                                7
                              )},${gameInfo.themeColor.slice(8)})`
                            }}
                          />
                        </div>
                      )}
                    </Button>
                    {themeSwitch ? (
                      <ul onClick={e => this.handleSelectIconBarColor(e)}>
                        <li className={classes.first_themeColor}>
                          first_themeColor
                        </li>
                        <li className={classes.second_themeColor}>
                          second_themeColor
                        </li>
                        <li className={classes.third_themeColor}>
                          third_themeColor
                        </li>
                        <li className={classes.fourth_themeColor}>
                          fourth_themeColor
                        </li>
                        <li className={classes.fifth_themeColor}>
                          fifth_themeColor
                        </li>
                        <li className={classes.sixth_themeColor}>
                          sixth_themeColor
                        </li>
                        <li className={classes.seventh_themeColor}>
                          seventh_themeColor
                        </li>
                        <li className={classes.eighth_themeColor}>
                          eighth_themeColor
                        </li>
                        <li className={classes.ninth_themeColor}>
                          ninth_themeColor
                        </li>
                      </ul>
                    ) : null}
                    {gameInfo.themeColor ? (
                      <Button
                        variant="outlined"
                        onClick={this.handlePreviewSwitch}
                      >
                        preview
                      </Button>
                    ) : null}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  游戏URL
                </TableCell>
                <TableCell>
                  <TextField
                    id="outlined-bare"
                    className={classes.textField}
                    value={gameInfo.url}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleInputChange('url')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  上线时间
                </TableCell>
                <TableCell>
                  <DatePicker
                    className={classNames(
                      classes.textFieldTime,
                      classes.onTime
                    )}
                    placeholder="请选择创建时间"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect
                    value={gameInfo.startTime}
                    onChange={this.handleDateChange('startTime')}
                    animateYearScrolling={false}
                    clearable={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  下线时间
                </TableCell>
                <TableCell>
                  <DatePicker
                    className={classNames(
                      classes.textFieldTime,
                      classes.offTime
                    )}
                    placeholder="请选择创建时间"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect
                    value={gameInfo.endTime}
                    onChange={this.handleDateChange('endTime')}
                    animateYearScrolling={false}
                    clearable={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  Provider
                </TableCell>
                <TableCell>
                  <AsyncSelect
                    cacheOptions
                    isSearchable
                    styles={selectStyles}
                    className={classes.providerSelect}
                    defaultValue={{
                      label: gameInfo.provider.name,
                      value: gameInfo.provider.pid
                    }}
                    loadOptions={this.getOptions('provider')}
                    onChange={this.updateGameInfoSelect('provider')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Publisher</TableCell>
                <TableCell>
                  <AsyncSelect
                    cacheOptions
                    isSearchable
                    styles={selectStyles}
                    className={classes.providerSelect}
                    defaultValue={{
                      label: gameInfo.source.name,
                      value: gameInfo.source.sid
                    }}
                    loadOptions={this.getOptions('source')}
                    onChange={this.updateGameInfoSelect('sourceId')}
                  />
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>横竖屏</TableCell>
                <TableCell>
                  <TextField
                    select
                    className={classes.textFieldSelect}
                    variant="outlined"
                    value={gameInfo.style || ''}
                    onChange={this.handleInputChange('style')}
                  >
                    <MenuItem value={6}>竖屏</MenuItem>
                    <MenuItem value={5}>横屏</MenuItem>
                  </TextField>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>权重</TableCell>
                <TableCell>
                  {/* <TextField
                    select
                    className={classes.textFieldSelect}
                    variant="outlined"
                    value={gameInfo.boutiques || 0}
                    onChange={this.handleInputChange('boutiques')}
                  >
                    <MenuItem value={0}>不加</MenuItem>
                    {[
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10,
                      11,
                      12,
                      13,
                      14,
                      15,
                      16,
                      17,
                      18,
                      19,
                      20,
                      21,
                      22,
                      23,
                      24,
                      25,
                      26,
                      27,
                      28,
                      29,
                      30
                    ].map(item => {
                      return (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      )
                    })}
                  </TextField> */}
                  <TextField
                    margin="none"
                    variant="outlined"
                    className={classes.gameImg}
                    value={gameInfo.boutiques || 0}
                    onChange={this.setAddInfoHandle('boutiques')}
                    type="text"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>显示区域</TableCell>
                <TableCell>
                  <TextField
                    margin="none"
                    select
                    variant="outlined"
                    value={gameInfo.areas || []}
                    className={classes.textFieldSelect}
                    onChange={this.handleInputChange('areas')}
                    SelectProps={{
                      classes: { outlined: classes.menu },
                      multiple: true
                    }}
                  >
                    {AREAS.map(({ value, label }) => {
                      return (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <em>*</em>
                  游戏标签
                </TableCell>
                {['ru', 'es'].findIndex(
                  item => item === authInfo.region.code.toLocaleLowerCase()
                ) > -1 ? (
                  <TableCell className={classes.lastCellRu}>
                    <TextField
                      margin="none"
                      select
                      variant="outlined"
                      value={tagsFirstValue || 0}
                      className={classes.textFieldSelect}
                      onChange={this.setAddInfoHandle('tagsFirstValue')}
                      SelectProps={{ classes: { outlined: classes.menu } }}
                    >
                      {gameAdd.tagList && !!gameAdd.tagList.length
                        ? gameAdd.tagList.map(item => {
                          return (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          )
                        })
                        : null}
                    </TextField>
                    <TextField
                      margin="none"
                      select
                      variant="outlined"
                      value={tagsSecondValue || 0}
                      className={classes.textFieldSelect}
                      onChange={this.setAddInfoHandle('tagsSecondValue')}
                      SelectProps={{ classes: { outlined: classes.menu } }}
                    >
                      <MenuItem value={0}>please select...</MenuItem>
                      {gameAdd.tagList && !!gameAdd.tagList.length
                        ? gameAdd.tagList.map(item => {
                          return (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          )
                        })
                        : null}
                    </TextField>
                  </TableCell>
                ) : (
                  <TableCell className={classes.lastCellIn}>
                    <AsyncSelect
                      cacheOptions
                      isMulti
                      isSearchable
                      defaultValue={gameInfo.tags.map(d => {
                        if (TAG_TYPE.findIndex(age => age === d) < 0) {
                          return { label: d, value: d }
                        }
                      })}
                      styles={selectStyles}
                      className={classes.providerSelect}
                      loadOptions={this.getOptions('tag')}
                      onChange={this.updateGameInfoSelect('tags')}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.submitEdit} color="primary">
            保存
          </Button>
          <Button onClick={this.handleClose} color="primary">
            取消
          </Button>
        </DialogActions>
        {titleSwtch ? (
          <AlertDialog
            open={titleSwtch}
            content={''}
            title={dialogTitle}
            children={dialogChildren}
            handleClose={() => {
              gameAdd.updateAddInfo('title', '')
              gameAdd.updateAddInfo('subTitle', [])
              this.setState({
                titleSwtch: false
              })
            }}
            handleConfirm={this.confirmHandle}
          />
        ) : null}
      </Dialog>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FormDialog)

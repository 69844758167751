import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'

import Layout from './layout/Index'
import Stores from './models'
import '@src/assets/css/reset.css'
import '@src/assets/css/layout.scss'
import '@src/assets/css/react-datetime.scss'

// console.log(Stores)
ReactDOM.render(
  <Provider {...Stores}>
    <Router hashType="noslash">
      <Layout />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

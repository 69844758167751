import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from '../ContentManage/ContentManage'
import Toast from '@src/components/Toast'

class TaskManageAPI {
  getTaskLists = data =>
    fetchData({
      url: 'video/audit/list',
      method: 'GET',
      data: { ...data }
    })

  auditTask = data =>
    fetchData({
      url: `video/add/audit`,
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...data
      },
      method: 'POST'
    })
  updatePublisher = option => {
    return fetchData({
      url: 'video/channel/update/name',
      method: 'POST',
      data: {
        ...option
      }
    })
  }
}

class TaskManage {
  constructor (api) {
    this.taskManageApi = api
  }

  @observable
  list = []

  @observable
  total = 0

  @observable
  pageNum = 1

  @observable
  updateState = []

  // 0待审核 1执行中
  @observable
  status = 0
  // 1 channel 2 keywords 4 supertitlelink  8 playlist  16 ins hashtag  32 ins accounts  33
  @observable
  processSize = 1

  @observable
  updateCategorys = []

  @observable
  updateCounts = []

  @action
  updateStatus = (type, value) => {
    if (type === 'processSize') {
      this.processSize = value
    } else if (type === 'status') {
      this.status = value
    }
  }

  @action
  updateBtnState = index => {
    this.updateState[index].update = false
  }
  @action
  updatePageNum = pageNum => {
    this.pageNum = pageNum
  }
  @action
  updatePublish = async (option, index) => {
    let result = await this.taskManageApi.updatePublisher(option)
    if (result.data && result.data.status) {
      this.updateState[index].update = true
      Toast.success(result.data.msg || 'update success')
    } else {
      Toast.error(result.data.msg || 'update fail')
    }
  }

  @action
  updateCategoryState = option => {
    const { index, category } = option
    if (option.update !== undefined) {
      this.updateCategorys[index].update = option.update
    }
    this.updateCategorys[index].category = category
  }
  @action
  updateCategory = async (params, index) => {
    let result = await this.taskManageApi.updatePublisher(params)
    if (result.data && result.data.status) {
      this.updateCategoryState({
        index,
        category: '',
        update: true
      })
      this.getTaskList()
    }
  }

  @action
  updateCountState = option => {
    const { index, crawlCount } = option
    if (option.update !== undefined) {
      this.updateCounts[index].update = option.update
    }
    this.updateCounts[index].crawlCount = crawlCount
  }
  @action
  updateCount = async (params, index) => {
    let result = await this.taskManageApi.updatePublisher(params)
    if (result.data && result.data.status) {
      this.updateCountState({
        index,
        crawlCount: '',
        update: true
      })
      this.getTaskList()
    }
  }

  @action
  getTaskList = async searchValue => {
    const query = {
      pageSize: 8,
      status: this.status,
      tab: this.processSize,
      publisher: '',
      region: authInfo.region.code,
      type: contentManage.contentType.toUpperCase()
    }
    if (searchValue) {
      query.publisher = searchValue.publisher
      query.entityId = searchValue.entityId
      query.pageNum = 1
      this.pageNum = 1
    } else {
      query.pageNum = this.pageNum
    }
    let result = ''
    try {
      result = await this.taskManageApi.getTaskLists(query)
      if (!result.data.status) {
        throw new Error()
      }
      this.list = result.data.result.list || []
      this.total = result.data.result.count || 0
      this.updateState = []
      if (this.status === 1 && this.processSize === 1) {
        this.list.forEach(item => {
          if (item) {
            let obj = { update: true }
            this.updateState.push(obj)
          }
        })
      }
      this.list.forEach(item => {
        if (item) {
          let obj1 = { update: true, category: '' }
          let obj2 = { update: true, crawlCount: '' }
          this.updateCategorys.push(obj1)
          this.updateCounts.push(obj2)
        }
      })

      // if (this.processSize === 33) {
      // this.list.forEach(item => {
      //   if (item) {
      //     let obj = { update: true, category: '' }
      //     this.updateCategoryState.push(obj)
      //   }
      // })
      // }
    } catch (e) {
      Toast.error('fail')
    }
  }

  @action
  auditTask = data => {
    return this.taskManageApi.auditTask(data)
  }
}

const taskManageAPI = new TaskManageAPI()
const taskManage = new TaskManage(taskManageAPI)

export default taskManage

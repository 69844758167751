import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import GameFilter from '../GameAdmin/GameFilter'
import GameBatchUp from './GameBatchUp'
import GameSingleBatchUp from './GameSingleBatchUp'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  leftPannel: {
    width: 250,
    height: '100%',
    backgroundColor: 'cyan'
  },
  content: {
    width: 'calc(100vw - 250px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignSelf: 'stretch',
    background: '#fff',
    justifyContent: 'space-between',
    borderLeft: '1px solid #c7c7c7'
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    paddingLeft: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2
  }
})

class GameAdd extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      uploadStyle: 'Batch'
    }
  }
  handleChangeSelect = event => {
    if (!event) {
      this.setState({
        uploadStyle: 'Batch'
      })
    } else {
      this.setState({
        uploadStyle: event.target.value
      })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.leftPannel}>
          <GameFilter />
        </div>
        <div className={classes.content}>
          <Typography className={classes.title}>Add Games</Typography>
          {this.state.uploadStyle === 'Batch' ? (
            <GameBatchUp
              handleChangeSelect={this.handleChangeSelect}
              uploadStyle={this.state.uploadStyle}
            />
          ) : null}
          {this.state.uploadStyle === 'Single' ? (
            <GameSingleBatchUp
              handleChangeSelect={this.handleChangeSelect}
              uploadStyle={this.state.uploadStyle}
            />
          ) : null}
        </div>
      </div>
    )
  }
}
GameAdd.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(GameAdd)

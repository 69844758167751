import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { TextField, Typography } from '@material-ui/core'

const styles = theme => ({
  item: {
    width: '100%',
    marginBottom: theme.spacing.unit * 1.5,
    display: 'flex',
    alignItems: 'center'
  },
  itemTitle: {
    width: '20%',
    textAlign: 'right',
    marginRight: '30px'
  },
  mandatory: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'inline-block'
  },
  inputContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  gameImg: {
    width: '100%',
    '@global': {
      'div:first-child': {
        '@global': {
          input: {
            padding: '11px 14px'
          }
        }
      }
    }
  }
})

@inject('gameAdd')
@observer
class GameEditTitle extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      titleMap: {}
    }
  }
  componentDidMount () {
    const { gameAdd } = this.props
    const titleMap = {}
    gameAdd.AddInfo.subTitle.forEach(item => {
      titleMap[item.language] = item.title
    })
    this.setState({
      titleMap
    })
  }
  handleChangeTitle = lang => e => {
    const { gameAdd } = this.props
    let subTitle = []
    const titleMap = this.state.titleMap
    titleMap[lang] = e.target.value
    this.setState({
      titleMap
    })
    for (let key in this.state.titleMap) {
      let obj = {
        language: key,
        title: this.state.titleMap[key]
      }
      subTitle.push(obj)
    }
    gameAdd.updateAddInfo('subTitle', subTitle)
  }
  render () {
    const { classes, gameAdd } = this.props
    const { titleMap } = this.state
    return (
      <div>
        <div className={classes.item}>
          <span className={classes.itemTitle}>
            <Typography className={classes.mandatory}>English</Typography>
          </span>
          <span className={classes.inputContent}>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.gameImg}
              value={gameAdd.AddInfo.title}
              onChange={e => {
                gameAdd.updateAddInfo('title', e.target.value)
              }}
              type="text"
            />
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.itemTitle}>
            <Typography className={classes.mandatory}>Russia</Typography>
          </span>
          <span className={classes.inputContent}>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.gameImg}
              value={titleMap.ru || ''}
              onChange={this.handleChangeTitle('ru')}
              type="text"
            />
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.itemTitle}>
            <Typography className={classes.mandatory}>Espain</Typography>
          </span>
          <span className={classes.inputContent}>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.gameImg}
              value={titleMap.es || ''}
              onChange={this.handleChangeTitle('es')}
              type="text"
            />
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.itemTitle}>
            <Typography className={classes.mandatory}>French</Typography>
          </span>
          <span className={classes.inputContent}>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.gameImg}
              value={titleMap.fr || ''}
              onChange={this.handleChangeTitle('fr')}
              type="text"
            />
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.itemTitle}>
            <Typography className={classes.mandatory}>Indonesian</Typography>
          </span>
          <span className={classes.inputContent}>
            <TextField
              margin="none"
              variant="outlined"
              className={classes.gameImg}
              value={titleMap.in || ''}
              onChange={this.handleChangeTitle('in')}
              type="text"
            />
          </span>
        </div>
      </div>
    )
  }
}
GameEditTitle.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(GameEditTitle)

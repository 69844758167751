import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import { excelToJson, downloadExl } from '@src/utils/excel.js'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toast from '../../components/Toast'

const styles = theme => ({
  fileOffline: {
    position: 'relative',
    // top: theme.spacing.unit * 8,
    left: theme.spacing.unit,
    display: 'inline - block',
    background: '#D0EEFF',
    border: '1px solid #99D3F5',
    borderRadius: '4px',
    padding: '4px 12px',
    overflow: 'hidden',
    color: '#1E88C7',
    textDecoration: 'none',
    textIndent: '0',
    lineHeight: '20px',
    '&:hover': {
      background: '#AADFFD',
      borderColor: '#78C3F3',
      color: '#004974',
      textDecoration: 'none'
    }
  },
  fileInput: {
    position: 'absolute',
    fontSize: '100px',
    right: 0,
    top: 0,
    opacity: 0
  },
  container: {
    marginTop: '80px'
  },
  img: {
    width: '160px',
    height: '90px'
  },
  checkbox: {
    width: '30px',
    height: '30px'
  },
  export: {
    marginRight: '20px',
    marginLeft: '20px'
  }
})

@inject('batchOffline')
@observer
class BatchOffline extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: []
    }
  }

  handleSelect = (key, index) => e => {
    const value = e.target.checked
    const rows = this.state.rows
    rows[index][key] = value
    this.setState({
      rows: rows
    })
  }

  handleChange = e => {
    const print = data => {
      this.setState({
        rows: data
      })
    }
    excelToJson(e, print)
  }

  exportHandle = () => {
    const data = JSON.parse(JSON.stringify(this.state.rows))
    let s_class = []
    let a_class = []
    let offline = []
    let listHead = ['NO.', 'S class', 'A class', 'Offline']
    data.forEach((row, index) => {
      if (row['S class']) {
        row['S class'] = row.ID
        s_class.push(row.ID)
      }
      if (row['A class']) {
        row['A class'] = row.ID
        a_class.push(row.ID)
      }
      if (row['Offline']) {
        row['Offline'] = row.ID
        offline.push(row.ID)
      }
      if (
        (row['S class'] && row['A class']) ||
        (row['A class'] && row['Offline']) ||
        (row['Offline'] && row['S class'])
      ) {
        Toast.error(`${row.ID} select error`)
      }
    })
    let res = []
    let maxL = offline.length
    let compareL = [s_class.length, a_class.length]
    compareL.forEach(item => {
      if (item > maxL) {
        maxL = item
      }
    })
    for (let index = 0; index < maxL; index++) {
      let obj = {
        'NO.': index + 1,
        'S class': s_class[index],
        'A class': a_class[index],
        Offline: offline[index]
      }
      res.push(obj)
    }
    downloadExl(res, listHead)
  }

  render () {
    const { classes } = this.props
    const { rows } = this.state
    return (
      <div>
        <div className={classes.container}>
          <a href="javascript:;" className={classes.fileOffline}>
            select file
            <input
              type="file"
              className={classes.fileInput}
              id="File"
              onChange={this.handleChange}
              name="file"
            />
          </a>

          <button onClick={this.exportHandle} className={classes.export}>
            export results
          </button>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>NO.</TableCell>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">Title</TableCell>
                <TableCell align="right">Thumbnail</TableCell>
                <TableCell align="right">S class</TableCell>
                <TableCell align="right">A class</TableCell>
                <TableCell align="right">Offline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.ID}>
                  <TableCell align="right">{index + 1}</TableCell>
                  <TableCell align="right">{row.ID}</TableCell>
                  <TableCell align="right">{row.Title}</TableCell>
                  <TableCell align="right">
                    <img className={classes.img} src={row.Thumbnail} />
                  </TableCell>
                  <TableCell align="right">
                    <input
                      type="checkbox"
                      checked={row['S class']}
                      onChange={this.handleSelect('S class', index)}
                      className={classes.checkbox}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <input
                      type="checkbox"
                      checked={row['A class']}
                      onChange={this.handleSelect('A class', index)}
                      className={classes.checkbox}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <input
                      type="checkbox"
                      checked={row['Offline']}
                      onChange={this.handleSelect('Offline', index)}
                      className={classes.checkbox}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
}

BatchOffline.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(BatchOffline)

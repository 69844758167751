import { observable, action, computed } from 'mobx'
import { fetchData } from '../utils/tools'
// import Toast from '@src/components/Toast'
import { intl } from '@src/IntlGlobalProvider'
import { ProductConf, ManageContentList } from '@src/utils/constant'
import { removeToken, removeCookie, getCookie } from '@src/utils/cookie'

const logoutRequest = () =>
  fetchData({
    url: 'logout',
    method: 'GET'
  })

const getRegions = () =>
  fetchData({
    url: 'region/list',
    method: 'GET'
  })

const getUserInfo = () =>
  fetchData({
    url: 'user/current/user',
    method: 'GET'
  })

const LoginAPI = {
  login: data => {
    return fetchData({
      url: `login`,
      data: data,
      method: 'POST'
    })
  }
}

class AuthInfo {
  @observable
  user_name = getCookie('name') || ''
  @observable
  isLogin = false
  @observable
  user = JSON.parse(window.localStorage.getItem('user')) || null
  @observable
  product = JSON.parse(window.localStorage.getItem('product')) || null // 当前用户选择的产品
  @observable
  region = JSON.parse(window.localStorage.getItem('region')) || {} // 当前用户操作的地区
  @observable
  languages = JSON.parse(window.localStorage.getItem('languages')) || []
  @observable
  showMenus = true // 控制特定产品下的业务模块
  @observable
  regionList = null // 当前用户管理的地区列表

  @computed
  get menus () {
    const user = this.user
    if (!user) {
      return null
    }
    let manageContent = (user && user.manageContent) || []
    let menus = {
      className: 'slj-menu-container',
      submenus: []
    }

    if (user.role === 2) {
      manageContent = ManageContentList
    }

    menus.submenus = manageContent.map(content => {
      if (content === 'Game') {
        return {
          name: 'Game',
          className: 'slj-sub-menu-container slj-submenu-first',
          submenus: [
            {
              name: intl.formatMessage({ id: 'View List' }),
              className: 'slj-menu-item',
              path: '/browserGame/contentManage'
            },
            {
              name: intl.formatMessage({ id: 'Add Game' }),
              className: 'slj-menu-item',
              path: '/browserGame/gameAdd'
            },
            {
              name: intl.formatMessage({ id: 'Review Game' }),
              className: 'slj-menu-item',
              path: '/browserGame/gameReview'
            },
            {
              name: intl.formatMessage({ id: 'Tag Manage' }),
              className: 'slj-menu-item',
              path: '/tagManage'
            }
          ]
        }
      }
      if (content === 'Theme') {
        return {
          name: 'Theme',
          className: 'slj-sub-menu-container slj-submenu-first',
          submenus: [
            {
              name: intl.formatMessage({ id: 'Theme List' }),
              className: 'slj-menu-item',
              path: '/browserTheme/contentManage'
            },
            {
              name: intl.formatMessage({ id: 'Add Theme' }),
              className: 'slj-menu-item',
              path: '/browserTheme/addTheme'
            }
          ]
        }
      }
      return {
        name: content,
        className: 'slj-sub-menu-container slj-submenu-first',
        submenus: [
          {
            className: 'slj-sub-menu-container slj-submenu-second',
            name: intl.formatMessage({ id: 'contentManage' }),
            submenus: [
              {
                name: intl.formatMessage({ id: 'contentList' }),
                className: 'slj-menu-item',
                path: '/newsFeed/contentManage'
              },
              {
                name: intl.formatMessage({ id: 'addContent' }),
                className: 'slj-menu-item',
                path: '/addContent',
                hide: content.toUpperCase() !== 'VIDEO'
              },
              {
                name: intl.formatMessage({ id: 'webCrawler' }),
                className: 'slj-menu-item',
                path: '/addWebCrawler',
                hide: content.toUpperCase() !== 'NEWS'
              },
              {
                name: intl.formatMessage({ id: 'viewTask' }),
                className: 'slj-menu-item',
                path: '/taskManage',
                hide: content.toUpperCase() !== 'VIDEO'
              },
              {
                hide: parseInt(user.role, 10) === 1,
                name: intl.formatMessage({ id: 'batchOffline' }),
                className: 'slj-menu-item',
                path: '/publisherManage'
              },
              {
                name: intl.formatMessage({ id: 'uploadCSV' }),
                className: 'slj-menu-item',
                path: '/uploadNews',
                hide:
                  content.toUpperCase() !== 'NEWS' || this.region.code !== 'RU'
              }
            ]
          },
          {
            className: 'slj-sub-menu-container slj-submenu-second',
            name: intl.formatMessage({ id: 'basicManage' }),
            hide: parseInt(user.role, 10) === 1,
            submenus: [
              {
                name: intl.formatMessage({ id: 'categoryManage' }),
                className: 'slj-menu-item',
                path: '/categoryManage'
              },
              {
                name: intl.formatMessage({ id: 'maskWordManage' }),
                className: 'slj-menu-item',
                path: '/maskWordManage'
              }
            ]
          }
        ]
      }
    })

    if (this.product === 'Panipuri') {
      menus.submenus = menus.submenus.reduce((acc, item) => {
        if (item.name === 'Video') {
          acc.push(item)
        }
        return acc
      }, [])
    }

    return menus
  }
  // 选择产品之后，业务操作在对应子路由下
  @computed
  get subPath () {
    let result = ''
    ProductConf.forEach(d => {
      if (d.category === this.product) {
        result = d.subPath
      }
    })
    return result
  }

  @computed
  get isOnline () {
    const host = location.host
    return !/^sandbox/.test(host) && /cms.pt.intl.miui.com/.test(host)
  }

  @action
  resetLoginInfo = () => {
    name = ''
  }
  @action
  loginFn = async obj => {
    const result = await LoginAPI.login(obj)
    const { data, head } = result
    // 登录成功
    if (head.code === 200) {
      this.updateIsLogin(true)
      this.use_name = getCookie('name')
      return true
    } else {
      return data.msg != '' ? data.msg : '登录失败! 请检查用户名和密码是否正确.'
    }
  }
  @action
  loginOutFn = () => {
    this.updateIsLogin(false)
    this.resetLoginInfo()
    removeToken()
    removeCookie('name')
  }
  @action
  updateIsLogin = value => {
    this.isLogin = value
  }
  /*
   user role param three value
   @value 1 is normal(normal operate)
   @value 2 is admin(manage user && operate)
   @value 3 is editor(whole operate with content)
   */
  @action
  getUserInfo = async () => {
    // const result = await getUserInfo()
    let result = {}
    const name = getCookie('name')
    // console.log(getCookie('name'))
    if (name) {
      result = {
        head: {
          code: 200,
          time: 1667966125532,
          msg: '[^_^]',
          server: 'content-cms',
          version: '1.0-SNAPSHOT'
        },
        data: {
          status: true,
          result: {
            id: 56,
            name: name,
            role: name === 'admin' ? 2 : 2,
            effectTime: 2022,
            failTime: 2028,
            userId: 'b3b5857d3ca5799d',
            userType: 1,
            email: '',
            manageRegion: [],
            manageContent: [],
            manageProduct: []
          },
          msg: null
        },
        permission: {
          userName: name,
          role: name === 'admin' ? 2 : 2
        }
      }
    }

    if (result.data && result.data.status) {
      this.user = Object.assign(
        { manageContent: [], manageProduct: [], manageRegion: [] },
        result.data.result || {}
      )
      window.localStorage.setItem('user', JSON.stringify(this.user))
    } else {
      // Toast.error(intl.formatMessage({ id: 'getUserDetailFail' }))
    }
  }

  @action
  changeProduct = product => {
    this.product = product
    window.localStorage.setItem('product', JSON.stringify(this.product))
  }

  @action
  changeRegion = region => {
    if (!region) {
      return
    }

    if (this.region.code !== region.code) {
      this.region = region
      window.localStorage.setItem('region', JSON.stringify(this.region))
      this.getCountryLanguages()
    }
  }

  @action
  logout = () => {
    return logoutRequest()
  }

  @action
  fetchRegions = async () => {
    const result = await getRegions()
    if (result.data && result.data.status && result.data.result) {
      this.regionList = result.data.result
    }
  }

  @action
  getCountryLanguages = async () => {
    const result = await fetchData({
      url: `region/language/list?region=${this.region.code}`,
      method: 'GET'
    })

    if (result.data && result.data.status) {
      if (result.data.result && result.data.result) {
        result.data.result = result.data.result.map(value => {
          return {
            value: value,
            label: value
          }
        })
        this.languages = result.data.result
        window.localStorage.setItem(
          'languages',
          JSON.stringify(result.data.result)
        )
      }
    }
  }

  @action
  showProductMenu = show => {
    this.showMenus = show
  }
}

export default new AuthInfo()

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import { Input } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import GameDetail from './GameDetail'
import GameEdit from './GameEdit'
import TablePagination from '@src/components/TablePaginationActions'
import * as constant from '@src/utils/constant'
import Toast from '@src/components/Toast'
import moment from 'moment'
import { TextField } from '@material-ui/core'

const styles = theme => ({
  root: {
    height: 'calc(100vh - 124px)',
    backgroundColor: '#fff',
    overflow: 'auto',
    borderTop: '1px solid #c7c7c7',
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  customContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  table: {
    width: 'calc(100vw - 280px)',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    tableLayout: 'fixed'
  },
  headTableRow: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing.unit * 5,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: '#EEEEEF'
  },
  head: {
    color: '#000',
    fontSize: 14,
    borderBottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    fontWeight: 700
  },
  image: {
    width: theme.spacing.unit * 8,
    height: theme.spacing.unit * 8,
    borderRadius: 10,
    margin: 5,
    border: '1px solid rgba(0, 0, 0, .2)',
    cursor: 'pointer'
  },
  thbodyRow: {
    display: 'flex',
    minHeight: theme.spacing.unit * 9,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '@global': {
      td: {
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        overflow: 'hidden',
        paddingRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        maxHeight: theme.spacing.unit * 9,
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderBottom: 0
      },
      'td:last-child': {
        paddingRight: theme.spacing.unit
      }
    }
  },
  operate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  operateBtn: {
    width: 30,
    height: 30,
    lineHeight: '35px',
    fontSize: 14,
    color: '#5069f5',
    padding: 0,
    cursor: 'pointer'
  },
  noContent: {
    width: '100%',
    textAlign: 'center',
    fontSize: 24,
    margin: '10px 0'
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: -10
  },
  picView: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
    height: 30
  },
  weightInput: {
    width: '50%',
    '@global': {
      'div:first-child': {
        '@global': {
          input: {
            padding: '11px 14px'
          }
        }
      }
    }
  }
})

@inject('gameFilter', 'gameManage')
@observer
class GameList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowDatail: false,
      gameDatail: null,
      isShowEdit: false,
      EditDatail: null,
      picView: false,
      picSelectedURL: null,
      pageSize: 10
    }
  }
  componentDidMount() {
    this.getList()
  }
  getList = () => {
    const { gameManage } = this.props
    gameManage.getGameList()
  }
  changeWeight = (gameId, gameName, newWeight) => {
    const { gameManage, gameFilter } = this.props
    const area = gameFilter.filters.location
    gameManage
      .changeWeight(gameId, area, newWeight)
      .then(res => {
        if (res.data && res.data.result) {
          Toast.success(`成功将游戏 ${gameName} 权重修改为 ${newWeight}`)
          gameManage.getGameList()
        }
      })
      .catch(err => {
        Toast.error('游戏 ${gameName} 修改权重失败')
      })
  }
  changeListBoutiques = gameId => event => {
    const { gameManage } = this.props
    let value = event.target.value
    gameManage.updateListBoutiques(gameId, value)
  }
  changehandle = (type, docId, state, region) => {
    const { gameManage } = this.props
    if (type === 'online' && parseInt(state) === 1) {
      Toast.warning('已经上线，不能操作')
      return
    }
    if (type === 'offline' && parseInt(state) === 0) {
      Toast.warning('已经下线，不能操作')
      return
    }
    gameManage.whetherLine(type, docId, region, () => {
      // 上下线成功后再获取一下数据列表
      gameManage.getGameList()
    })
  }
  deleteChangehandle = async (docId, addTime, region) => {
    const { gameManage } = this.props
    let res = await gameManage.deleteOnline(docId, addTime, region)
    if (res.data) {
      if (res.data.status) {
        Toast.success('操作成功')
        // 上下线成功后再获取一下数据列表
        gameManage.getGameList()
      } else {
        Toast.error('操作失败')
      }
    } else {
      Toast.error('操作失败')
    }
  }
  checkDetailHandle = async (docId, audit, region) => {
    const { gameManage } = this.props
    let result = await gameManage.getGameDetail(docId, audit, region)
    if (result) {
      if (result.data.status && result.data.result) {
        this.setState({
          isShowDatail: true,
          gameDatail: result.data.result
        })
      } else {
        Toast.error('获取信息失败...')
      }
    } else {
      Toast.error('获取信息失败...')
    }
  }
  // 触发分页功能
  handleChangePage = (event, page) => {
    const { gameManage } = this.props
    if (page + 1 === gameManage.pageNum) {
      return
    }
    gameManage.updatePageNum('pageNum', page + 1)
    gameManage.getGameList()
  }

  handleChangePageSize = pageSize => {
    const { gameManage } = this.props
    gameManage.pageSize = pageSize
    gameManage.getGameList()
  }

  handlePageSizeInput = event => {
    const value = event.target.value
    this.setState({
      pageSize: value
    })
  }

  handlePageSizeInputEnter = event => {
    if (event.keyCode === 13) {
      let pageSize = Number(this.state.pageSize)
      const { count } = this.props
      if (pageSize > count) {
        pageSize = count
      } else if (pageSize < 0) {
        pageSize = 0
      }
      this.setState({
        pageSize
      })
      this.handleChangePageSize(pageSize)
    }
  }

  // 编辑
  changeEditCandle = async (docId, audit, region) => {
    const { gameManage } = this.props
    let result = await gameManage.getGameDetail(docId, audit, region)
    if (result) {
      if (result.data.status && result.data.result) {
        this.setState({
          isShowEdit: true,
          EditDatail: result.data.result
        })
      } else {
        Toast.error('获取信息失败...')
      }
    } else {
      Toast.error('获取信息失败...')
    }
  }

  handleZoomIn = e => {
    this.setState({
      picView: true,
      picSelectedURL: e.target.src
    })
  }

  handleZoomOut = e => {
    if (e.target.nodeName === 'DIV') {
      this.setState({
        picView: false
      })
    }
  }
  findImage = images => {
    let url = ''
    for (let i = 0; i < images.length; i++) {
      if (images[i].type === 'GAME_ICON_PIC') {
        url = images[i].url
        break
      } else if (images[i].type === 'GAME_ICON_GIF' && images[i].url) {
        url = images[i].url
      }
    }
    return url
  }

  render() {
    const { classes, gameFilter, gameManage } = this.props
    const {
      isShowDatail,
      gameDatail,
      EditDatail,
      isShowEdit,
      picView,
      picSelectedURL
    } = this.state
    return (
      <div className={classes.root}>
        {gameManage.gameList && gameManage.gameList.length ? (
          <div className={classes.tableContent}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headTableRow}>
                  {constant.gameTableHead.map((item, index) => {
                    if (index === 8 || index === 4) {
                      return (
                        <TableCell
                          className={classes.head}
                          key={index}
                          style={{ width: '15%' }}
                        >
                          {item}
                        </TableCell>
                      )
                    }
                    if (index === 1) {
                      return (
                        <TableCell
                          className={classes.head}
                          key={index}
                          style={{ width: '12%' }}
                        >
                          {item}
                        </TableCell>
                      )
                    }
                    if (index === 6) {
                      return (
                        <TableCell
                          className={classes.head}
                          key={index}
                          style={{ width: '20%' }}
                        >
                          {item}
                        </TableCell>
                      )
                    }
                    if (index === 0 || index === 2) {
                      return (
                        <TableCell
                          className={classes.head}
                          key={index}
                          style={{ width: '9%' }}
                        >
                          {item}
                        </TableCell>
                      )
                    }
                    return (
                      <TableCell
                        className={classes.head}
                        key={index}
                        style={{ width: '10%' }}
                      >
                        {item}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {gameManage.gameList.map((row, index) => {
                  // let line = ''
                  let tags = ''
                  // if (parseInt(row.state) === 1) {
                  //   line = 'online'
                  // } else {
                  //   line = 'offline'
                  // }
                  if (row.tags.length !== 0) {
                    if (row.tags.length === 1) {
                      tags = row.tags[0]
                    }
                    if (row.tags.length === 2) {
                      tags = `${row.tags[0]},${row.tags[1]}`
                    }
                    if (row.tags.length === 3) {
                      tags = `${row.tags[0]},${row.tags[1]},${row.tags[2]}`
                    }
                  }
                  if (row.tags.length === 0) {
                    tags = '-'
                  }
                  return (
                    <TableRow key={index} className={classes.thbodyRow}>
                      <TableCell style={{ width: '9%' }}>{row.docId}</TableCell>
                      <TableCell style={{ width: '12%' }}>
                        {row.title}
                      </TableCell>
                      <TableCell style={{ width: '9%' }}>
                        <img
                          src={this.findImage(row.images)}
                          className={classes.image}
                          onClick={this.handleZoomIn}
                        />
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>{tags}</TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {moment(row.addTime).format('YYYY-MM-DD HH:mm')}
                      </TableCell>
                      {/* <TableCell style={{ width: '10%' }}>{line}</TableCell> */}
                      <TableCell style={{ width: '10%' }}>
                        {row.provider.name}
                      </TableCell>
                      {/* <TableCell style={{ width: '10%' }}>
                        {row.clickRate}
                      </TableCell> */}
                      <TableCell style={{ width: '20%' }}>
                        <TextField
                          margin="none"
                          variant="outlined"
                          className={classes.weightInput}
                          value={row.boutiques}
                          // InputProps={{ inputProps: { min: 0 } }}
                          onChange={this.changeListBoutiques(row.docId)}
                          type="number"
                        />
                        <Tooltip
                          title="点击确认修改权重"
                          onClick={() => {
                            this.changeWeight(
                              row.docId,
                              row.title,
                              row.boutiques
                            )
                          }}
                        >
                          <img
                            className={classes.operateBtn}
                            src={require('../../../assets/weightEdit.png')}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{ width: '15%' }}
                        className={classes.operate}
                      >
                        <Tooltip
                          title="online"
                          onClick={() =>
                            this.changehandle(
                              'online',
                              row.docId,
                              row.state,
                              row.region
                            )
                          }
                        >
                          {parseInt(row.state) === 1 ? (
                            <img
                              className={classes.operateBtn}
                              src={require('../../../assets/noOnline.png')}
                            />
                          ) : (
                            <img
                              className={classes.operateBtn}
                              src={require('../../../assets/online.png')}
                            />
                          )}
                        </Tooltip>
                        <Tooltip
                          title="edit"
                          onClick={() => {
                            this.changeEditCandle(
                              row.docId,
                              row.audit,
                              row.region
                            )
                          }}
                        >
                          <img
                            className={classes.operateBtn}
                            src={require('../../../assets/edit.png')}
                          />
                        </Tooltip>
                        <Tooltip
                          title="offline"
                          onClick={() =>
                            this.changehandle(
                              'offline',
                              row.docId,
                              row.state,
                              row.region
                            )
                          }
                        >
                          {parseInt(row.state) === 0 ? (
                            <img
                              className={classes.operateBtn}
                              src={require('../../../assets/noOffline.png')}
                            />
                          ) : (
                            <img
                              className={classes.operateBtn}
                              src={require('../../../assets/offline.png')}
                            />
                          )}
                        </Tooltip>
                        <Tooltip
                          title="delete"
                          onClick={() =>
                            this.deleteChangehandle(
                              row.docId,
                              row.addTime,
                              row.region
                            )
                          }
                        >
                          <img
                            className={classes.operateBtn}
                            src={require('../../../assets/delete.png')}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className={classes.noContent}>No Content Found</p>
        )}
        {isShowDatail && gameDatail ? (
          <GameDetail
            open={isShowDatail}
            handleClose={() => {
              this.setState({ isShowDatail: false })
            }}
            gameDatail={gameDatail}
          />
        ) : null}
        {EditDatail && isShowEdit ? (
          <GameEdit
            open={isShowEdit}
            updateList={this.getList}
            handleClose={() => {
              this.setState({ isShowEdit: false })
            }}
            EditDatail={EditDatail}
          />
        ) : null}
        {Number(gameManage.sum) > 0 ? (
          <div className={classes.tablePagination}>
            <TablePagination
              count={Number(gameManage.sum)}
              rowsPerPage={gameManage.pageSize}
              page={Number(gameManage.pageNum - 1)}
              onChangePage={(event, page) => {
                this.handleChangePage(event, page)
              }}
            />
            <div className={classes.customContainer}>
              <span>perPageSize：</span>
              <Input
                id="customPage"
                value={this.state.pageSize}
                onChange={this.handlePageSizeInput}
                type="number"
                onKeyUp={this.handlePageSizeInputEnter}
                classes={{
                  inputType: classes.pageNumberInput
                }}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <div
          className={classes.picView}
          style={picView ? { visibility: 'visible' } : { visibility: 'hidden' }}
          onClick={this.handleZoomOut}
        >
          <img src={picSelectedURL} alt="picSelected" />
        </div>
      </div>
    )
  }
}

GameList.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles, { withTheme: true })(GameList)

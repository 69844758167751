import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'
import moment from 'moment'
import Toast from '@src/components/Toast/index'
import { copyToClipboard } from '@src/utils/tools'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center'
  },
  iconContainer: {
    width: '100%',
    minHeight: 180,
    position: 'relative'
  },
  iconImg: {
    width: 200,
    height: 180,
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%'
  },
  DetailInfo: {
    width: '100%',
    marginTop: theme.spacing.unit * 2
  },
  item: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing.unit
  },
  left: {
    width: '37%',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 'bold'
  },
  right: {
    width: '63%',
    textAlign: 'left',
    paddingLeft: theme.spacing.unit,
    display: 'flex',
    flexWrap: 'wrap',
    wordBreak: 'break-all'
  },
  operate: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    borderTop: '1px solid #ccc',
    marginBottom: 0,
    paddingBottom: 8,
    paddingTop: 8
  },
  copyBtn: {
    padding: 0
  }
})

@inject('gameManage')
@observer
class GameDetail extends React.Component {
  // constructor (props) {
  //   super(props)
  // }
  copyLink = value => {
    copyToClipboard(value)
    Toast.success('copy successfully')
  }
  render () {
    const { open, handleClose, classes, gameDatail } = this.props
    let tagValue = ''
    let line = ''
    if (gameDatail.tags.length !== 0) {
      if (gameDatail.tags.length === 1) {
        tagValue = gameDatail.tags[0]
      }
      if (gameDatail.tags.length > 1) {
        tagValue = gameDatail.tags.join(',')
      }
    }
    if (gameDatail.tags.length === 0) {
      tagValue = '-'
    }
    if (parseInt(gameDatail.state) === 1) {
      line = 'online'
    } else {
      line = 'offline'
    }
    return (
      <Dialog open={open} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">Game Detail page</DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.iconContainer}>
            <img
              src={require('../../../assets/browser.png')}
              className={classes.iconImg}
              alt=""
            />
            {/* <img src={gameDatail.images[0].url} alt=""/> */}
          </div>
          <div className={classes.DetailInfo}>
            <div className={classes.item}>
              <span className={classes.left}>Game Name:</span>
              <span className={classes.right}>{gameDatail.title}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>provider:</span>
              <span className={classes.right}>{gameDatail.provider.name}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>publish time:</span>
              <span className={classes.right}>
                {moment(gameDatail.publishTime).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>offline time:</span>
              <span className={classes.right}>
                {moment(gameDatail.endTime).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>Link:</span>
              <div className={classes.right}>
                <span>{gameDatail.url}</span>
                <Button
                  onClick={() => {
                    this.copyLink(gameDatail.url)
                  }}
                  color="primary"
                  variant="outlined"
                  className={classes.copyBtn}
                >
                  copy
                </Button>
              </div>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>Tag:</span>
              <span className={classes.right}>{tagValue}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>Status:</span>
              <span className={classes.right}>{line}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>impression:</span>
              <span className={classes.right}>{gameDatail.st.views}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>Click:</span>
              <span className={classes.right}>{gameDatail.st.clicks}</span>
            </div>
            <div className={classes.item}>
              <span className={classes.left}>CTR:</span>
              <span className={classes.right}>{gameDatail.clickRate}</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.operate}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

GameDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GameDetail)

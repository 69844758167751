import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from '../ContentManage/ContentManage'
import Toast from '@src/components/Toast'

const TagManageAPI = {
  getTagList (data) {
    return fetchData({
      url: 'tag/edit/list',
      method: 'GET',
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...data
      }
    })
  },
  addTag (data) {
    console.log(authInfo.region.code)
    return fetchData({
      url: `tag/add`,
      method: 'POST',
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...data
      }
    })
  },
  deleteTag (data) {
    return fetchData({
      url: `tag/delete`,
      method: 'POST',
      data
    })
  },
  alterTag (data) {
    return fetchData({
      url: `tag/edit`,
      method: 'POST',
      data: {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase(),
        ...data
      }
    })
  }
}

class TagManage {
  @observable
  filters = {
    name: ''
  }
  @observable
  tagList = []
  @observable
  pageSize = 10
  @observable
  pageNum = 1
  @observable
  sum = 0
  @observable
  lastRegion = ''
  constructor (api) {
    this.tagApi = api
  }

  @action
  getTagList = async pageReset => {
    if (pageReset === 'pageReset') {
      this.pageNum = 1
    }
    if (this.lastRegion === '') {
      this.lastRegion = authInfo.region.code
    }
    if (this.lastRegion !== authInfo.region.code) {
      this.lastRegion = authInfo.region.code
      this.pageNum = 1
    }
    const response = await this.tagApi.getTagList({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      ...this.filters
    })

    const { data } = response
    if (data && data.status) {
      const { result = {} } = data
      this.sum = result.count || 0
      this.tagList = result.list || []
    } else {
      Toast.error(data.msg || '获取任务列表出错')
      this.tagList = []
    }
  }

  @action
  handlePageChange = newPage => {
    if (newPage !== this.pageNum) {
      this.pageNum = newPage
      this.getTagList()
    }
  }

  @action
  addTag = data => {
    return this.tagApi.addTag(data)
  }

  @action
  alterTag = data => {
    return this.tagApi.alterTag(data)
  }

  @action
  deleteTag = data => {
    return this.tagApi.deleteTag(data)
  }

  @action
  updateFilter = (attr, value) => {
    this.filters[attr] = value
  }
}

const tagManage = new TagManage(TagManageAPI)

export default tagManage

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { intl } from '@src/IntlGlobalProvider'
import UploadFile from '@src/components/UploadFile'
import { Typography } from '@material-ui/core'
import { observer, inject } from 'mobx-react'

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing.unit * 3,
    height: 'calc(100vh - 64px)'
  },
  fileName: {
    color: '#00f',
    fontSize: 18,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})

@inject('authInfo')
@observer
class UploadNews extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fileName: ''
    }
  }

  // uploadSuccess = () => {}
  getFileName = fileName => {
    this.setState({
      fileName
    })
  }

  render () {
    const { classes, authInfo } = this.props
    const { region } = authInfo
    const { fileName } = this.state
    return (
      <div className={classes.root}>
        {region.code === 'RU' && (
          <div>
            <p>{intl.formatMessage({ id: 'uploadDesc' })}:</p>
            <Typography className={classes.fileName}>{fileName}</Typography>
            <UploadFile
              id="uploadFile"
              uploadApi="mail/ru/upload/file"
              uploadChange={() => {}}
              uploadFileName={this.getFileName}
              textProp="Upload from computer"
              // fileTypes={['text
            />
          </div>
        )}
      </div>
    )
  }
}

UploadNews.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(UploadNews)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({})
class ExportExcel extends React.Component {
  render () {
    // const { classes } = this.props
    return <div />
  }
}

ExportExcel.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(ExportExcel)

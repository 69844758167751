import { observable, action, computed } from 'mobx'
import moment from 'moment'
import { fetchData, validateBetweenFilter, Collection } from '@src/utils/tools'
import videoFilter from './VideoFilter'
import authInfo from '../authInfo'
import { intl } from '@src/IntlGlobalProvider'
import Toast from '@src/components/Toast/index'
import { encodeQuery } from '@src/utils/url'
// import { downloadExl2 } from '@src/utils/excel'

const OPERATION_TARGET_TYPES = {
  all: 1,
  notAll: 0
}
class VideoAPI {
  static config = {}
  fetchVideos = (data, highContent) =>
    fetchData({
      url: `video/list?useHighCTRPool=${highContent}`,
      data: data,
      method: 'POST'
    })

  getVideoDetail = docId =>
    fetchData({
      url: `video/info?docId=` + docId,
      method: 'GET'
    })

  onlineVideo = docId =>
    fetchData({
      url: `doc/online?docId=` + docId + '&type=VIDEO',
      method: 'GET'
    })
  offlineVideo = data =>
    fetchData({
      url: `doc/offline?${encodeQuery(data)}`,
      method: 'GET'
    })
  batchLineVideo = ({ type, data, param }) => {
    return fetchData({
      url: `doc/batch/${type}?${encodeQuery(param)}`,
      data,
      method: 'POST'
    })
  }
  batchChangeCategory = (data, urlParam) =>
    fetchData({
      url: `video/batch/edit?${encodeQuery(urlParam)}`,
      data,
      method: 'POST'
    })
  addVideo = data =>
    fetchData({
      url: `video/add`,
      data: Object.assign(data, {
        region: authInfo.region.code,
        userIds: [authInfo.user.userId],
        type: 'VIDEO'
      }),
      method: 'POST'
    })
  uploadPic = data =>
    fetchData({
      url: `upload/file`,
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST'
    })
  // 在高点展内容中，一键审核带参数useHighCTRPool=true，否则不带
  editVideo = (...param) => {
    const [data, edit, type] = [...param]
    if (type && edit) {
      return fetchData({
        url: `video/edit?useHighCTRPool=true`,
        data: data,
        method: 'POST'
      })
    } else {
      return fetchData({
        url: `video/edit`,
        data: data,
        method: 'POST'
      })
    }
  }
  reviewVideos = data => {
    return fetchData({
      url: 'video/batch/video/review',
      data: data,
      method: 'POST'
    })
  }

  updateEditTags = data => {
    return fetchData({
      url: 'video/edit/tags',
      data: data,
      method: 'POST'
    })
  }
  deleteCache = () => {
    return fetchData({
      url: `video/v2/export/delete/cache`,
      method: 'POST'
    })
  }
  exportVideoProgress = ({ param = {}, data }) => {
    return fetchData({
      url: ` video/v2/export?${encodeQuery(param)}`,
      data: data,
      method: 'POST',
      rawResponse: true
    })
  }
  exportVideosToExcel = ({ param = {}, data }) => {
    this.config = { param, data }
    return fetchData({
      url: `video/v2/export/download?${encodeQuery(param)}`,
      data: data,
      method: 'POST',
      rawResponse: true,
      responseType: 'arraybuffer'
    }).then(response => {
      // const filename = (
      //   'CMS Data ' +
      //   moment(Date.now()).format('YYYY-MM-DD HH:mm') +
      //   '.csv'
      // ).replace(':', '：')
      let type = response.headers['content-type']
      if (type === 'application/json') {
        console.log(1)
        this.exportVideosToExcel(this.config)
        return
      }
      const filename = (
        'CMS Data ' +
        moment(Date.now()).format('YYYY-MM-DD HH:mm') +
        '.xlsx'
      ).replace(':', '：')

      const excelBlob = response.data
      const elink = document.createElement('a') // 创建a标签
      elink.download = filename
      elink.style.display = 'none'
      const blob = new Blob([excelBlob], { type: 'application/vnd.ms-excel' })
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)
    })
  }
}

class VideoStore {
  constructor (videoApi) {
    this.videoApi = videoApi
  }
  @observable
  /**
   * 视频列表（只会存当前一页的数据）
   * 已确认目前需求只操作当前页数据
   */
  videos = []
  @observable
  pageSize = 20
  @observable
  pageNum = 1
  @observable
  /**
   * 搜索结果总数
   */
  sum = 0

  @observable
  /**
   * 是否选中所有视频
   */
  isSelectAll = false

  @observable
  /**
   * 勾选 select all 的情况下，被取消选中的video项
   */
  // uncheckedVideosWhenSelectAll = new Collection([]) // 这样不会触发computed更新
  uncheckedVideosWhenSelectAll = []

  @observable
  completed = 0

  @computed
  get selectedCount () {
    if (this.isSelectAll) {
      return this.sum - this.uncheckedVideosWhenSelectAll.length
    }
    return this.videos.filter(item => item.checked).length
  }

  @action
  setSelectAll = isSelect => {
    this.isSelectAll = isSelect
    this.videos.forEach(item => {
      item.checked = isSelect
    })
    this.uncheckedVideosWhenSelectAll = []
  }
  // 控制高点内容
  @observable
  highContent = false
  @observable
  // 控制视频按钮组
  speedyBtnGroup = false
  @observable
  // 显示视频被选中的总数
  selectCount = 0
  // 记录原来的筛选条件
  @observable
  originFilter = {}

  @action
  resetPageNum = () => {
    this.pageNum = 1
  }

  @action
  updateStatus = (...reset) => {
    const [type, value, sign] = [...reset]
    this[type] = value
    // 如果是高点展的内容
    if (type === 'highContent' && sign === void 0) {
      // 如果高点展内容一直开着，再次请求的话，需要记住原来的filter条件
      if (value) {
        this.originFilter = Object.assign({}, videoFilter.browserFilters)
        videoFilter.emptyFilters()
        videoFilter.browserFilters.state = 1
      } else {
        videoFilter.browserFilters = Object.assign({}, this.originFilter)
      }
      this.speedyBtnGroup = false
      this.selectCount = 0
      this.uncheckedVideosWhenSelectAll = []
      this.fetchVideos(1)
    }
    // 如果需要关闭高点展内容，并且是首次进入界面，需要还原成默认的filter条件
    if (sign !== void 0 && type === 'highContent') {
      videoFilter.resetFilters()
    }
  }

  // 审核高点展内容items成功后需要调用list
  @action
  reviewList = () => {
    this.speedyBtnGroup = false
    this.selectCount = 0
    this.fetchVideos(1)
  }

  @action
  updateTags = (params, tags) => {
    this[params] = tags
  }

  @action
  paramsFn = () => {
    // 视频内容池需要区分所应用的app，参数传递里需要加pkg字段，使用Int值
    // PANIPURY(1, "com.funnypuri.client"),
    // BROWSER(2, "com.android.browser")
    const pkg = authInfo.product === 'News Feed' ? 2 : 1

    let searchOptions = Object.assign(
      {
        region: authInfo.region.code,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        pkg
      },
      videoFilter.browserFilters
    )
    ;[
      'providerIds',
      'sources',
      'cates',
      'cpCates',
      'tags',
      'keywords',
      'playlistName',
      'cpTags',
      'hashTags'
    ].forEach(item => {
      let list = []
      searchOptions[item].forEach(i => {
        if (i.hasOwnProperty('value')) {
          list.push(i.value)
        } else {
          list.push(i)
        }
      })
      searchOptions[item] = list
    })
    if (searchOptions.categoryType === 'all') {
      searchOptions.categoryType = ''
    }
    if (
      searchOptions.docId.split(' ').filter(item => item.trim() !== '').length >
      1
    ) {
      searchOptions.docId = searchOptions.docId
        .split(' ')
        .filter(item => item.trim() !== '')
        .join(',')
    }
    searchOptions.cpName =
      searchOptions.cpName === 'all' ? '' : searchOptions.cpName
    searchOptions.storeTimeStart =
      searchOptions.storeTimeStart === null ? 0 : searchOptions.storeTimeStart
    searchOptions.storeTimeEnd =
      searchOptions.storeTimeEnd === null ? 0 : searchOptions.storeTimeEnd
    searchOptions.publishTimeStart =
      searchOptions.publishTimeStart === null
        ? 0
        : searchOptions.publishTimeStart
    searchOptions.publishTimeEnd =
      searchOptions.publishTimeEnd === null ? 0 : searchOptions.publishTimeEnd
    searchOptions.updateTimeStart =
      searchOptions.updateTimeStart === null ? 0 : searchOptions.updateTimeStart
    searchOptions.updateTimeEnd =
      searchOptions.updateTimeEnd === null ? 0 : searchOptions.updateTimeEnd

    if (searchOptions.language === 'all') {
      searchOptions.language = ''
    }
    if (searchOptions.language === 'others') {
      videoFilter.languages.forEach((item, index) => {
        if (index > 1) {
          searchOptions.lans.push(item.value)
        }
      })
    } else {
      searchOptions.lans = []
    }
    return searchOptions
  }

  @action
  fetchVideos = async (pageNum, pageSize) => {
    if (pageNum && pageNum !== this.pageNum) {
      this.pageNum = pageNum
    }
    if (pageSize && pageSize !== this.pageSize) {
      this.pageSize = pageSize
    }
    let searchOptions = this.paramsFn()
    if (!searchOptions) {
      return
    }
    const waitCheckFilters = [
      {
        front: 'publishTimeStart',
        behind: 'publishTimeEnd',
        frontValue: searchOptions.publishTimeStart,
        behindValue: searchOptions.publishTimeEnd
      },
      {
        front: 'storeTimeStart',
        behind: 'storeTimeEnd',
        frontValue: searchOptions.storeTimeStart,
        behindValue: searchOptions.storeTimeEnd
      },
      {
        front: 'updateTimeStart',
        behind: 'updateTimeEnd',
        frontValue: searchOptions.updateTimeStart,
        behindValue: searchOptions.updateTimeEnd
      },
      {
        front: 'minDuration',
        behind: 'maxDuration',
        frontValue: searchOptions.minDuration,
        behindValue: searchOptions.maxDuration
      },
      {
        front: 'minClick',
        behind: 'maxClick',
        frontValue: searchOptions.minClick,
        behindValue: searchOptions.maxClick
      },
      {
        front: 'minCpClick',
        behind: 'maxCpClick',
        frontValue: searchOptions.minCpClick,
        behindValue: searchOptions.maxCpClick
      },
      {
        front: 'minViews',
        behind: 'maxViews',
        frontValue: searchOptions.minViews,
        behindValue: searchOptions.maxViews
      },
      {
        front: 'minLikes',
        behind: 'maxLikes',
        frontValue: searchOptions.minLikes,
        behindValue: searchOptions.maxLikes
      },
      {
        front: 'minShare',
        behind: 'maxShare',
        frontValue: searchOptions.minShare,
        behindValue: searchOptions.maxShare
      },
      {
        front: 'minLikeRate',
        behind: 'maxLikeRate',
        frontValue: searchOptions.minLikeRate,
        behindValue: searchOptions.maxLikeRate
      },
      {
        front: 'minFinishRate',
        behind: 'maxFinishRate',
        frontValue: searchOptions.minFinishRate,
        behindValue: searchOptions.maxFinishRate
      },
      {
        front: 'minDownloads',
        behind: 'maxDownloads',
        frontValue: searchOptions.minDownloads,
        behindValue: searchOptions.maxDownloads
      }
    ]

    let validateResult = validateBetweenFilter(waitCheckFilters)

    if (validateResult) {
      Toast.error(
        intl.formatMessage(
          { id: validateResult.type },
          {
            front: validateResult.front,
            behind: validateResult.behind
          }
        )
      )

      return
    }

    let result
    try {
      console.log('searchOptions=>', searchOptions)
      result = await this.videoApi.fetchVideos(searchOptions, this.highContent)
      let data = result.data
      if (!data.status) {
        throw new Error()
      }
      let videoList = data.result.list || []
      videoList.forEach(val => {
        val.playing = false
        if (!val.image) {
          val.image = {
            url: require('../../assets/no-poster.jpeg'),
            width: 320,
            height: 180
          }
        }
      })

      videoList.forEach(item => {
        item.iconValue = 0
        item.checked = this.isSelectAll
      })
      this.videos = videoList
      this.sum = data.result.count || 0
    } catch (e) {
      this.videos = []
      this.sum = 0
    }
  }
  @action
  getVideoDetail = docId => {
    return this.videoApi.getVideoDetail(docId)
  }

  @action
  onlineVideo = docId => {
    return this.videoApi.onlineVideo(docId)
  }

  @action
  uploadPic = data => {
    return this.videoApi.uploadPic(data)
  }
  // 单个下线
  @action
  offlineVideo = (docId, reason, pornDegree) => {
    return this.videoApi.offlineVideo({
      docId: docId,
      type: 'VIDEO',
      reason: reason,
      pornDegree: pornDegree
    })
  }

  @action
  batchStateVideo = params => {
    const { type, reason } = params
    let data = this.paramsFn()
    let param = {}
    param = {
      type: 'VIDEO',
      reason: ''
      // ,pornDegree: null,
      // all: null
    }
    if (this.isSelectAll) {
      data.excludeDocIds = this.getUncheckedVideoIds()
      Object.assign(param, {
        all: OPERATION_TARGET_TYPES.all
      })
    } else {
      data.docIds = this.videos
        .filter(item => item.checked)
        .map(item => item.docId)
      Object.assign(param, {
        all: OPERATION_TARGET_TYPES.notAll
      })
    }
    // 批量上线与下线
    if (type === 'online' || type === 'offline') {
      param.reason = reason
      if (type === 'offline') {
        param.pornDegree = params.pornDegree
      }
      return this.videoApi.batchLineVideo({
        data,
        type,
        param
      })
    }

    // 批量修改category
    if (type === 'batchChangeCategory') {
      param.category = reason
      return this.videoApi.batchChangeCategory(data, param)
    }
  }

  @action
  addVideo = data => {
    return this.videoApi.addVideo(data)
  }

  // 批量审核高点内容
  @action
  reviewVideos = data => {
    return this.videoApi.reviewVideos(data)
  }

  @action
  editVideo = (...data) => {
    return this.videoApi.editVideo(...data, this.highContent)
  }

  @action
  updateEditTags = data => {
    return this.videoApi.updateEditTags(data)
  }

  getUncheckedVideoIds = () =>
    this.uncheckedVideosWhenSelectAll.map(item => item.docId)

  @action
  deleteCache = () => {
    return this.videoApi.deleteCache()
  }
  @action
  /**
   * @return {Promise<any>}
   */
  exportVideos = type => {
    // TODO 优化 加一个loading态，否则导出太多可能等待太久
    // select all 状况下需要使用接口把所有数据下载
    const data = this.paramsFn()
    if (!data) return Promise.reject(new Error('ilegal params'))
    // delete data.pageNum
    // delete data.pageSize

    let config = {}
    const loadFields = this.getExportLoadFields()

    if (this.isSelectAll) {
      config = {
        data: {
          ...data,
          excludeDocIds: this.getUncheckedVideoIds(),
          loadFields
        },
        param: {
          exportAll: OPERATION_TARGET_TYPES.all
        }
      }
    } else {
      // 否则，直接把当前选中的数据在浏览器端直接导出
      config = {
        data: {
          ...data,
          docId: this.videos
            .filter(item => item.checked)
            .map(item => item.docId)
            .join(','),
          loadFields
        },
        param: {
          exportAll: OPERATION_TARGET_TYPES.notAll
        }
      }
    }
    if (type) {
      return this.videoApi.exportVideoProgress(config)
    } else {
      return this.videoApi.exportVideosToExcel(config)
    }
  }

  getExportLoadFields = () => {
    const config = this.getExportConfig()
    // const fields = Object.values(config)
    //   .filter(item => !!item)
    // return [...new Set(fields)]
    return Object.keys(config)
  }

  getExportConfig = () => {
    return {
      Docid: 'docId',
      title: 'title',
      'thumbnail url': 'image.url',
      // 'thumbnail url': 'image',
      'videoplay url': 'playUrl',
      duration: 'duration',
      language: 'lan',
      CP: '',
      provider: 'provider.name',
      // 'provider': 'provider',
      // 'publisher': 'source.name',
      publisher: 'source',
      status: 'state',
      level: 'pornDegree', // 需要把0，1，2，3转换成safe、dangerous等文案??
      Review_way: 'auditType', // 需要把-1，0，1，2，3，4 转换成对应的文案？？
      'original category': 'cpCats[0]',
      // 'original category': 'cpCats',
      'editor category': 'cats[0]',
      // 'editor category': 'cats',
      'original tags': 'cpTags',
      'editor tags': 'tags',
      'supertitle link': 'hashTags',
      'playlist name': 'playlistName',
      'publish time': 'publishTime', // 是否需要格式化
      'storage time': 'addTime', // 是否需要格式化
      'update time': 'updateTime', // 是否需要格式化
      'original comments': 'st.comments',
      // 'original comments': 'st',
      'original Likes': 'cpst.likes',
      // 'original Likes': 'cpst',
      'original share': 'totalShare',
      'original views': 'cpst.clicks',
      // 'original views': 'cpst',
      'imp in feed(total)': 'st.views',
      // 'imp in feed(total)': 'st',
      'view in feed(total)': 'st.clicks',
      // 'view in feed(total)': 'st',
      'pv_ctr(total)': '', // 没有属性，通过计算出来的 st.clicks / st.views
      // 'imp_7days': 'extraData.sevenTopView',
      imp_7days: 'extraData',
      // 'view_7days': 'extraData.sevenTopClick',
      view_7days: 'extraData',
      // 'ctr_7days': 'extraData.sevenTopRate',
      ctr_7days: 'extraData',
      // 'likes in feed': 'st.likes',
      'likes in feed': 'st',
      score: 'boutiques'
    }
  }

  // exportFromBrowserEnd = () => {
  //   // mac文件名不能包含标点符合，最终会自动变成“CMS Data2020-02-17 17_01.xlsx”
  //   const filename = 'CMS Data' + moment(Date.now()).format('YYYY-MM-DD HH:mm') + '.xlsx'
  //   downloadExl2([
  //     {
  //       prop1: 'hello',
  //       prop2: 'world'
  //     },
  //     {
  //       prop1: 'hello2',
  //       prop2: 'world2'
  //     }
  //   ], {
  //     filename
  //   })
  // }

  isSameVideo (a, b) {
    return a.docId === b.docId
  }
  @action
  toggleVideoChecked (index, checked) {
    // debugger
    const video = this.videos[index]
    video.checked = checked
    if (!this.isSelectAll) return
    const { has, remove } = Collection
    const hasRecorded = has(
      this.uncheckedVideosWhenSelectAll,
      video,
      this.isSameVideo
    )
    if (checked) {
      hasRecorded &&
        remove(this.uncheckedVideosWhenSelectAll, video, this.isSameVideo)
    } else {
      !hasRecorded && this.uncheckedVideosWhenSelectAll.push(video)
    }
  }
}

const videoApi = new VideoAPI()
const videoStore = new VideoStore(videoApi)

export default videoStore

const en_US = {
  country: 'Country',
  user: 'User',
  logout: 'Sign out',
  RU: 'Russia',
  IN: 'India',
  UA: 'Ukraine',
  ID: 'Indonesia',
  russiaLan: 'Russia',
  ensureOffline: 'Are you sure you want to go Offline?',
  ensureOnline: 'Are you sure you want to go Online?',
  ensureDelete: 'Are you sure you want to delete the user?',
  offlineReason: 'Please enter the reason for offline',
  nullReason: 'Please fill in the reason for the offline, not all spaces',
  offlineFail: 'Offline video failed',
  search: 'Search',
  reset: 'Reset',
  online: 'Online',
  offline: 'Offline',
  horizontal: 'Width over height',
  vertical: 'Height over width',
  square: 'Height equals width',
  yes: 'Yes',
  no: 'No',
  all: 'All',
  fillIds: 'Please fill in IDs',
  addVideo: 'Add a crawl video',
  idCollection: 'Bulk IDs',
  type: 'Types of',
  separateId: 'Each ID is separated by an English comma',
  requestSended: 'A request has been made and is being executed',
  requestFail: 'The request failed. Please check the network connection',
  crawl: 'Crawl',
  null: 'No details',
  close: 'Close',
  cancel: 'Cancel',
  ensure: 'OK',
  jump: 'Jump to',
  page: 'Page',
  regionSelect: 'Region Selection',
  IntervalClosure: '{front} and {behind} should be both null or both not null',
  IntervalIllegal: '{front} should be less than {behind}',
  reason: 'Offline Reason',
  logoutFail: 'Logout fail',
  tagExisted: 'This tag already exists',
  moreTag: 'Can only add up to 10 labels',
  fileType: 'File format is only supported',
  maxSize: 'Image size cannot exceed',
  minSize: 'Image size cannot be less than',
  uploadSuccess: 'Successful upload',
  uploadFail: 'Upload failed',
  uploadImg: 'Upload Image',
  cropImg: 'Crop Image',
  manuallyPlay: 'Manually increase clicks',
  editVideoFail: 'Edit video failed',
  editNewsFail: 'Edit news failed',
  editGifFail: 'Edit gif failed',
  editPictureFail: 'Edit picture failed',
  boutique: 'Add Highquality',
  cancelBoutique: 'Cancel Highquality',
  videoBoutiqueFail: 'Video Highquality failed',
  newsBoutiqueFail: 'News Highquality failed',
  cropFail: 'Crop Image failed',
  getNewsDetailFail: 'Failed to get information details',
  userManage: 'User Management',
  userList: 'User List',
  addUser: 'Add User',
  addUserFail: 'Add user failed',
  editUserFail: 'Edit user failed',
  deleteUserFail: 'Delete user failed',
  miMail: 'Mi Email address',
  role: 'Role',
  manageArea: 'Management area',
  manageContent: 'Management content',
  operate: 'Operate',
  mailIsNeed: 'Email is required',
  roleIsNeed: 'Role is required',
  regionIsNeed: 'Country/Region is required while is not admin',
  contentIsNeed: 'Manage contents is required',
  getUserDetailFail: 'Get user info failed',
  welcome: 'Welcome to the Content Pool Management System',
  addRegion: 'Add region',
  addCategory: 'Add category',
  editCategory: 'Edit category',
  languageRequire: 'The language for the application cannot be empty',
  applyReasonRequire: 'The reason for the application cannot be empty',
  addCrawlStatus: 'Add a crawl task {status}',
  // 屏蔽词  cp
  maskWord: 'Mask word',
  addMaskWord: 'Add mask word',
  maskWordPrompt: 'One per line, you can add more than one',
  validateRequire: '{name} is required',
  notValid: '{name} is not valid',
  failedToast: '{operator} failed',
  successToast: '{operator} success',
  editFail: 'Edit failed',
  editSuccess: 'Successful editing',
  addFail: 'Add failed',
  addSuccess: 'Successful add',
  deleteFail: 'Failed to delete',
  deleteSuccess: 'Successfully deleted',
  listFail: 'Failed to get list',
  deleteSure: 'Are you sure you want to delete the {name}',
  name: 'name',
  callbackLink: 'Callback link',
  contentLink: 'Content link',
  token: 'Token setting is valid',
  cprovide: 'Cp provides',
  addCrawlTask: 'Add a crawl task',
  applicateReason: 'Reason for application',
  setTokenFail: 'Set token status failed',
  getRegionListFail: 'Failed to get region list',
  // taskManage
  waiting: 'To be processed',
  processing: 'Processing',
  finished: 'Completed',
  processingStatusChannel: 'PROCESSING STATUS',
  processingStatusKeywords: 'Processing Status-Keywords',
  processingStatusPlaylist: 'Processing Status-playlist',
  approveOperate: 'To apply for',
  crawlType: 'Crawl type',
  applicant: 'The applicant',
  applicantReason: 'Reason for application',
  applicantTime: 'Application time',
  executeTime: 'Execution time',
  finishedTime: 'Finished time',
  reject: 'Reject',
  approve: 'Approve',
  approveEnsure: 'Are you sure to approve the task?',
  rejectTask: 'Reject the task',
  approveTask: 'Approve the task',
  enterRejectReason: 'Please enter the reason for the rejection',
  manualAddContent: 'Add {content} manually',
  rejected: 'Rejected',
  completed: 'Completed',
  auit: 'To audit',
  rejectReason: 'Reason for rejection',
  operateStatus: '{operate} {status} ',
  // publisherManage
  contentCount: 'Number of content',
  offlinePublisherReason: 'Offline reason',
  onlineSuccess: 'online Success',
  offlineSuccess: 'offline Success',
  ensureRestore: 'Ensure restore',
  afterEnsureRestore:
    'After ensuring, the content will be delivered and crawled.',
  operatePublisherStatus: '{operate} publisher {status}',
  // menu option
  contentManage: 'Content Manage',
  basicManage: 'Basic Manage',
  contentList: 'View List',
  webCrawler: 'Web crawler',
  addContent: 'Add',
  viewTask: 'View Tasks',
  batchOffline: 'Batch Offline',
  categoryManage: 'Category Manage',
  areaManage: 'Area Manage',
  providerManage: 'Provider Manage',
  maskWordManage: 'Maskword manage',
  uploadCSV: 'CSV Upload',
  // filterTip
  docIdTip: 'The unique ID identification of the content',
  tagTip: 'Contains Native Tag and Editer Tag',
  // areaManage
  deleteArea: 'Are you sure you want to delete the area?',
  deleteCategory: 'Are you sure you want to delete the category?',
  categoryName: 'Category Name',
  operating: 'Operating',
  areaName: 'Area name',
  addCoutry: 'Add country',
  awardList: 'Failed to get list...',
  choose: 'Please choose',
  selectRegion: 'Select region',
  emptyInput: "Can't be empty!",
  'View List': 'View List',
  'Add Game': 'Add Game',
  'Review Game': 'Review Game',
  'Tag Manage': 'Tag Manage',
  addTag: 'AddTag',
  tagId: 'tagId',
  tagName: 'TagName',
  wait: 'Please wait a moment',
  'operate people': 'operate people',
  'operate time': 'operate time',
  bulkIdRequire: "bulk ID can't be empty!",
  reasonRequire: "reason can't be empty!",
  publisherRequire: "publisher can't be empty!",
  vkNameRequire: "name can't be empty!",
  keywordRequire: "keyword can't be empty!",
  hashTagRequire: 'keyword start with #, for example :#id1!',
  crawlTimeRequire: "crawlTime can't be empty!",
  crawlPublishTimeRequire: "crawlPublishTime can't be empty!",
  crawlSortRequire: "crawlSort can't be empty!",
  categoryRequire: 'please select category',
  idExist: '{status} already exist!',
  addTags: 'Add Tags(s)',
  removeTags: 'Remove Tag(s)',
  'Theme List': 'Theme List',
  'Add Theme': 'Add Theme',
  'Please complete the parameters': 'Please complete the parameters',
  'Please enter the correct url': 'Please enter the correct url',
  'add not': 'Add Not',
  exportData: 'Export Data',
  selectImg: 'Select Image',
  tailor: 'tailor',
  'view original tags': 'view original tags',
  'close original tags': 'close original tags',
  selectCheckBox: 'First select checkbox,please!',
  'select category': 'Change Editor Category to',
  crawlTimeNumber: 'crawlTime must be number!',
  onlineSelectCheckBox: 'Only online cards have this feature!',
  offlineSelectCheckBox: 'Only offline cards have this feature!',
  categoryNotNull: `category don't be null!`,
  EditorInformation: 'Editor information',
  SelectReason: 'Please choose the {type} reason:',
  highContent: `You can't edit filters when Show Only Top Contents switch is opened`,
  crawlCountNumber: `crawlCount can't less than 0!`,

  // filter
  provider: 'Provider',
  language: 'Language',
  videoStatus: 'Video Status',
  isEdited: 'isEdited',
  reviewWay: 'Review_way',
  title: 'Title',
  docId: 'Doc ID',
  CP: 'CP',
  videoUrl: 'Video PlayUrl',
  videoId: 'Video PlayId',
  publisher: 'Publisher',
  publishTime: 'Publish Time',
  storageTime: 'Storage Time',
  updateTime: 'Update Time',
  originalCategory: 'Original Category',
  editorCategory: 'Editor Category',
  editorTag: 'Editor Tag',
  Keywords: 'Keywords',
  hashTags: 'HashTag',
  playlistName: 'PlaylistName',
  videoDuration: 'Video Duration Time',
  originalViews: 'Original Views',
  impressionInNewsFeed: 'Impression in Newsfeed',
  viewInFeed: 'View in Feed',
  likeInFeed: 'Like in Feed',
  shareInFeed: 'Share in Feed',
  downloadInFeed: 'Download in Feed',
  videoType: 'Video Type',
  isAD: 'isAD',
  editor: 'Editor',
  originalTag: 'Original Tag',
  contentLevel: 'Content Level',
  originalLevel: 'Original Level',
  editorLevel: 'Editor Level',
  topContent: 'Top Content',
  safe: 'Safe',
  suspect: 'Suspect',
  dangerous: 'Dangerous',
  safe_for_kids: 'Safe For Kids',
  tagsStatus: 'Tags Status',
  accountRequired: "account can't be empty!",
  fieldRequired: "{field} can't be empty!",
  score: 'Score',
  noScore: 'Not rated',
  authorId: 'Author ID',
  shareRate: 'Share Rate',
  finishRate: 'Finish Rate',
  likeRate: 'Like Rate',
  accountId: 'Account ID',
  onlineFail: 'Online Fail',
  // other
  uploadDesc:
    'Here is the file upload entry used for the content review of the RU Newsfeeds'
}

export default en_US

import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import Toast from '@src/components/Toast'

class CPManageAPI {
  getCPList = params =>
    fetchData({
      url: 'provider/list',
      data: {
        name: '',
        ...params
      },
      method: 'get'
    })

  editCP = params =>
    fetchData({
      url: `provider/${params.id ? 'edit' : 'add'}`,
      data: { ...params }
    })

  deleteCP = params =>
    fetchData({
      url: `provider/delete`,
      data: { ...params }
    })

  setToken = params =>
    fetchData({
      url: `provider/miss/token`,
      data: { ...params }
    })

  getRegions = () =>
    fetchData({
      url: 'region/list',
      method: 'GET'
    })
}

class CPManage {
  constructor (api) {
    this.cpManageApi = api
  }

  @observable
  list = []
  @observable
  total = 0
  @observable
  pageSize = 10
  @observable
  pageNum = 1

  @action
  getCPList (params) {
    this.cpManageApi
      .getCPList(
        Object.assign(
          {
            pageSize: this.pageSize,
            pageNum: this.pageNum
          },
          null
        )
      )
      .then(
        action(res => {
          if (res.data.status) {
            let result = res.data.result || {}
            this.list = result.list || []
            this.total = result.count || 0
          } else {
            Toast.error(res.data.msg)
          }
        })
      )
  }

  @action
  editCP (params) {
    return this.cpManageApi.editCP(params)
  }

  @action
  deleteCP (params) {
    return this.cpManageApi.deleteCP(params)
  }

  @action
  setToken (params) {
    return this.cpManageApi.setToken(params)
  }

  @action
  getRegions = () => {
    return this.cpManageApi.getRegions()
  }

  @action
  updatePageNum = pageNum => {
    if (pageNum === this.pageNum) {
      return
    }

    this.pageNum = pageNum
  }
}

const cpManageAPI = new CPManageAPI()
const cpManage = new CPManage(cpManageAPI)

export default cpManage

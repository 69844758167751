import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from '../authInfo'
import contentManage from '../ContentManage/ContentManage'

class PublisherManageAPI {
  getPublisherList = option =>
    fetchData({
      url:
        `source/offline/list?region=${authInfo.region.code}` +
        `&type=${contentManage.contentType.toUpperCase()}&ps=${
          option.pageSize
        }&pn=${option.pageNum}&publisher=${option.publisher}`,
      method: 'POST'
    })

  offlinePublisher = data =>
    fetchData({
      url: `source/offline/doc`,
      method: 'POST',
      data: Object.assign(data, {
        region: authInfo.region.code,
        type: contentManage.contentType.toUpperCase()
      })
    })

  restorePublisher = name =>
    fetchData({
      url:
        `source/restore/doc?region=${authInfo.region.code}` +
        `&name=${name}&type=${contentManage.contentType.toUpperCase()}`,
      method: 'POST'
    })
}

class PublisherManage {
  constructor (api) {
    this.publisherManageApi = api
  }

  @observable
  publisherList = []
  @observable
  pageSize = 10
  @observable
  pageNum = 1
  @observable
  sum = 0

  @action
  updatePageNum = (attr, value) => {
    this[attr] = value
  }
  @action
  getPublisherList = async searchName => {
    let searchOptions = {
      pageSize: this.pageSize,
      publisher: ''
    }
    if (searchName) {
      searchOptions.publisher = searchName
      searchOptions.pageNum = 1
      this.pageNum = 1
    } else {
      searchOptions.pageNum = this.pageNum
    }
    const result = await this.publisherManageApi.getPublisherList(searchOptions)

    if (result.data && result.data.status) {
      this.publisherList = result.data.result.list || []
      this.sum = result.data.result.count || 0
    }
  }

  @action
  offlinePublisher = data => {
    return this.publisherManageApi.offlinePublisher(data)
  }

  @action
  restorePublisher = name => {
    return this.publisherManageApi.restorePublisher(name)
  }
}

const publisherManageAPI = new PublisherManageAPI()
const publisherManage = new PublisherManage(publisherManageAPI)

export default publisherManage

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, MenuItem } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: 60,
        minHeight: 60,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: 0
    },
    sort: {
        display: 'flex',
        alignItems: 'center'
    },
    menu: {
        padding: '11px 8px',
        width: 200
    },
    sortType: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: theme.spacing.unit
    },
    up: {
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        cursor: 'pointer',
        marginBottom: 5
    },
    down: {
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        cursor: 'pointer'
    }
})

class GameOperate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          currentValue: 'publishTime',
          topActive: false
        }
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value
        }, () => {
          this.emitChooseOption('currentValue');
        })
    }

    setSortType = (type) => {
        this.setState({
            topActive: !!(type)
        }, () => {
          this.emitChooseOption('topActive');
        })
    }

    emitChooseOption(field) {
      const { currentValue, topActive } = this.state;
      const { handleSortOption } = this.props;

      if(handleSortOption) {
        handleSortOption(field, {
          currentValue,
          topActive
        });
      }
    }

    render() {
        const { classes, currentOptions } = this.props;
        const { currentValue, topActive } = this.state;
        return (
            <div className={classes.root}>
                <div className={classes.sort}>
                    <Typography style={{ color: 'green', marginRight: '5px' }}>Sorted by:</Typography>
                    <TextField margin="none" select variant="outlined" SelectProps={{ classes: { outlined: classes.menu } }} value={currentValue} onChange={this.handleChange}>
                        {currentOptions.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                    <div className={classes.sortType}>
                      <div style={{ borderBottom: `12px solid ${topActive ? '#00838f' : '#ccc'}` }} className={classes.up} onClick={() => this.setSortType(1)}></div>
                      <div style={{ borderTop: `12px solid ${topActive ? '#ccc' : '#00838f'}` }} className={classes.down} onClick={() => this.setSortType(0)}></div>
                    </div>
                </div>
            </div>
        )
    }
}

GameOperate.PropTypes = {
  classes: PropTypes.object.isRequired,
  currentOptions: PropTypes.array.isRequired,
  handleSortOption: PropTypes.func.isRequired
}
export default withStyles(styles, { withTheme: true })(GameOperate)
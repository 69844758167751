import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SecondaryDropdown from '@src/components/SecondaryDropdown/Index'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { intl } from '@src/IntlGlobalProvider'
import { searchItem } from '@src/utils/tools'

const styles = () => ({
  root: {
    display: 'flex'
  },
  dropdownContainer: {
    position: 'relative',
    padding: '8px 16px',
    borderRadius: '4px',
    background: '#fff',
    marginLeft: 10,
    color: '#000',
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  },
  button: {
    color: '#000',
    background: '#fff',
    marginLeft: 10,
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
})

@inject('authInfo')
@observer
class AdminMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menus: null,
      isShowDropdown: false,
      currentDatabaseName: '',
      databases: [
        {
          id: 'IN',
          name: 'India database',
          domain: 'http://in.cms.pt.intl.miui.com'
        },
        {
          id: 'RU',
          name: 'Russia database',
          domain: 'http://cms.pt.intl.miui.com'
        },
        {
          id: 'SG',
          name: 'Singapore database',
          domain: 'http://sgp.cms.pt.intl.miui.com'
        }
      ]
    }
  }

  handleDatabaseChange = e => {
    const { database } = this.state
    let newDatabase = e.target.value

    if (newDatabase === database) {
      return
    }

    window.location.href =
      this.getDomain(newDatabase) + process.env.PUBLIC_URL + '/index.html'
  }

  renderMenu = () => {
    const { databases } = this.state

    let menus = {
      className: 'slj-menu-container',
      submenus: []
    }

    menus.submenus = databases.map(database => {
      return {
        name: database.name,
        className: 'slj-sub-menu-container slj-submenu-first',
        submenus: [
          {
            className: 'slj-menu-item',
            name: intl.formatMessage({ id: 'userManage' }),
            path: '/userManage'
          },
          {
            className: 'slj-menu-item',
            name: intl.formatMessage({ id: 'areaManage' }),
            path: '/areaManage'
          }
        ]
      }
    })

    this.setState({
      menus: menus
    })
  }

  handleDropdownChange = (database, path) => {
    const { authInfo } = this.props
    const { changeRegion, isOnline } = authInfo

    let region
    let currentDatabase = searchItem(this.state.databases, { name: database })
      .item
    let domain = window.location.origin

    // 修复admin 选择database后进行usermanage时，地区选项不存在
    switch (database) {
      case 'India database':
        region = { name: 'India', code: 'IN' }
        break
      case 'Russia database':
        region = { name: 'Russia', code: 'RU' }
        break
      case 'Singapore database':
        region = { name: 'Singapore', code: 'SG' }
        break
      default:
        region = {}
    }
    changeRegion(region)

    if (currentDatabase && domain !== currentDatabase.domain && isOnline) {
      window.location.href =
        currentDatabase.domain +
        process.env.PUBLIC_URL +
        '/index.html?path=' +
        path
    } else {
      this.props.history.replace(path)
    }
  }

  gotoCpManage = () => {
    const { history } = this.props

    history.replace('/cpManage')
  }

  componentDidMount () {
    this.renderMenu()

    let currentDatabase = searchItem(this.state.databases, { domain: origin })
      .item
    this.setState({
      currentDatabaseName:
        (currentDatabase && currentDatabase.name) || 'Russia database'
    })

    if (window.location.search) {
      let search = window.location.search
      let path = ''

      search.replace(/path=(.+)(?=\b)/, (match, $path) => {
        path = $path
      })

      if (path) {
        this.props.history.replace(path)
      }
    }
  }

  render () {
    const { classes } = this.props
    const { menus, isShowDropdown, currentDatabaseName } = this.state
    return (
      <div className={classes.root}>
        <div
          className={classes.dropdownContainer}
          onMouseEnter={() => {
            this.setState({ isShowDropdown: true })
          }}
          onMouseLeave={() => {
            this.setState({ isShowDropdown: false })
          }}
        >
          {currentDatabaseName}
          {menus &&
            isShowDropdown && (
            <SecondaryDropdown
              menus={menus}
              ChangeHandle={this.handleDropdownChange}
            />
          )}
        </div>
        <div className={classes.button} onClick={this.gotoCpManage}>
          {intl.formatMessage({ id: 'providerManage' })}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(AdminMenu))

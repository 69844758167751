import Loadable from 'react-loadable'
import Loading from '@src/components/Loading'

// Browser Game Production
import { GameAdmin, GameAdd, GameReview } from '../pages/BrowserGames'
import Statistical from '../pages/Statistical/index'
import ContentsCheck from '../pages/AwardIds/index'
import BatchOffline from '../pages/BatchOffline/index'
import ExportExcel from '../pages/ExportExcel/index'
import UploadNews from '../pages/UploadNewsManage/Index'

const TagManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "tag" */ '../pages/TagManage/Index'),
  loading: Loading
})

const RegionSelect = Loadable({
  loader: () =>
    import(/* webpackChunkName: "region" */ '../pages/RegionSelect'),
  loading: Loading
})

const AddContent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "content" */ '../pages/AddContent/Index'),
  loading: Loading
})

const CPManage = Loadable({
  loader: () => import(/* webpackChunkName: "cp" */ '../pages/CPManage/Index'),
  loading: Loading
})

const MaskWordManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "maskword" */ '../pages/MaskWordManage/Index'),
  loading: Loading
})

const CategoryManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "category" */ '../pages/CategoryManage/Index'),
  loading: Loading
})

const AreaManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "area" */ '../pages/AreaManage/Index'),
  loading: Loading
})

const PublisherManage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "publisher" */ '../pages/PublisherManage/Index'
    ),
  loading: Loading
})

const TaskManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "task" */ '../pages/TaskManage/Index'),
  loading: Loading
})

const UserManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "user" */ '../pages/UserManage/Index'),
  loading: Loading
})

const ContentManage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "content" */ '../pages/ContentManage/Index'),
  loading: Loading
})

const WebCrawler = Loadable({
  loader: () =>
    import(/* webpackChunkName: "webCrawler" */ '../pages/WebCrawler/Index'),
  loading: Loading
})

const ThemeList = Loadable({
  loader: () =>
    import(/* webpackChunkName: "theme" */ '../pages/Theme/ThemeList/Index'),
  loading: Loading
})

const ThemeAdd = Loadable({
  loader: () =>
    import(/* webpackChunkName: "themeAdd" */ '../pages/Theme/ThemeAdd/Index'),
  loading: Loading
})

const rootRoutes = [
  { path: '/userManage', name: '用户管理', component: UserManage },
  { path: '/taskManage', name: '任务管理', component: TaskManage },
  {
    path: '/publisherManage',
    name: 'Publisher管理',
    component: PublisherManage
  },
  { path: '/areaManage', name: '地区管理', component: AreaManage },
  { path: '/categoryManage', name: '分类管理', component: CategoryManage },
  { path: '/maskWordManage', name: '屏蔽词管理', component: MaskWordManage },
  { path: '/cpManage', name: 'cp管理', component: CPManage },
  { path: '/addContent', name: '添加内容', component: AddContent },
  { path: '/tagManage', name: '运营tag管理', component: TagManage },
  { path: '/regionSelect', name: '区域选择', component: RegionSelect },
  { path: '/addWebCrawler', name: '网页爬虫', component: WebCrawler },
  { path: '/uploadNews', name: '上传CSV', component: UploadNews }
]

const newsFeedRouts = [
  {
    path: '/newsFeed/contentManage',
    name: '内容管理',
    component: ContentManage
  }
]

const panipuriRouts = [
  {
    path: '/panipuri/contentManage',
    name: '内容管理',
    component: ContentManage
  }
]

const browserGameRouts = [
  {
    path: '/browserGame/contentManage',
    name: '内容管理',
    component: GameAdmin
  },
  { path: '/browserGame/gameAdd', name: '内容管理', component: GameAdd },
  { path: '/browserGame/gameReview', name: '游戏审核', component: GameReview }
]

const browserThemeRouts = [
  {
    path: '/browserTheme/contentManage',
    name: '内容管理',
    component: ThemeList
  },
  {
    path: '/browserTheme/addTheme',
    name: '增加主题',
    component: ThemeAdd
  }
]

const contentsCheckRouts = [
  { path: '/contentsCheck', name: '解析ID', component: ContentsCheck }
]
const statisticalRouts = [
  { path: '/statistical', name: '统计管理', component: Statistical }
]
const batchofflineRouts = [
  { path: '/batchoffline', name: '批量下线', component: BatchOffline }
]

const exportExcel = [
  { path: '/exportexcel', name: '导入导出', component: ExportExcel }
]

export default rootRoutes.concat(
  newsFeedRouts,
  panipuriRouts,
  browserGameRouts,
  statisticalRouts,
  contentsCheckRouts,
  batchofflineRouts,
  browserThemeRouts,
  exportExcel
)

import axios from 'axios'
import Toast from '../components/Toast'
import { intl } from '@src/IntlGlobalProvider'
import ContentManage from '../models/ContentManage/ContentManage'

const restArguments = function (func, startIndex) {
  startIndex = startIndex == null ? func.length - 1 : +startIndex
  return function () {
    var length = Math.max(arguments.length - startIndex, 0)

    var rest = Array(length)

    var index = 0
    for (; index < length; index++) {
      rest[index] = arguments[index + startIndex]
    }
    switch (startIndex) {
      case 0:
        return func.call(this, rest)
      case 1:
        return func.call(this, arguments[0], rest)
      case 2:
        return func.call(this, arguments[0], arguments[1], rest)
    }
    var args = Array(startIndex + 1)
    for (index = 0; index < startIndex; index++) {
      args[index] = arguments[index]
    }
    args[startIndex] = rest
    return func.apply(this, args)
  }
}

const delay = restArguments(function (func, wait, args) {
  return setTimeout(function () {
    return func.apply(null, args)
  }, wait)
})

export function fetchData ({ url, data, ...config }) {
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL

  if (!url) return
  const rawResponse = config.rawResponse
  delete config.rawResponse
  let method = config.method || 'post'
  if (method.toLowerCase() === 'get' && data !== undefined) {
    config.params = { ...data }
  }
  if (
    ContentManage.paramsPkgType !== 'statistical' &&
    ContentManage.paramsPkgType !== 'batchoffline' &&
    ContentManage.paramsPkgType !== 'contentsCheck'
  ) {
    // 奇葩的服务端兼容 'content/cms/doc/batch/offline?type=VIDEO', 'content/cms/doc/batch/online?type=VIDEO'
    let dataUrl = ['user/current/user', 'region/list']
    url =
      dataUrl.includes(url) ||
      url.indexOf('content/cms/doc/batch/online?type=VIDEO') !== -1 ||
      url.indexOf('content/cms/doc/batch/offline?type=VIDEO') !== -1
        ? url
        : url +
          (url.includes('?') ? '&' : '?') +
          `pkg=${ContentManage.paramsPkgType}`
  }

  return new Promise((resolve, reject) => {
    axios({
      url: apiBaseURL + url,
      method,
      data,
      ...config
    })
      .then(response => {
        const result = response.data || {}
        // result._originalResponse = response
        resolve(rawResponse ? response : result)
      })
      .catch(error => {
        // reject(error)
        Toast.error(error.message)
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          if (window.location.hash != '#login') {
            window.location.hash = '/login'
          }
        }
        // throw error
      })
  })
}

export function formatTimeZone (timezone) {
  let tz = timezone.slice(3)
  let sym = tz.slice(0, 1)
  let time = tz.slice(1).split(':')
  let newTime = 0

  if (time.length === 1) {
    newTime = time[0] * 60
  } else if (time.length === 2) {
    newTime = time[0] * 60 + +time[1]
  }

  return Number(sym + newTime)
}

export function searchItem (arr, condition) {
  if (!judgeType(arr)('array') || !arr.length) {
    return
  }

  let result = {
    index: NaN,
    item: null
  }

  for (let i = 0, len = arr.length; i < len; i++) {
    let isSame = true
    for (let prop in condition) {
      if (arr[i][prop] === condition[prop]) {
        continue
      } else {
        isSame = false
        break
      }
    }

    if (isSame) {
      result.item = arr[i]
      result.index = i
      break
    }
  }

  return result
}

export function validateBetweenFilter (filters) {
  if (!judgeType(filters)('array')) {
    return
  }

  let result = null

  for (let i = 0; i < filters.length; i++) {
    let front = filters[i]['frontValue']
    let behind = filters[i]['behindValue']
    if (
      filters[i].behind === 'publishTimeEnd' ||
      filters[i].behind === 'storeTimeEnd' ||
      filters[i].behind === 'updateTimeEnd'
    ) {
      if (behind && front > behind) {
        result = filters[i]
        result.type = 'IntervalIllegal'
        break
      }
    } else {
      if (behind !== '' && +front > +behind) {
        result = filters[i]
        result.type = 'IntervalIllegal'
        break
      }
    }
  }

  return result
}

export function judgeType (param) {
  return type => {
    let paramType = Object.prototype.toString.call(param)
    return paramType.substring(8, paramType.length - 1).toLowerCase() === type
  }
}

export function formatDuration (duration, style) {
  let minutes, seconds

  if (style === 'panipuri') {
    duration = duration / 1000
    minutes = Math.floor(duration / 60)
    seconds = Math.floor(duration % 60)
  }
  if (style === 'newsFeed') {
    minutes = Math.floor(duration / 60)
    seconds = duration % 60
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return minutes + ':' + seconds
}

export function getImageNaturalSize (img, cb) {
  if (img.naturalWidth) {
    cb(img.naturalWidth, img.naturalHeight)
  } else {
    let image = new Image()
    image.src = img.src
    image.onload = () => {
      cb(image.width, image.height)
    }
  }
}

export function paramRequireCheck (params) {
  if (!Array.isArray(params)) {
    return false
  }

  let hasNullParam = false
  for (let i = 0; i < params.length; i++) {
    if (!params[i]['value'].trim()) {
      Toast.error(
        intl.formatMessage(
          { id: 'validateRequire' },
          { name: params[i]['key'] }
        )
      )
      hasNullParam = true
      break
    }
  }

  return hasNullParam
}

export function urlValidCheck (url) {
  if (typeof url !== 'string') {
    return false
  }

  url = url.trim()

  if (!url) {
    return false
  }

  return /^http(s)?:\/\//.test(url)
}

export function debounce (func, wait, immediate) {
  var timeout, result

  var later = function (context, args) {
    timeout = null
    if (args) result = func.apply(context, args)
  }

  var debounced = restArguments(function (args) {
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      var callNow = !timeout
      timeout = setTimeout(later, wait)
      if (callNow) result = func.apply(this, args)
    } else {
      timeout = delay(later, wait, this, args)
    }

    return result
  })

  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

/**
 * 线上环境根据域名确定默认选择，测试环境默认选择russia
 */
export function getDefaultDatabase () {
  const host = location.host
  const reg = /^(\w*)\.?cms\.pt\.intl\.miui\.com/
  const [, db = 'ru'] = host.match(reg)
  return db
}

export function getRegionNameByCode (code) {
  const regionConf = {
    RU: 'Russia',
    UA: 'Ukraine',
    IN: 'India',
    ID: 'Indonesia',
    ES: 'Spain'
  }

  return regionConf[code] || ''
}

/**
 * sendBeacon 解决浏览器窗口关闭时，异步请求被丢弃的问题
 */

export function sendBeacon (params) {
  const img = new Image()
  const path = 'doc/action/edit'
  const { query } = params
  img.src = `${path}?${query}`
}

/**
 * 判断是否是youtube的视频
 * youtube视频采用react-youtube播放，非youtube的采用video
 */

export function isYoutubeUrl (url) {
  const reg = /^https:\/\/www.youtube.com/
  return reg.test(url)
}
/**
 *
 * @param {itemList} itemList
 * @param {totalList} totalList
 * @example
 * const a = ['BROWSER'];
 * const list = [{
      name: 'NewsFeed',
      value: 'BROWSER'
    }, {
      name: 'Panipuri',
      value: 'PANIPURI'
    }]
    func(a, list) => [NewsFeed]
 */

export function getExistItemFromList (itemList, totalList) {
  const result = totalList.reduce((acc, cur) => {
    if (itemList.includes(cur.value)) {
      acc.push(cur.name)
    }
    return acc
  }, [])
  return result
}
export function deleteAttribute (obj, arr) {
  arr.forEach(value => {
    if (obj.hasOwnProperty(value)) {
      delete obj[value]
    }
  })
}

export const copyToClipboard = text => {
  const textArea = document.createElement('textarea')
  textArea.setAttribute(
    'style',
    'width:1px;height:1px;border:0;position:absolute;left:-999999px;'
  )
  document.querySelector('#alert-dialog-title').appendChild(textArea)
  textArea.value = text
  textArea.select()
  document.execCommand('copy')
  document.querySelector('#alert-dialog-title').removeChild(textArea)
}

/**
 * 表单校验策略
 */
var validStrategy = {
  nonEmptyString (str, paylod) {
    if (str === '') {
      return paylod.errorMsg
    }
  },
  nonEmptyArray (arr, paylod) {
    if (Array.isArray(arr) && arr.length > 0) {
      return paylod.errorMsg
    }
  },
  stringMaxLength (str, paylod) {
    if (str.length > paylod.maxLength) {
      return paylod.errorMsg
    }
  }
}

export function Validator () {
  let validatorList = []

  function add (value, rules) {
    for (var i = 0; i < rules.length; i++) {
      const rule = rules[i]
      const { strategy, payload } = rule

      if (typeof strategy === 'string') {
        normalCheck(value, strategy, payload)
      }

      if (typeof strategy === 'function') {
        customFunction(value, strategy, payload)
      }
    }
  }

  function normalCheck (value, strategy, payload) {
    const validFun = validStrategy[strategy]

    if (typeof validFun !== 'function') {
      throw new Error(`表单校验的strategy[${strategy}]不存在`)
    }

    validatorList.push(function () {
      return validFun.apply(null, [value, payload])
    })
  }

  function customFunction (value, isValidFun, payload) {
    const boundFun = function () {
      if (!isValidFun(value)) {
        return payload.errorMsg
      }
    }
    validatorList.push(boundFun)
  }

  function start () {
    for (var i = 0, len = validatorList.length; i < len; i++) {
      const validFun = validatorList[i]
      const errorMsg = validFun()

      if (errorMsg) {
        return errorMsg
      }
    }
  }

  return {
    add,
    start
  }
}

export function isPromise (promise) {
  return (
    promise !== null &&
    typeof promise === 'object' &&
    typeof promise.then === 'function'
  )
}

export function address () {
  let host = window.location.host
  let baseHost = 'http://10.232.52.218:8302'
  // 沙盒
  if (host.indexOf('localhost') > -1) {
    return baseHost
  }
  return `http://${host}`
}

function identify (a, b) {
  return a === b
}
export class Collection {
  constructor (arr) {
    this.list = arr || []
  }

  has (item, identifyFn) {
    return Collection.has(this.list, item, identifyFn)
  }

  remove (item, identifyFn) {
    this.list = Collection.remove(this.list, item, identifyFn)
  }

  add (item) {
    return this.list.push(item)
  }

  size () {
    return this.list.length
  }

  static has (arr = [], target, identifyFn = identify) {
    return arr.some(item => identifyFn(item, target))
  }

  static remove (arr = [], target, identifyFn = identify) {
    return arr.filter(item => !identifyFn(item, target))
  }
}

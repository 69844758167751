import React from 'react'
import { Route } from 'react-router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Layout from './Layout'
import withRoot from '../utils/withRoot'
import Login from '../pages/Login/Index'
import { getToken } from '@src/utils/cookie'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
    background: '#fff'
  },
  main: {
    marginTop: 64
  }
})

class Index extends React.Component {
  componentDidMount() {
    // console.log("渲染")
    if (!getToken()) {
      // console.log("重新登录")
      if (window.location.hash != '#login') {
        window.location.hash = '/login'
      }
    }
  }
  render() {
    // const { classes } = this.props
    return (
      <>
        <Route
          path="/login"
          component={Login}
        ></Route>
        <Layout></Layout>
      </>
    )
  }
}

// Layout.propTypes = {
//   classes: PropTypes.object.isRequired
// }

export default withRoot(withStyles(styles, { withTheme: true })(Index))

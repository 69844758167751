import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Paper,
  MenuItem,
  Button
} from '@material-ui/core'
import DatePicker from 'react-datetime'
import UploadFile from '@src/components/UploadFile'
import authInfo from '@src/models/authInfo'
import Toast from '@src/components/Toast'
import AlertDialog from '@src/components/AlertDialog'
import AsyncSelect from 'react-select/lib/Async'
import classNames from 'classnames'
import GameEditTitle from './GameEditTitle'

const styles = theme => ({
  content: {
    background: '#fff',
    minHeight: 'calc(100vh - 64px - 49px)'
  },
  addContent: {
    padding: theme.spacing.unit * 3,
    width: '98%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '1%'
  },
  contentTitle: {
    width: '40%'
  },
  selectTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  select: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  selectLanguage: {
    width: '50%',
    marginTop: theme.spacing.unit
  },
  divider: {
    width: '90%',
    height: '2px',
    border: '2px solid #ccc',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  formContent: {
    width: '98%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  item: {
    width: '80%',
    marginBottom: theme.spacing.unit * 1.5,
    display: 'flex',
    alignItems: 'center'
  },
  itemTitle: {
    width: '20%',
    textAlign: 'right',
    marginRight: '30px'
  },
  mandatory: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'inline-block'
  },
  inputContent: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  titleBtn: {
    width: '300px',
    marginRight: '10px',
    backgroundColor: '#eee',
    textTransform: 'initial'
  },
  textField: {
    height: 40
  },
  gameImg: {
    width: '50%',
    '@global': {
      'div:first-child': {
        '@global': {
          input: {
            padding: '11px 14px'
          }
        }
      }
    }
  },
  line: {
    width: '50%',
    height: 40,
    display: 'flex',
    alignItems: 'center'
  },
  lineGameOffline: {
    width: '50%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  lineSelectDate: {
    width: '250px',
    marginLeft: '-6px',
    position: 'relative',
    '& input': {
      paddingLeft: '10px'
    }
  },
  lineProviderSelect: {
    width: '80%'
  },
  gameSelect: {
    width: '30%'
  },
  providerSelect: {
    width: '80%'
  },
  gameTagSelect: {
    width: '30%',
    marginRight: theme.spacing.unit * 2
  },
  menu: {
    padding: '11px 8px'
  },
  weighted: {
    width: '80%',
    display: 'flex',
    alignItems: 'center'
  },
  weightedSelect: {
    marginLeft: 10,
    fontSize: 12
  },
  gameTag: {
    width: '80%'
  },
  locationTag: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit
  },
  locationTagSelect: {
    fontSize: '12px',
    marginRight: '5px'
  },
  btnArea: {
    width: '90%',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'space-around'
  },
  uploadImgBtn: {
    marginRight: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '@global': {
      div: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  },
  closeBtn: {
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '& > div': {
      width: '14px',
      height: '14px',
      position: 'relative'
    }
  },
  closeRight: {
    position: 'absolute',
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(45deg)',
    '-webkit-transform': 'rotate(45deg)'
  },
  closeLeft: {
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(-45deg)',
    '-webkit-transform': 'rotate(-45deg)'
  },
  uploadImage: {
    width: 80,
    height: 80
  },
  timeControl: {
    '@global': {
      '.rdtPicker': {
        left: '0 !important'
      }
    }
  },
  selectTag: {
    display: 'flex'
  },
  iconBarColorBtn: {
    width: '250px',
    backgroundColor: 'aliceblue',
    marginRight: '10px',
    '& div': {
      display: 'flex'
    },
    '& p:first-child': {
      height: 20,
      lineHeight: '20px'
    },
    '& p:last-child': {
      width: '80px',
      height: '20px',
      marginLeft: '15px'
    }
  },
  colorItemCard: {
    width: 250,
    height: 250,
    backgroundColor: '#ffffff',
    position: 'absolute',
    top: '38px',
    left: '0',
    zIndex: 9,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > li': {
      width: 80,
      height: 80,
      borderRadius: 10,
      cursor: 'pointer',
      textIndent: '-999px'
    }
  },
  first_themeColor: {
    background: 'linear-gradient(to right,#ef5e38,#f08b63)'
  },
  second_themeColor: {
    background: 'linear-gradient(to right,#f06b2d,#f1a35d)'
  },
  third_themeColor: {
    background: 'linear-gradient(to right,#e69413,#eec243)'
  },
  fourth_themeColor: {
    background: 'linear-gradient(to right,#15be69,#2ce285)'
  },
  fifth_themeColor: {
    background: 'linear-gradient(to right,#59b223,#7ad353)'
  },
  sixth_themeColor: {
    background: 'linear-gradient(to right,#2882ec,#4eb2f8)'
  },
  seventh_themeColor: {
    background: 'linear-gradient(to right,#3243ff,#52a0ff)'
  },
  eighth_themeColor: {
    background: 'linear-gradient(to right,#9e37ff,#eb5aff)'
  },
  ninth_themeColor: {
    background: 'linear-gradient(to right,#682aff,#894be8)'
  },
  preview: {
    width: 90,
    height: 130,
    borderRadius: 10,
    '& > img': {
      width: 90,
      height: 90,
      borderRadius: 10
    },
    '& > div': {
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 3
    }
  }
})

// 单个上传还是批量上传
const SING_OR_BATCH = [
  {
    value: 'Batch',
    label: 'Batch upload'
  },
  {
    value: 'Single',
    label: 'A single upload'
  }
]

const AREAS = [
  { value: 'top_picks', label: 'TOP PICKS' },
  { value: 'new_games', label: 'NEW GAMES' },
  { value: 'favorite_recommend', label: 'FAVORITE RECOMMEND' },
  { value: 'recent_recommend', label: 'RECENT RECOMMEND' },
  { value: 'detail_recommend', label: 'DETAIL RECOMMEND' },
  { value: 'mobile_all', label: 'MOBILE ALL' }
]

@inject('gameAdd', 'tagManage', 'authInfo')
@observer
class GameBatchUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      themeSwitch: false,
      themeColorClass: '',
      themePreview: false,
      gifDeleteBtn: false,
      titleSwtch: false,
      dialogChildren: null,
      dialogTitle: '',
      bannerDeleteBtn: false,
      images: [],
    }
  }
  componentDidMount() {
    const { gameAdd } = this.props
    gameAdd.resetAddInfo()
    if (
      ['ru', 'es'].findIndex(
        item => item === authInfo.region.code.toLocaleLowerCase()
      ) > -1
    ) {
      gameAdd.getTagList()
    }
  }

  setAddInfoHandle = key => event => {
    const { gameAdd } = this.props
    let value = event.target.value
    console.log(value)
    // let isInput = value.length > 8 && !value.endsWith('.') ? true : value.length > this.lastValue.length;
    // if (isInput) {
    //     this.realValue += value.substring(value.length - 1);
    // } else {
    //     this.realValue = this.realValue.substring(0, this.realValue.length - 1)
    // }
    // if (this.realValue.length <= 8) {
    //     this.setState({
    //         titleName: this.realValue
    //     })

    // } else {
    //     this.setState({
    //         titleName: this.realValue.slice(0, 8) + '...'
    //     })
    // }
    // gameAdd.updateAddInfo(key, this.realValue)
    // this.lastValue = value;
    gameAdd.updateAddInfo(key, value)
  }
  // origin 地址
  uploadOriginSuccess = () => data => {
    const { gameAdd } = this.props
    console.log('origin 地址: ' + data.result.url)
    gameAdd.updateAddInfo('originalImagesStr', data.result.url)
  }
  // 当加载图片成功的时候，修改默认的图片，显示正确的图片
  uploadSuccess = style => data => {
    const { gameAdd } = this.props
    let image = new Image()
    image.src = data.result.url
    image.onload = () => {
      let imageWidth = image.naturalWidth
      let imageHeight = image.naturalHeight
      if (imageWidth > 320) {
        imageWidth = 320
        imageHeight = ~~(320 / (image.naturalWidth / image.naturalHeight))
      }
      let tempImage = {}
      if (style === 'GAME_ICON_PIC') {
        console.log('webp 地址: ' + data.result.url)
        tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_PIC'
        }
        gameAdd.updateIcon(style, tempImage)
        gameAdd.updateDefaultImg('icon', data.result.url)
      } else if (style === 'GAME_ICON_GIF') {
        tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_GIF'
        }
        gameAdd.updateIcon(style, tempImage)
        gameAdd.updateDefaultImg('gif', data.result.url)
        this.setState({
          gifDeleteBtn: true
        })
      } else if (style === 'GAME_ICON_BANNER') {
        tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_BANNER'
        }
        gameAdd.updateIcon(style, tempImage)
        gameAdd.updateDefaultImg('banner', data.result.url)
        this.setState({
          bannerDeleteBtn: true
        })
      }
      const newImage = this.state.images
      newImage.push(tempImage)
      this.setState({
        images: newImage
      })
    }
  }
  deleteUploadImage = type => e => {
    const { gameAdd } = this.props
    let images = this.state.images
    if (type === 'GAME_ICON_GIF') {
      gameAdd.deleteUploadGif()
      document.getElementById('uploadGif').value = ''
      this.setState({
        gifDeleteBtn: false
      })
    } else if (type === 'GAME_ICON_BANNER') {
      gameAdd.deleteUploadBanner()
      document.getElementById('uploadBanner').value = ''
      this.setState({
        bannerDeleteBtn: false
      })
    }
    images = images.filter(item => item.type !== type)
    this.setState(
      {
        images
      },
      () => {
        if (images.length <= 0) {
          this.setState({
            themePreview: false
          })
        }
      }
    )
  }

  handleDateChange = key => date => {
    const { gameAdd } = this.props
    gameAdd.updateAddInfo(key, new Date(date._d).getTime())
  }
  handSaveBtnClick = () => {
    const { gameAdd } = this.props
    gameAdd.saveSuccess().then(resolve => {
      if (resolve) {
        this.props.handleChangeSelect()
      }
    })
  }
  handCancleBtnClick = () => {
    const { gameAdd } = this.props
    this.setState({
      themePreview: false,
      bannerDeleteBtn: false,
      gifDeleteBtn: false
    })
    gameAdd.resetAddInfo()
  }
  // provider和publisher
  handleInputChange = newValue => {
    // const inputValue = newValue.replace(/\W/g, '')
    return newValue
  }
  getOptions = idParams => async inputValue => {
    if (typeof inputValue === 'object') {
      inputValue = ''
    }
    // if (inputValue === '') {
    //   return
    // }
    let res = await this.props.gameAdd.searchFilters(inputValue, idParams)
    let result = []
    if (res.data.result && res.data.status) {
      if (Array.isArray(res.data.result)) {
        res.data.result.forEach(value => {
          let obj = {}
          if (typeof value === 'object') {
            obj.value = value.pid
            obj.label = value.name
          } else {
            obj.value = value
            obj.label = value
          }
          result.push(obj)
        })
      } else {
        res.data.result.list.forEach(value => {
          let obj = {}
          obj.value = value.id
          obj.label = value.name
          result.push(obj)
        })
      }
    }
    return result || []
  }
  searchCb = (params, order) => value => {
    const { gameAdd } = this.props
    if (value.label === '') {
      return
    }
    if (params === 'tags') {
      gameAdd.updateAddInfo(order, value.label)
    } else {
      gameAdd.updateAddInfo(params, value)
    }
  }
  handleIconBarColorSwitch = () => {
    this.setState({
      themeSwitch: !this.state.themeSwitch
    })
  }
  handlePreviewSwitch = () => {
    const { gameAdd } = this.props
    if (!gameAdd.AddInfo.images.length) {
      Toast.warning('Please select icon or gif first!')
      return
    }
    this.setState({
      themePreview: !this.state.themePreview
    })
  }
  handleSelectIconBarColor = e => {
    const { gameAdd } = this.props
    if (e.target.tagName === 'LI') {
      var id = e.target.id
      this.setState({
        themeColorClass: id,
        themeSwitch: false
      })
      let themeColorValue = ''
      if (id === 'first_themeColor') {
        themeColorValue = '#ef5e38,#f08b63'
      } else if (id === 'second_themeColor') {
        themeColorValue = '#f06b2d,#f1a35d'
      } else if (id === 'third_themeColor') {
        themeColorValue = '#e69413,#eec243'
      } else if (id === 'fourth_themeColor') {
        themeColorValue = '#15be69,#2ce285'
      } else if (id === 'fifth_themeColor') {
        themeColorValue = '#59b223,#7ad353'
      } else if (id === 'sixth_themeColor') {
        themeColorValue = '#2882ec,#4eb2f8'
      } else if (id === 'seventh_themeColor') {
        themeColorValue = '#3243ff,#52a0ff'
      } else if (id === 'eighth_themeColor') {
        themeColorValue = '#9e37ff,#eb5aff'
      } else if (id === 'ninth_themeColor') {
        themeColorValue = '#682aff,#894be8'
      }
      gameAdd.updateAddInfo('themeColor', themeColorValue)
    }
  }
  renderImg = () => {
    const { images } = this.state
    return !!images && !!images.length ? (
      <img src={images[images.length - 1].url} />
    ) : null
  }

  enterTitleHandle = () => {
    this.setState({
      titleSwtch: true,
      dialogTitle: 'Enter title',
      dialogChildren: <GameEditTitle />
    })
  }

  confirmHandle = () => {
    const { gameAdd } = this.props
    if (
      gameAdd.AddInfo.title &&
      gameAdd.AddInfo.subTitle.length === 4 &&
      gameAdd.AddInfo.subTitle[0].title !== '' &&
      gameAdd.AddInfo.subTitle[1].title !== '' &&
      gameAdd.AddInfo.subTitle[2].title !== '' &&
      gameAdd.AddInfo.subTitle[3].title !== ''
    ) {
      this.setState({
        titleSwtch: false,
        dialogTitle: null,
        dialogChildren: null
      })
    } else {
      Toast.warning('You need all the input...')
    }
  }
  // 抽取下拉选择框
  // 参数分别是className、动态修改的参数、原始默认值、menuItem的list数组、
  renderSelectHandle = (classname, modifyValue, defaultValue, menuList) => {
    const { classes } = this.props
    return (
      <TextField
        variant="outlined"
        margin="none"
        select
        className={classes[classname]}
        SelectProps={{
          classes: { outlined: classes.menu },
          multiple: modifyValue === 'areas'
        }}
        onChange={
          modifyValue === 'uploadStyle'
            ? this.props.handleChangeSelect
            : this.setAddInfoHandle(modifyValue)
        }
        value={defaultValue || 0}
      >
        {modifyValue === 'tagsFirstValue' ||
          modifyValue === 'tagsSecondValue' ? (
          <MenuItem value={0}>please select...</MenuItem>
        ) : null}
        {menuList && menuList.length
          ? menuList.map(item => {
            if (
              modifyValue === 'lan' ||
              modifyValue === 'tagsFirstValue' ||
              modifyValue === 'tagsSecondValue'
            ) {
              return (
                <MenuItem key={item.id} value={item.code || item.name}>
                  {item.name}
                </MenuItem>
              )
            }
            if (typeof item === 'object') {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            } else {
              return (
                <MenuItem key={item} value={item}>
                  {' '}
                  {item}{' '}
                </MenuItem>
              )
            }
          })
          : null}
      </TextField>
    )
  }
  render() {
    const { classes, gameAdd, theme, uploadStyle } = this.props
    const {
      bannerDeleteBtn,
      gifDeleteBtn,
      titleSwtch,
      dialogChildren,
      dialogTitle
    } = this.state
    const selectStyles = theme
    return (
      <div className={classes.content}>
        <Paper className={classes.addContent} elevation={3}>
          <div className={classes.contentTitle}>
            <Typography className={classes.selectTitle}>Types of</Typography>
            {this.renderSelectHandle(
              'select',
              'uploadStyle',
              uploadStyle,
              SING_OR_BATCH
            )}
          </div>
          <div className={classes.divider} />
          <div className={classes.formContent}>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>Game Name</Typography>
              </span>
              <div className={classes.inputContent}>
                <Button
                  variant="outlined"
                  className={classes.titleBtn}
                  onClick={this.enterTitleHandle}
                >
                  {gameAdd.AddInfo.title
                    ? gameAdd.AddInfo.title
                    : 'Enter the title'}
                </Button>
              </div>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>
                  Game Image
                </Typography>
              </span>
              <span className={classes.inputContent}>
                <div className={classes.uploadImgBtn}>
                  {/* 加载显示图片 */}
                  <div>
                    <img
                      className={classes.uploadImage}
                      src={gameAdd.defaultImg.icon}
                      ref={el => (this.uploadImage = el)}
                    />
                  </div>
                  <div>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <UploadFile
                      id="uploadFile"
                      fileTypes={['image/png', 'image/jpeg', 'image/webp']}
                      maxSize={5 * 1024 * 1000}
                      uploadApi="upload/file"
                      needUploadWebp={true}
                      uploadChange={this.uploadSuccess('GAME_ICON_PIC')}
                      uploadOriginSuccess={this.uploadOriginSuccess()}
                    />
                  </div>
                </div>

                <div className={classes.uploadImgBtn}>
                  {/* 加载显示图片 */}
                  <div style={{ position: 'relative' }}>
                    {gifDeleteBtn ? (
                      <div
                        className={classes.closeBtn}
                        onClick={this.deleteUploadImage('GAME_ICON_GIF')}
                      >
                        <div>
                          <span className={classes.closeRight} />
                          <span className={classes.closeLeft} />
                        </div>
                      </div>
                    ) : null}
                    <img
                      className={classes.uploadImage}
                      src={gameAdd.defaultImg.gif}
                      ref={el => (this.uploadGif = el)}
                    />
                  </div>
                  <div>
                    <UploadFile
                      id="uploadGif"
                      fileTypes={['image/gif']}
                      maxSize={5 * 1024 * 1000}
                      uploadApi="upload/file"
                      uploadChange={this.uploadSuccess('GAME_ICON_GIF')}
                      textProp="Upload Gif"
                    />
                  </div>
                </div>
                <div className={classes.uploadImgBtn}>
                  {/* 加载显示图片 */}
                  <div style={{ position: 'relative' }}>
                    {bannerDeleteBtn ? (
                      <div
                        className={classes.closeBtn}
                        onClick={this.deleteUploadImage('GAME_ICON_BANNER')}
                      >
                        <div>
                          <span className={classes.closeRight} />
                          <span className={classes.closeLeft} />
                        </div>
                      </div>
                    ) : null}
                    <img
                      className={classes.uploadImage}
                      src={gameAdd.defaultImg.banner}
                      ref={el => (this.uploadBanner = el)}
                    />
                  </div>
                  <div>
                    <UploadFile
                      id="uploadBanner"
                      fileTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/webp',
                        'image/gif'
                      ]}
                      maxSize={5 * 1024 * 1000}
                      uploadApi="upload/file"
                      uploadChange={this.uploadSuccess('GAME_ICON_BANNER')}
                      textProp="Upload Banner"
                    />
                  </div>
                </div>
                {this.state.themePreview ? (
                  <div
                    className={classNames(
                      classes.preview,
                      classes[this.state.themeColorClass]
                    )}
                  >
                    {this.renderImg()}
                    <div>
                      <p>mint games</p>
                      <p>19930 players</p>
                    </div>
                  </div>
                ) : null}
              </span>
            </div>

            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>
                  Icon Bar Color
                </Typography>
              </span>
              <div className={classes.inputContent}>
                <Button
                  variant="outlined"
                  className={classes.iconBarColorBtn}
                  onClick={this.handleIconBarColorSwitch}
                >
                  {gameAdd.AddInfo.themeColor === '' ? (
                    'please select themeColor'
                  ) : (
                    <div>
                      <p style={{ height: 20, lineHeight: '20px' }}>
                        {gameAdd.AddInfo.themeColor}
                      </p>
                      <p
                        style={{
                          background: `linear-gradient(to right,${gameAdd.AddInfo.themeColor.slice(
                            0,
                            7
                          )},${gameAdd.AddInfo.themeColor.slice(8)})`
                        }}
                      />
                    </div>
                  )}
                </Button>
                {this.state.themeSwitch ? (
                  <ul
                    className={classes.colorItemCard}
                    onClick={e => this.handleSelectIconBarColor(e)}
                  >
                    <li
                      className={classes.first_themeColor}
                      id="first_themeColor"
                    >
                      first_themeColor
                    </li>
                    <li
                      className={classes.second_themeColor}
                      id="second_themeColor"
                    >
                      second_themeColor
                    </li>
                    <li
                      className={classes.third_themeColor}
                      id="third_themeColor"
                    >
                      third_themeColor
                    </li>
                    <li
                      className={classes.fourth_themeColor}
                      id="fourth_themeColor"
                    >
                      fourth_themeColor
                    </li>
                    <li
                      className={classes.fifth_themeColor}
                      id="fifth_themeColor"
                    >
                      fifth_themeColor
                    </li>
                    <li
                      className={classes.sixth_themeColor}
                      id="sixth_themeColor"
                    >
                      sixth_themeColor
                    </li>
                    <li
                      className={classes.seventh_themeColor}
                      id="seventh_themeColor"
                    >
                      seventh_themeColor
                    </li>
                    <li
                      className={classes.eighth_themeColor}
                      id="eighth_themeColor"
                    >
                      eighth_themeColor
                    </li>
                    <li
                      className={classes.ninth_themeColor}
                      id="ninth_themeColor"
                    >
                      ninth_themeColor
                    </li>
                  </ul>
                ) : null}
                {gameAdd.AddInfo.themeColor !== '' ? (
                  <Button variant="outlined" onClick={this.handlePreviewSwitch}>
                    preview
                  </Button>
                ) : null}
              </div>
            </div>

            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>Game URL</Typography>
              </span>
              <span className={classes.inputContent}>
                <TextField
                  margin="none"
                  variant="outlined"
                  className={classes.gameImg}
                  value={gameAdd.AddInfo.url}
                  onChange={this.setAddInfoHandle('url')}
                  type="text"
                />
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>
                  Game Online
                </Typography>
              </span>
              <span className={classes.lineSelectDate}>
                <DatePicker
                  dateFormat="YYYY-MM-DD"
                  closeOnSelect
                  onChange={this.handleDateChange('startTime')}
                  value={gameAdd.AddInfo.startTime}
                  className={classes.timeControl}
                />
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>
                  Game Offline
                </Typography>
              </span>
              <span className={classes.lineSelectDate}>
                <DatePicker
                  dateFormat="YYYY-MM-DD"
                  closeOnSelect
                  onChange={this.handleDateChange('endTime')}
                  value={gameAdd.AddInfo.endTime}
                  className={classes.timeControl}
                />
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>Provider</Typography>
              </span>
              <span className={classes.lineProviderSelect}>
                <AsyncSelect
                  // autoFocus
                  cacheOptions
                  defaultOptions
                  isMulti
                  isSearchable
                  classes={classes}
                  styles={selectStyles}
                  className={classes.providerSelect}
                  loadOptions={this.getOptions('provider')}
                  onChange={this.searchCb('providerIds')}
                />
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>
                  Choose Language
                </Typography>
              </span>
              <span className={classes.inputContent}>
                {this.renderSelectHandle(
                  'selectLanguage',
                  'lan',
                  gameAdd.AddInfo.lan,
                  gameAdd.languagesList
                )}
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <Typography className={classes.mandatory}>权重</Typography>
              </span>
              <span className={classes.weighted}>
                {/* {this.renderSelectHandle(
                  'gameSelect',
                  'boutiques',
                  gameAdd.AddInfo.boutiques,
                  BOUTUQUES_ITEMS
                )} */}
                <span className={classes.inputContent}>
                  <TextField
                    margin="none"
                    variant="outlined"
                    className={classes.gameImg}
                    value={gameAdd.AddInfo.boutiques}
                    onChange={this.setAddInfoHandle('boutiques')}
                    type="text"
                  />
                </span>
                <i className={classes.weightedSelect}>若不选，默认不加</i>
              </span>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <Typography className={classes.mandatory}>显示区域</Typography>
              </span>

              <div className={classes.gameTag}>
                <div className={classes.locationTag}>
                  {this.renderSelectHandle(
                    'gameTagSelect',
                    'areas',
                    gameAdd.AddInfo.areas,
                    AREAS
                  )}
                </div>
              </div>
            </div>
            <div className={classes.item}>
              <span className={classes.itemTitle}>
                <img
                  className={classes.startImg}
                  src={require('./../../../assets/mandatory.png')}
                />
                <Typography className={classes.mandatory}>Game Tags</Typography>
              </span>

              <div className={classes.gameTag}>
                {['ru', 'es'].findIndex(
                  item => item === authInfo.region.code.toLocaleLowerCase()
                ) > -1 ? (
                  <div className={classes.selectTag}>
                    {this.renderSelectHandle(
                      'gameTagSelect',
                      'tagsFirstValue',
                      gameAdd.AddInfo.tagsFirstValue,
                      gameAdd.tagList
                    )}
                    {this.renderSelectHandle(
                      'gameTagSelect',
                      'tagsSecondValue',
                      gameAdd.AddInfo.tagsSecondValue,
                      gameAdd.tagList
                    )}
                  </div>
                ) : (
                  <div className={classes.selectTag}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isSearchable
                      defaultValue={gameAdd.AddInfo.tags.map(d => ({
                        label: d,
                        value: d
                      }))}
                      classes={classes}
                      styles={selectStyles}
                      className={classes.gameTagSelect}
                      loadOptions={this.getOptions('tag')}
                      onChange={this.searchCb('tags', 'tagsFirstValue')}
                    />
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isSearchable
                      classes={classes}
                      styles={selectStyles}
                      className={classes.gameTagSelect}
                      loadOptions={this.getOptions('tag')}
                      onChange={this.searchCb('tags', 'tagsSecondValue')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.btnArea}>
            <Button variant="contained" onClick={this.handCancleBtnClick}>
              取消
            </Button>
            <Button variant="contained" onClick={this.handSaveBtnClick}>
              保存
            </Button>
          </div>
        </Paper>
        {titleSwtch ? (
          <AlertDialog
            open={titleSwtch}
            content={''}
            title={dialogTitle}
            children={dialogChildren}
            handleClose={() => {
              gameAdd.updateAddInfo('title', '')
              gameAdd.updateAddInfo('subTitle', [])
              this.setState({
                titleSwtch: false,
                dialogTitle: null,
                dialogChildren: null
              })
            }}
            handleConfirm={this.confirmHandle}
          />
        ) : null}
      </div>
    )
  }
}

GameBatchUp.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(withRouter(GameBatchUp))

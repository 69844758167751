import { observable, action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import authInfo from './../authInfo'
import ContentManage from './../ContentManage/ContentManage'

class CategoryManageAPI {
  // 获取列表
  fetchRows = data =>
    fetchData({
      url: `category/list`,
      method: 'GET',
      data: data
    })

  addCategory = data =>
    fetchData({
      url: `category/add`,
      method: 'POST',
      data: data
    })

  editCategory = data =>
    fetchData({
      url: `category/edit`,
      method: 'POST',
      data: data
    })

  deleteCategory = data =>
    fetchData({
      url: `category/delete`,
      method: 'POST',
      data: data
    })
}

class CategoryManage {
  @observable
  rows = []
  @observable
  pageSize = 10
  @observable
  pageNum = 1
  @observable
  sum = 0

  constructor (api) {
    this.categoryApi = api
  }
  @action
  resetPage = () => {
    this.pageNum = 1
  }
  @action
  updatePage = pageNum => {
    if (this.pageNum === pageNum || isNaN(pageNum)) {
      return
    }
    this.pageNum = pageNum
  }
  @action
  fetchRows = async pageNum => {
    if (pageNum) {
      this.pageNum = pageNum
    }
    let options = {
      region: authInfo.region.code,
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      type: ContentManage.contentType.toUpperCase()
    }
    let res = await this.categoryApi.fetchRows(options)
    if (!!res.data && res.data.status && res.data.result) {
      this.rows = res.data.result.list
      this.sum = res.data.result.count
    }
    if (!!res.data && res.data.status && !res.data.result) {
      this.rows = []
      this.sum = 0
    }
  }
  @action
  addCategory = data => {
    var options = {
      name: data.name,
      region: authInfo.region.code,
      type: ContentManage.contentType.toUpperCase()
    }
    return this.categoryApi.addCategory(options)
  }
  @action
  editCategory = data => {
    var options = {
      id: data.id,
      name: data.name,
      region: authInfo.region.code,
      type: data.type
    }
    return this.categoryApi.editCategory(options)
  }
  @action
  deleteCategory = data => {
    return this.categoryApi.deleteCategory(data)
  }
}

const categoryManageAPI = new CategoryManageAPI()
const categoryManage = new CategoryManage(categoryManageAPI)

export default categoryManage

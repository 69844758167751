import { fetchData } from '@src/utils/tools'
import contentManage from '../ContentManage/ContentManage'
import authInfo from '../authInfo'

let timer = null

const commonApi = {
  searchFilters: data => {
    if (timer) {
      clearTimeout(timer)
    }
    function sendRequest () {
      if (data.type === 'source') {
        return fetchData({
          url:
            `${data.type}/match?name=${data.name}` +
            `&region=${
              authInfo.region.code
            }&type=${contentManage.contentType.toUpperCase()}` +
            `&pn=1&ps=50`,
          method: 'GET'
        })
      }
      if (data.type === 'tag') {
        return fetchData({
          url:
            `${data.type}/edit/list?name=${data.name}` +
            `&type=${contentManage.contentType.toUpperCase()}&region=${
              authInfo.region.code
            }` +
            `&pn=1&ps=50`,
          method: 'GET'
        })
      }
      return fetchData({
        url:
          `${data.type}/match?name=${data.name}` +
          `&region=${
            authInfo.region.code
          }&type=${contentManage.contentType.toUpperCase()}` +
          `&pn=1&ps=50`,
        method: 'GET'
      })
    }
    return new Promise((resolve, reject) => {
      timer = setTimeout(() => {
        timer = null
        resolve(sendRequest())
      }, 300)
    })
  }
}

export default commonApi

import { observable, action, computed } from 'mobx'
import { fetchData, validateBetweenFilter } from '@src/utils/tools'
import newsFilterStore from './NewsFilter'
import contentManage from './ContentManage'
import authInfo from '../authInfo'
import Toast from '@src/components/Toast'
import { intl } from '@src/IntlGlobalProvider'
import { encodeQuery } from '@src/utils/url'

const OPERATION_TARGET_TYPES = {
  all: 1,
  notAll: 0
}
class NewsManageAPI {
  getNewsList = data =>
    fetchData({
      url: `${contentManage.contentType.toLowerCase()}/list`,
      method: 'POST',
      data: data
    })

  offlineNews = (docId, reason) =>
    fetchData({
      url: 'doc/offline',
      data: {
        docId: docId,
        reason: reason,
        type: contentManage.contentType.toUpperCase()
      },
      method: 'GET'
    })

  onlineNews = docId =>
    fetchData({
      url: 'doc/online',
      data: {
        docId: docId,
        type: contentManage.contentType.toUpperCase()
      },
      method: 'GET'
    })

  editNews = data =>
    fetchData({
      url: `${contentManage.contentType.toLowerCase()}/edit`,
      data: data,
      method: 'POST'
    })

  getNewsDetail = docId =>
    fetchData({
      url: `${contentManage.contentType.toLowerCase()}/info`,
      data: {
        docid: docId
      },
      method: 'GET'
    })

  batchUpdateStateNews = ({ type, data, param }) => {
    return fetchData({
      url: `doc/batch/${type}?${encodeQuery(param)}`,
      data,
      method: 'POST'
    })
  }
}

class NewsManage {
  constructor (api) {
    this.newsApi = api
  }

  @observable
  news = []
  @observable
  pageSize = 40
  @observable
  pageNum = 1
  @observable
  sum = 0
  @observable
  // 控制按钮组
  speedyBtnGroup = false
  @observable
  // 显示被选中的总数
  selectCount = 0
  @computed
  get selectedCount () {
    return this.news.filter(item => item.checked).length
  }

  @action
  updateStatus = (...reset) => {
    const [type, value] = [...reset]
    this[type] = value
  }

  @action
  toggleChecked (index, checked) {
    const newItem = this.news[index]
    newItem.checked = checked
  }
  @action
  selectCurrPage () {
    this.news.forEach(item => {
      item.checked = true
    })
  }

  @action
  paramsFn = () => {
    let searchOptions = Object.assign(
      {
        region: authInfo.region.code,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      },
      newsFilterStore.filters
    )

    if (searchOptions.language === 'all') {
      searchOptions.language = ''
    }
    if (searchOptions.language === 'others') {
      newsFilterStore.languages.forEach((item, index) => {
        if (index > 1) {
          searchOptions.lans.push(item.value)
        }
      })
    } else {
      searchOptions.lans = []
    }
    searchOptions.storeTimeStart =
      searchOptions.storeTimeStart === null ? 0 : searchOptions.storeTimeStart
    searchOptions.storeTimeEnd =
      searchOptions.storeTimeEnd === null ? 0 : searchOptions.storeTimeEnd
    searchOptions.publishTimeStart =
      searchOptions.publishTimeStart === null
        ? 0
        : searchOptions.publishTimeStart
    searchOptions.publishTimeEnd =
      searchOptions.publishTimeEnd === null ? 0 : searchOptions.publishTimeEnd

    return searchOptions
  }

  @action
  getNewsList = async (pageNum, pageSize) => {
    if (pageNum && pageNum !== this.pageNum) {
      this.pageNum = pageNum
    }
    if (pageSize && pageSize !== this.pageSize) {
      this.pageSize = pageSize
    }

    let searchOptions = this.paramsFn()
    if (!searchOptions) {
      return
    }

    const waitCheckFilters = [
      {
        front: 'publishTimeStart',
        behind: 'publishTimeEnd',
        frontValue: searchOptions.publishTimeStart,
        behindValue: searchOptions.publishTimeEnd
      },
      {
        front: 'minCpClick',
        behind: 'maxCpClick',
        frontValue: searchOptions.minCpClick,
        behindValue: searchOptions.maxCpClick
      },
      {
        front: 'minViews',
        behind: 'maxViews',
        frontValue: searchOptions.minViews,
        behindValue: searchOptions.maxViews
      }
    ]

    let validateResult = validateBetweenFilter(waitCheckFilters)

    if (validateResult) {
      Toast.error(
        intl.formatMessage(
          { id: validateResult.type },
          {
            front: validateResult.front,
            behind: validateResult.behind
          }
        )
      )

      return
    }
    let result
    try {
      result = await this.newsApi.getNewsList(searchOptions)
      console.log('news=>', result)
      if (result.data && result.data.status) {
        this.news = []
        this.news = result.data.result.list || []
        this.sum = result.data.result.count || 0
      } else {
        this.news = []
        this.sum = 0
      }
    } catch (e) {
      this.news = []
      this.sum = 0
    }
  }

  @action
  offlineNews = (docId, reason) => {
    return this.newsApi.offlineNews(docId, reason)
  }

  @action
  onlineNews = docId => {
    return this.newsApi.onlineNews(docId)
  }

  @action
  editNews = data => {
    return this.newsApi.editNews(data)
  }

  @action
  getNewsDetail = docId => {
    return this.newsApi.getNewsDetail(docId)
  }

  @action
  resetPageNum = () => {
    this.pageNum = 1
  }

  @action
  batchStateNews = params => {
    const { type, reason } = params
    let data = this.paramsFn()
    let param = {}
    param = {
      type: 'NEWS',
      reason: ''
      // ,pornDegree: null,
      // all: null
    }
    data.docIds = this.news.filter(item => item.checked).map(item => item.docId)
    Object.assign(param, {
      all: OPERATION_TARGET_TYPES.notAll
    })

    // 批量上线与下线
    if (type === 'online' || type === 'offline') {
      param.reason = reason
      // if (type === 'offline') {
      //   param.pornDegree = params.pornDegree
      // }
      return this.newsApi.batchUpdateStateNews({
        data,
        type,
        param
      })
    }
  }
}

const newsManageAPI = new NewsManageAPI()
const newsManage = new NewsManage(newsManageAPI)

export default newsManage

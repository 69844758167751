import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Paper,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import DatePicker from 'react-datetime'
import UploadFile from '@src/components/UploadFile'
import AsyncSelect from 'react-select/lib/Async'
import Toast from '@src/components/Toast'
import GameEditTitle from './../GameAdd/GameEditTitle'
import AlertDialog from '@src/components/AlertDialog'
import classNames from 'classnames'

const styles = theme => ({
  content: {
    background: '#fff'
  },
  addContent: {
    padding: theme.spacing.unit * 3,
    width: '98%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '1%'
  },
  contentTitle: {
    width: '40%'
  },
  selectTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  select: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  divider: {
    width: '90%',
    height: '2px',
    border: '2px solid #ccc',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  formContent: {
    width: '98%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  item: {
    width: '80%',
    marginBottom: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center'
  },
  itemTitle: {
    width: '20%',
    textAlign: 'right',
    marginRight: '30px'
  },
  mandatory: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'inline-block'
  },
  inputContent: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  titleBtn: {
    width: '300px',
    marginRight: '10px',
    backgroundColor: '#eee',
    textTransform: 'initial'
  },
  textField: {
    height: 40
  },
  gameImg: {
    width: '50%',
    '@global': {
      'div:first-child': {
        '@global': {
          input: {
            padding: '11px 14px'
          }
        }
      }
    }
  },
  gameUrl: {
    width: '100%',
    '@global': {
      'div:first-child': {
        '@global': {
          input: {
            padding: '11px 14px'
          }
        }
      }
    }
  },
  lineSelectDate: {
    width: '250px',
    marginLeft: '-6px',
    '& input': {
      paddingLeft: '10px'
    },
    '@global': {
      'div:first-child': {
        '& > .rdtPicker': {
          left: 'auto !important'
        }
      }
    }
  },
  lineSelectProvider: {
    width: '80%'
  },
  gameSelect: {
    width: '30%'
  },
  providerSelect: {
    width: '30%'
  },
  gameTagSelect: {
    width: '30%',
    marginRight: theme.spacing.unit * 2
  },
  menu: {
    padding: '11px 8px'
  },
  weighted: {
    width: '80%',
    display: 'flex',
    alignItems: 'center'
  },
  weightedSelect: {
    marginLeft: 10,
    fontSize: 12
  },
  gameTag: {
    width: '80%'
  },
  locationTag: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit
  },
  btnArea: {
    width: '90%',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'space-around'
  },
  uploadImgBtn: {
    marginRight: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '@global': {
      div: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  },
  closeBtn: {
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '& > div': {
      width: '14px',
      height: '14px',
      position: 'relative'
    }
  },
  closeRight: {
    position: 'absolute',
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(45deg)',
    '-webkit-transform': 'rotate(45deg)'
  },
  closeLeft: {
    width: '14px',
    height: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(-45deg)',
    '-webkit-transform': 'rotate(-45deg)'
  },
  uploadImage: {
    width: 80,
    height: 80
  },
  iconBarColorBtn: {
    width: '250px',
    backgroundColor: 'aliceblue',
    marginRight: '10px',
    '& + ul': {
      width: 250,
      height: 250,
      backgroundColor: '#ffffff',
      position: 'absolute',
      top: '38px',
      left: '0',
      zIndex: 9,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > li': {
        width: 80,
        height: 80,
        borderRadius: 10,
        cursor: 'pointer',
        textIndent: '-999px'
      }
    }
  },
  first_themeColor: {
    background: 'linear-gradient(to right,#ef5e38,#f08b63)'
  },
  second_themeColor: {
    background: 'linear-gradient(to right,#f06b2d,#f1a35d)'
  },
  third_themeColor: {
    background: 'linear-gradient(to right,#e69413,#eec243)'
  },
  fourth_themeColor: {
    background: 'linear-gradient(to right,#15be69,#2ce285)'
  },
  fifth_themeColor: {
    background: 'linear-gradient(to right,#59b223,#7ad353)'
  },
  sixth_themeColor: {
    background: 'linear-gradient(to right,#2882ec,#4eb2f8)'
  },
  seventh_themeColor: {
    background: 'linear-gradient(to right,#3243ff,#52a0ff)'
  },
  eighth_themeColor: {
    background: 'linear-gradient(to right,#9e37ff,#eb5aff)'
  },
  ninth_themeColor: {
    background: 'linear-gradient(to right,#682aff,#894be8)'
  },
  preview: {
    width: 90,
    height: 130,
    borderRadius: 10,
    '& > img': {
      width: 90,
      height: 90,
      borderRadius: 10
    },
    '& > div': {
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 3
    }
  }
})
const TAG_TYPE = [
  'history_',
  'must play_',
  'hot games_',
  'game card_',
  'new game_',
  'app vault_'
]

const AREAS = [
  { value: 'top_picks', label: 'TOP PICKS' },
  { value: 'new_games', label: 'NEW GAMES' },
  { value: 'favorite_recommend', label: 'FAVORITE RECOMMEND' },
  { value: 'recent_recommend', label: 'RECENT RECOMMEND' },
  { value: 'detail_recommend', label: 'DETAIL RECOMMEND' },
  { value: 'mobile_all', label: 'MOBILE ALL' }
]

@inject('gameAdd', 'authInfo')
@observer
class GameBatchUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.EditDatail.title,
      subTitle: props.EditDatail.subTitle || [],
      srcImg: props.EditDatail.images[0].url,
      url: props.EditDatail.url,
      startTime: props.EditDatail.startTime,
      endTime: props.EditDatail.endTime,
      style: props.EditDatail.style,
      boutiques: props.EditDatail.boutiques,
      providerIds: props.EditDatail.provider,
      sourceId: props.EditDatail.source,
      tags: props.EditDatail.tags,
      images: props.EditDatail.images,
      originalImages: props.EditDatail.originalImages || [],
      areas: props.EditDatail.areas,
      tagsFirstValue: '',
      tagsSecondValue: '',
      icon: '' || require('./../../../assets/default.png'),
      gif: '' || require('./../../../assets/default.png'),
      banner: '' || require('./../../../assets/default.png'),
      themeSwitch: false,
      themeColorClass: '',
      themeColor: props.EditDatail.themeColor || '',
      themePreview: false,
      gifDeleteBtn: false,
      bannerDeleteBtn: false,
      titleSwtch: false,
      dialogChildren: null,
      dialogTitle: ''
    }
  }
  componentDidMount() {
    const { gameAdd } = this.props
    const { tags } = this.state
    if (
      ['ru', 'es'].findIndex(
        item => item === this.props.authInfo.region.code.toLocaleLowerCase()
      ) > -1
    ) {
      let firstValue = ''
      let secondValue = ''
      tags.forEach(item => {
        if (TAG_TYPE.findIndex(age => age === item) < 0) {
          if (firstValue === '') {
            firstValue = item
            return
          }
          if (firstValue && secondValue === '') {
            secondValue = item
          }
        }
      })
      this.setState({
        tagsFirstValue: firstValue,
        tagsSecondValue: secondValue
      })
      gameAdd.getTagList()
    }
    this.updateImg()
  }

  updateImg = () => {
    const { images } = this.state
    images.forEach(item => {
      switch (item.type) {
        case 'GAME_ICON_PIC': {
          this.setState({
            icon: item.url
          })
          break
        }
        case 'GAME_ICON_GIF': {
          this.setState({
            gif: item.url,
            gifDeleteBtn: true
          })
          break
        }
        case 'GAME_ICON_BANNER': {
          this.setState({
            banner: item.url,
            bannerDeleteBtn: true
          })
          break
        }
      }
    })
  }

  setAddInfoHandle = key => event => {
    this.setState({
      [key]: event.target.value
    })
  }
  // origin 地址
  uploadOriginSuccess = () => data => {
    const { originalImages } = this.state
    if (originalImages) {
      let image = new Image()
      image.src = data.result.url
      image.onload = () => {
        let imageWidth = image.naturalWidth
        let imageHeight = image.naturalHeight
        let tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_PIC'
        }
        const imagesL = originalImages.filter(item => item.type !== 'GAME_ICON_PIC')
        this.setState({
          originalImages: [...imagesL, tempImage]
        })
      }
    } else {
      let image = new Image()
      image.src = data.result.url
      image.onload = () => {
        let imageWidth = image.naturalWidth
        let imageHeight = image.naturalHeight
        let tempImage = {
          url: data.result.url,
          width: imageWidth,
          height: imageHeight,
          type: 'GAME_ICON_PIC'
        }
        this.setState({
          originalImages: [tempImage]
        })
      }
    }
  }
  // 当加载图片成功的时候，修改默认的图片，显示正确的图片
  uploadSuccess = type => data => {
    const { images, originalImages } = this.state
    let image = new Image()
    image.src = data.result.url
    if (type === 'GAME_ICON_PIC') {
      console.log('新webp 地址: ' + data.result.url)
    }
    image.onload = () => {
      let imageWidth = image.naturalWidth
      let imageHeight = image.naturalHeight
      if (imageWidth > 320) {
        imageWidth = 320
        imageHeight = ~~(320 / (image.naturalWidth / image.naturalHeight))
      }
      let tempImage = {
        url: data.result.url,
        width: imageWidth,
        height: imageHeight,
        type
      }
      const imagesL = images.filter(item => item.type !== type)
      images.forEach(item => {
        if (item.type === 'GAME_ICON_BANNER') {
          this.setState({
            bannerDeleteBtn: true
          })
        }
        if (item.type === 'GAME_ICON_GIF') {
          this.setState({
            gifDeleteBtn: true
          })
        }
      })
      this.setState(
        {
          images: [...imagesL, tempImage]
        },
        () => {
          this.updateImg()
        }
      )
      // 处理 originImages

      if (originalImages) {
        const imagesL = originalImages.filter(item => item.type !== type)
        this.setState({
          originalImages: [...imagesL, tempImage]
        })
      } else {
        this.setState({
          originalImages: [tempImage]
        })
      }
    }
  }
  // deleteUploadGif = () => {
  //   const { icon, images } = this.state
  //   if (icon === '' || icon === require('./../../../assets/default.png')) {
  //     this.setState({
  //       themePreview: false
  //     })
  //   }
  //   let imagesL = []
  //   imagesL.push(images[0])
  //   this.setState({
  //     gifDeleteBtn: false,
  //     gif: require('./../../../assets/default.png'),
  //     images: imagesL
  //   })
  //   document.getElementById('uploadGif').value = ''
  // }
  deleteUploadImage = type => {
    const { images } = this.state
    const imagesL = images.filter(item => item.type !== type)
    if (!!imagesL && imagesL.length <= 0) {
      this.setState({
        themePreview: false
      })
    }
    switch (type) {
      case 'GAME_ICON_BANNER': {
        this.setState({
          bannerDeleteBtn: false,
          banner: require('./../../../assets/default.png'),
          images: imagesL
        })
        document.getElementById('uploadBanner').value = ''
        break
      }
      case 'GAME_ICON_GIF': {
        this.setState({
          gifDeleteBtn: false,
          gif: require('./../../../assets/default.png'),
          images: imagesL
        })
        document.getElementById('uploadGif').value = ''
        break
      }
      default:
        break
    }
  }
  handleDateChange = key => date => {
    this.setState({
      [key]: new Date(date._d).getTime()
    })
  }
  handSaveBtnClick = async () => {
    const {
      title,
      subTitle,
      desc,
      url,
      startTime,
      endTime,
      style,
      boutiques,
      tags,
      providerIds,
      images,
      originalImages,
      tagsFirstValue,
      tagsSecondValue,
      areas,
      themeColor
    } = this.state
    const { EditDatail, gameAdd, handleClose, updateList } = this.props
    let newOptions = {}
    newOptions.title = title
    newOptions.subTitle = subTitle
    newOptions.desc = desc
    newOptions.url = url
    newOptions.startTime = startTime
    newOptions.endTime = endTime
    newOptions.style = style
    newOptions.boutiques = boutiques
    newOptions.images = images
    newOptions.originalImages = originalImages
    newOptions.themeColor = themeColor
    newOptions.areas = areas

    let tagsArra = []
    if (
      ['ru', 'es'].findIndex(
        item => item === this.props.authInfo.region.code.toLocaleLowerCase()
      ) > -1
    ) {
      if (!tagsFirstValue && !!tagsSecondValue) {
        Toast.warning('请按照顺序选择标签')
        return
      }
      if (
        !!tagsFirstValue &&
        !!tagsSecondValue &&
        tagsFirstValue === tagsSecondValue
      ) {
        Toast.warning('选择的标签应该不一样')
        return
      }
      if (tagsFirstValue) {
        tagsArra.push(tagsFirstValue)
      }
      if (tagsSecondValue) {
        tagsArra.push(tagsSecondValue)
      }
    } else {
      tags.forEach(value => {
        if (TAG_TYPE.findIndex(age => age === value) < 0) {
          if (typeof value === 'object') {
            tagsArra.push(value.label)
          } else {
            tagsArra.push(value)
          }
        }
      })
    }
    if (tagsArra.length === 2 && tagsArra[0] === tagsArra[1]) {
      Toast.warning('选择的标签应该不一样')
      return
    }
    // if (tagsArra.length > 2) {
    //   Toast.warning('标签最少一个，最多两个')
    //   return
    // }
    if (tagsArra.length === 0) {
      Toast.warning('标签最少一个')
      return
    }

    // if (tagsArra.length === 0 || tagsArra.length > 2) {
    //   Toast.warning('标签最多有两个普通标签')
    //   return
    // }

    newOptions.tags = tagsArra
    if (providerIds.hasOwnProperty('_origin')) {
      newOptions.provider = providerIds._origin
    } else {
      newOptions.provider = providerIds
    }
    // if (newOptions.images.length !== 0 && !newOptions.images[0].type) {
    //   newOptions.images[0].type = 'GAME_ICON_PIC'
    // }
    if (newOptions.images.length !== 0) {
      if (newOptions.images.length >= 1) {
        var temp = {}
        newOptions.images.forEach((item, index) => {
          if (item.type === 'GAME_ICON_GIF') {
            temp = item
            newOptions.images.splice(index, 1)
            newOptions.images.unshift(temp)
          }
        })
      } else if (!newOptions.images[0].type) {
        newOptions.images[0].type = 'GAME_ICON_PIC'
      }
    }
    let searchOptions = Object.assign(EditDatail, newOptions)
    for (let prop in searchOptions) {
      if (searchOptions[prop] === '') {
        Toast.warning('请填写完必填信息')
        return
      }
    }
    // 地址检测

    // 事件检测
    if (searchOptions.endTime < searchOptions.startTime) {
      Toast.warning('发布时间必须在下线时间之前')
      return
    }
    let result = await gameAdd.saveEdit(searchOptions)
    if (result.data) {
      if (result.data.status && result.data.result) {
        Toast.success('更新成功')
        handleClose()
        updateList()
      } else {
        Toast.error('更新失败')
      }
    } else {
      Toast.error('更新失败')
    }
  }

  getOptions = idParams => async inputValue => {
    if (typeof inputValue === 'object') {
      inputValue = ''
    }
    if (inputValue === '' && idParams !== 'tag' && idParams !== 'provider') {
      return
    }
    let res = await this.props.gameAdd.searchFilters(inputValue, idParams)
    let result = []
    if (res.data.result && res.data.status) {
      if (Array.isArray(res.data.result)) {
        res.data.result.forEach(value => {
          let obj = {}
          if (typeof value === 'object') {
            obj.value = value.id
            obj.label = value.name
            obj._origin = value
          } else {
            obj.value = value
            obj.label = value
          }
          result.push(obj)
        })
      } else {
        res.data.result.list.forEach(value => {
          let obj = {}
          obj.value = value.id
          obj.label = value.name
          result.push(obj)
        })
      }
    }
    return result || []
  }
  searchCb = params => value => {
    this.setState({
      [params]: value
    })
  }
  handleIconBarColorSwitch = () => {
    this.setState({
      themeSwitch: !this.state.themeSwitch
    })
  }
  handlePreviewSwitch = () => {
    if (!this.props.EditDatail.images.length) {
      Toast.warning('Please select icon first!')
      return
    }
    this.setState({
      themePreview: !this.state.themePreview
    })
  }

  handleSelectIconBarColor = e => {
    if (e.target.tagName === 'LI') {
      let themeColorValue = ''
      if (e.target.innerText === 'first_themeColor') {
        themeColorValue = '#ef5e38,#f08b63'
      } else if (e.target.innerText === 'second_themeColor') {
        themeColorValue = '#f06b2d,#f1a35d'
      } else if (e.target.innerText === 'third_themeColor') {
        themeColorValue = '#e69413,#eec243'
      } else if (e.target.innerText === 'fourth_themeColor') {
        themeColorValue = '#15be69,#2ce285'
      } else if (e.target.innerText === 'fifth_themeColor') {
        themeColorValue = '#59b223,#7ad353'
      } else if (e.target.innerText === 'sixth_themeColor') {
        themeColorValue = '#2882ec,#4eb2f8'
      } else if (e.target.innerText === 'seventh_themeColor') {
        themeColorValue = '#3243ff,#52a0ff'
      } else if (e.target.innerText === 'eighth_themeColor') {
        themeColorValue = '#9e37ff,#eb5aff'
      } else if (e.target.innerText === 'ninth_themeColor') {
        themeColorValue = '#682aff,#894be8'
      }
      this.setState({
        themeColorClass: e.target.innerText,
        themeSwitch: false,
        themeColor: themeColorValue
      })
    }
  }
  renderImg = () => {
    const { images } = this.state
    return !!images && !!images.length ? (
      <img src={images[images.length - 1].url} />
    ) : null
  }
  enterTitleHandle = () => {
    const { subTitle, title } = this.state
    const { gameAdd } = this.props
    gameAdd.updateAddInfo('title', title)
    gameAdd.updateAddInfo('subTitle', subTitle)
    this.setState({
      titleSwtch: true,
      dialogTitle: 'Enter title',
      dialogChildren: <GameEditTitle />
    })
  }
  confirmHandle = () => {
    const { gameAdd } = this.props
    console.log('hhh', gameAdd.AddInfo.subTitle)
    if (
      gameAdd.AddInfo.title &&
      gameAdd.AddInfo.subTitle.length === 4 &&
      gameAdd.AddInfo.subTitle[0].title !== '' &&
      gameAdd.AddInfo.subTitle[1].title !== '' &&
      gameAdd.AddInfo.subTitle[2].title !== '' &&
      gameAdd.AddInfo.subTitle[3].title !== ''
    ) {
      this.setState(
        {
          titleSwtch: false,
          title: gameAdd.AddInfo.title,
          subTitle: gameAdd.AddInfo.subTitle
        },
        () => {
          gameAdd.updateAddInfo('title', '')
          gameAdd.updateAddInfo('subTitle', [])
        }
      )
    } else {
      Toast.warning('You need all the input...')
    }
  }
  render() {
    const { classes, theme, open, handleClose, authInfo, gameAdd } = this.props
    const {
      title,
      url,
      startTime,
      endTime,
      boutiques,
      providerIds,
      tags,
      tagsFirstValue,
      tagsSecondValue,
      areas,
      icon,
      gif,
      banner,
      themeColor,
      themeSwitch,
      themeColorClass,
      themePreview,
      gifDeleteBtn,
      titleSwtch,
      dialogChildren,
      dialogTitle,
      bannerDeleteBtn
    } = this.state
    const selectStyles = theme
    return (
      <Dialog open={open} fullWidth={true} maxWidth="lg" scroll="paper">
        <DialogTitle id="alert-dialog-title">Game Edit page</DialogTitle>
        <DialogContent className={classes.content}>
          <div>
            <Paper className={classes.addContent} elevation={3}>
              <div className={classes.formContent}>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Game Name
                    </Typography>
                  </span>
                  <div className={classes.inputContent}>
                    <Button
                      variant="outlined"
                      className={classes.titleBtn}
                      onClick={this.enterTitleHandle}
                    >
                      {title || 'Enter the title'}
                    </Button>
                  </div>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Game Image
                    </Typography>
                  </span>
                  <span className={classes.inputContent}>
                    <div className={classes.uploadImgBtn}>
                      {/* 加载显示图片 */}
                      <div>
                        <img
                          className={classes.uploadImage}
                          src={icon}
                          ref={el => (this.uploadImage = el)}
                        />
                      </div>
                      <div>
                        <img
                          className={classes.startImg}
                          src={require('./../../../assets/mandatory.png')}
                        />
                        <UploadFile
                          id="uploadFile"
                          fileTypes={['image/png', 'image/jpeg', 'image/webp']}
                          maxSize={5 * 1024 * 1000}
                          uploadApi="upload/file"
                          needUploadWebp={true}
                          uploadChange={this.uploadSuccess('GAME_ICON_PIC')}
                          uploadOriginSuccess={this.uploadOriginSuccess()}
                        />
                      </div>
                    </div>

                    <div className={classes.uploadImgBtn}>
                      {/* 加载显示图片 */}
                      <div style={{ position: 'relative' }}>
                        {gifDeleteBtn ? (
                          <div
                            className={classes.closeBtn}
                            onClick={() =>
                              this.deleteUploadImage('GAME_ICON_GIF')
                            }
                          >
                            <div>
                              <span className={classes.closeRight} />
                              <span className={classes.closeLeft} />
                            </div>
                          </div>
                        ) : null}
                        <img
                          className={classes.uploadImage}
                          src={gif}
                          ref={el => (this.uploadGif = el)}
                        />
                      </div>
                      <div>
                        <UploadFile
                          id="uploadGif"
                          fileTypes={['image/gif']}
                          maxSize={5 * 1024 * 1000}
                          uploadApi="upload/file"
                          uploadChange={this.uploadSuccess('GAME_ICON_GIF')}
                          textProp="Upload Gif"
                        />
                      </div>
                    </div>
                    <div className={classes.uploadImgBtn}>
                      {/* 加载显示图片 */}
                      <div style={{ position: 'relative' }}>
                        {bannerDeleteBtn ? (
                          <div
                            className={classes.closeBtn}
                            onClick={() =>
                              this.deleteUploadImage('GAME_ICON_BANNER')
                            }
                          >
                            <div>
                              <span className={classes.closeRight} />
                              <span className={classes.closeLeft} />
                            </div>
                          </div>
                        ) : null}
                        <img
                          className={classes.uploadImage}
                          src={banner}
                          ref={el => (this.uploadBanner = el)}
                        />
                      </div>
                      <div>
                        <UploadFile
                          id="uploadBanner"
                          fileTypes={[
                            'image/png',
                            'image/jpeg',
                            'image/webp',
                            'image/gif'
                          ]}
                          maxSize={5 * 1024 * 1000}
                          uploadApi="upload/file"
                          uploadChange={this.uploadSuccess('GAME_ICON_BANNER')}
                          textProp="Upload Banner"
                        />
                      </div>
                    </div>
                    {themePreview & (themeColorClass !== '') ? (
                      <div
                        className={classNames(
                          classes.preview,
                          classes[themeColorClass]
                        )}
                      >
                        {this.renderImg()}
                        <div>
                          <p>mint games</p>
                          <p>19930 playes</p>
                        </div>
                      </div>
                    ) : null}
                    {themePreview & (themeColorClass === '') ? (
                      <div
                        className={classes.preview}
                        style={{
                          background: `linear-gradient(to right,${themeColor.slice(
                            0,
                            7
                          )},${themeColor.slice(8)})`
                        }}
                      >
                        {this.renderImg()}
                        <div>
                          <p>mint games</p>
                          <p>19930 playes</p>
                        </div>
                      </div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Icon Bar Color
                    </Typography>
                  </span>
                  <div className={classes.inputContent}>
                    <Button
                      variant="outlined"
                      className={classes.iconBarColorBtn}
                      onClick={this.handleIconBarColorSwitch}
                    >
                      {themeColor === '' ? (
                        'please select themeColor'
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <p style={{ height: 20, lineHeight: '20px' }}>
                            {themeColor}
                          </p>
                          <p
                            style={{
                              width: '80px',
                              height: '20px',
                              marginLeft: '15px',
                              background: `linear-gradient(to right,${themeColor.slice(
                                0,
                                7
                              )},${themeColor.slice(8)})`
                            }}
                          />
                        </div>
                      )}
                    </Button>
                    {themeSwitch ? (
                      <ul onClick={e => this.handleSelectIconBarColor(e)}>
                        <li className={classes.first_themeColor}>
                          first_themeColor
                        </li>
                        <li className={classes.second_themeColor}>
                          second_themeColor
                        </li>
                        <li className={classes.third_themeColor}>
                          third_themeColor
                        </li>
                        <li className={classes.fourth_themeColor}>
                          fourth_themeColor
                        </li>
                        <li className={classes.fifth_themeColor}>
                          fifth_themeColor
                        </li>
                        <li className={classes.sixth_themeColor}>
                          sixth_themeColor
                        </li>
                        <li className={classes.seventh_themeColor}>
                          seventh_themeColor
                        </li>
                        <li className={classes.eighth_themeColor}>
                          eighth_themeColor
                        </li>
                        <li className={classes.ninth_themeColor}>
                          ninth_themeColor
                        </li>
                      </ul>
                    ) : null}
                    {themeColor !== '' ? (
                      <Button
                        variant="outlined"
                        onClick={this.handlePreviewSwitch}
                      >
                        preview
                      </Button>
                    ) : null}
                  </div>
                </div>

                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Game URL
                    </Typography>
                  </span>
                  <span className={classes.inputContent}>
                    <TextField
                      margin="none"
                      variant="outlined"
                      className={classes.gameUrl}
                      value={url}
                      onChange={this.setAddInfoHandle('url')}
                      type="text"
                    />
                  </span>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Game Online
                    </Typography>
                  </span>
                  <span className={classes.lineSelectDate}>
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      onChange={this.handleDateChange('startTime')}
                      value={startTime}
                    />
                  </span>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Game Offline
                    </Typography>
                  </span>
                  <span className={classes.lineSelectDate}>
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      onChange={this.handleDateChange('endTime')}
                      value={endTime}
                    />
                  </span>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      Provider
                    </Typography>
                  </span>
                  <span className={classes.lineSelectProvider}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isSearchable
                      defaultValue={{
                        label: providerIds.name,
                        value: providerIds.pid
                      }}
                      classes={classes}
                      styles={selectStyles}
                      className={classes.providerSelect}
                      loadOptions={this.getOptions('provider')}
                      onChange={this.searchCb('providerIds')}
                    />
                  </span>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <Typography className={classes.mandatory}>权重</Typography>
                  </span>
                  <span className={classes.weighted}>
                    {/* <TextField
                      margin="none"
                      select
                      variant="outlined"
                      value={boutiques}
                      className={classes.gameSelect}
                      onChange={this.setAddInfoHandle('boutiques')}
                      SelectProps={{ classes: { outlined: classes.menu } }}
                    >
                      <MenuItem value={0}>不加</MenuItem>
                      {[
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20,
                        21,
                        22,
                        23,
                        24,
                        25,
                        26,
                        27,
                        28,
                        29,
                        30
                      ].map(item => {
                        return (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        )
                      })}
                    </TextField> */}
                    <span className={classes.inputContent}>
                      <TextField
                        margin="none"
                        variant="outlined"
                        className={classes.gameImg}
                        value={boutiques}
                        onChange={this.setAddInfoHandle('boutiques')}
                        type="text"
                      />
                    </span>
                    <i className={classes.weightedSelect}>若不选，默认不加</i>
                  </span>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <Typography className={classes.mandatory}>
                      显示区域
                    </Typography>
                  </span>
                  <div className={classes.gameTag}>
                    <div className={classes.locationTag}>
                      <TextField
                        margin="none"
                        select
                        variant="outlined"
                        value={areas || []}
                        className={classes.gameTagSelect}
                        onChange={this.setAddInfoHandle('areas')}
                        SelectProps={{
                          classes: { outlined: classes.menu },
                          multiple: true
                        }}
                      >
                        {AREAS.map(({ value, label }) => {
                          return (
                            <MenuItem value={value} key={value}>
                              {label}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className={classes.item}>
                  <span className={classes.itemTitle}>
                    <img
                      className={classes.startImg}
                      src={require('./../../../assets/mandatory.png')}
                    />
                    <Typography className={classes.mandatory}>
                      游戏标签
                    </Typography>
                  </span>
                  <div className={classes.gameTag}>
                    {['ru', 'es'].findIndex(
                      item => item === authInfo.region.code.toLocaleLowerCase()
                    ) > -1 ? (
                      <div className={classes.selectTag}>
                        <TextField
                          margin="none"
                          select
                          variant="outlined"
                          value={tagsFirstValue || 0}
                          className={classes.gameTagSelect}
                          onChange={this.setAddInfoHandle('tagsFirstValue')}
                          SelectProps={{ classes: { outlined: classes.menu } }}
                        >
                          <MenuItem value={0}>please select...</MenuItem>
                          {gameAdd.tagList && !!gameAdd.tagList.length
                            ? gameAdd.tagList.map(item => {
                              return (
                                <MenuItem value={item.name} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              )
                            })
                            : null}
                        </TextField>
                        <TextField
                          margin="none"
                          select
                          variant="outlined"
                          value={tagsSecondValue || 0}
                          className={classes.gameTagSelect}
                          onChange={this.setAddInfoHandle('tagsSecondValue')}
                          SelectProps={{ classes: { outlined: classes.menu } }}
                        >
                          <MenuItem value={0}>please select...</MenuItem>
                          {gameAdd.tagList && !!gameAdd.tagList.length
                            ? gameAdd.tagList.map(item => {
                              return (
                                <MenuItem value={item.name} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              )
                            })
                            : null}
                        </TextField>
                      </div>
                    ) : (
                      <div className={classes.selectTag}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          isMulti
                          isSearchable
                          defaultValue={tags.map(d => {
                            if (TAG_TYPE.findIndex(age => age === d) < 0) {
                              return { label: d, value: d }
                            }
                          })}
                          classes={classes}
                          styles={selectStyles}
                          className={classes.gameTagSelect}
                          loadOptions={this.getOptions('tag')}
                          onChange={this.searchCb('tags')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.btnArea}>
                <Button variant="contained" onClick={handleClose}>
                  取消
                </Button>
                <Button variant="contained" onClick={this.handSaveBtnClick}>
                  保存
                </Button>
              </div>
            </Paper>
          </div>
        </DialogContent>
        {titleSwtch ? (
          <AlertDialog
            open={titleSwtch}
            content={''}
            title={dialogTitle}
            children={dialogChildren}
            handleClose={() => {
              gameAdd.updateAddInfo('title', '')
              gameAdd.updateAddInfo('subTitle', [])
              this.setState({
                titleSwtch: false
              })
            }}
            handleConfirm={this.confirmHandle}
          />
        ) : null}
      </Dialog>
    )
  }
}
GameBatchUp.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(GameBatchUp)

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, MenuItem } from '@material-ui/core'
import DateTimePicker from 'react-datetime'
import AsyncSelect from 'react-select/lib/Async'
import { intl } from '@src/IntlGlobalProvider'

const styles = theme => ({
  root: {
    width: 250,
    minWidth: 250,
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    background: '#fff',
    padding: theme.spacing.unit
  },
  filterHeader: {
    display: 'flex',
    position: 'sticky',
    top: -8,
    zIndex: 999,
    justifyContent: 'space-between',
    borderBottom: '1px solid #999',
    alignItems: 'center',
    height: 50,
    backgroundColor: 'white',
    marginBottom: '10px'
  },
  filterItem: {
    marginBottom: theme.spacing.unit
  },
  titleContainer: {
    height: '21px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1B7F',
    fontWeight: 600
  },
  searchBtn: {
    color: '#5069f5',
    border: '1px solid #5069f5'
  },
  textField: {
    height: 30,
    minHeight: 30,
    '@global': {
      input: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        height: 36
      }
    }
  },
  margin: {
    height: 38,
    width: '100%'
  },
  between: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    width: '100%'
  },
  menu: {
    padding: '11px 8px'
  }
})

const AREAS = [
  { value: 'top_picks', label: 'TOP PICKS' },
  { value: 'new_games', label: 'NEW GAMES' },
  { value: 'favorite_recommend', label: 'FAVORITE RECOMMEND' },
  { value: 'recent_recommend', label: 'RECENT RECOMMEND' },
  { value: 'detail_recommend', label: 'DETAIL RECOMMEND' },
  { value: 'mobile_all', label: 'MOBILE ALL' }
]

@inject('gameFilter', 'gameManage', 'gameAdd')
@observer
class GameFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tagOptions: [],
      providerOptions: []
    }
  }
  componentDidMount () {
    const { gameFilter } = this.props
    gameFilter.resetFilter()
  }
  // 搜索
  searchGames = () => {
    const { gameManage } = this.props
    gameManage.resetPageNum()
    gameManage.getGameList()
  }
  setUpdate = (attr, value) => {
    const { gameFilter, gameManage } = this.props
    gameFilter.updateFilter(attr, value)
    gameManage.resetPageNum()
  }
  // 改变输入框的值
  setFilter = (attr, value) => {
    this.setUpdate(attr, value)
  }
  // 改变inputSearch的值
  handleInputChange = newValue => {
    // const inputValue = newValue.replace(/\W/g, '')
    return newValue
  }
  getOptions = keyValue => async inputValue => {
    if (typeof inputValue === 'object') {
      inputValue = ''
    }
    if (inputValue === '' && keyValue !== 'tag' && keyValue !== 'provider') {
      return
    }
    let res = await this.props.gameAdd.searchFilters(inputValue, keyValue)
    let result = []
    if (res.data.result && res.data.status) {
      if (Array.isArray(res.data.result)) {
        res.data.result.forEach(value => {
          let obj = {}
          if (typeof value === 'object') {
            if (keyValue === 'provider') {
              obj.value = value.pid
              obj.label = value.name
            }
            if (keyValue === 'source') {
              obj.value = value.sid
              obj.label = value.name
            }
          } else {
            obj.value = value
            obj.label = value
          }
          result.push(obj)
        })
      } else {
        res.data.result.list.forEach(value => {
          let obj = {}
          obj.value = value.id
          obj.label = value.name
          result.push(obj)
        })
      }
    }
    return result || []
  }
  searchCb = params => value => {
    let res = ''
    if (params === 'sourceId') {
      res = value.map(d => d.label)
    }
    if (params === 'providerIds') {
      res = value.map(d => d.value)
    }
    if (params === 'tags') {
      res = value.map(d => d.label)
    }
    this.setUpdate(params, res)
  }
  // 下拉选择状态
  handleChangeSelect = params => event => {
    this.setUpdate(params, event.target.value)
  }
  // 选择日期的函数
  handleDateChange = key => date => {
    this.setUpdate(key, new Date(date).getTime())
  }

  render () {
    const { classes, theme, gameFilter } = this.props
    const selectStyles = theme
    return (
      <div className={classes.root}>
        <div className={classes.filterHeader}>
          <Typography variant="h6" style={{ color: '#000' }}>
            Filters
          </Typography>
          <Button className={classes.searchBtn} onClick={this.searchGames}>
            {intl.formatMessage({ id: 'search' })}
          </Button>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Game ID</div>
          <TextField
            margin="none"
            variant="outlined"
            className={classes.textField}
            onChange={event => {
              this.setFilter('docId', event.target.value)
            }}
            type="text"
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Game Name</div>
          <TextField
            margin="none"
            variant="outlined"
            className={classes.textField}
            onChange={event => {
              this.setFilter('title', event.target.value)
            }}
            type="text"
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Publisher</div>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            isSearchable
            classes={classes}
            styles={selectStyles}
            onInputChange={this.handleInputChange}
            loadOptions={this.getOptions('source')}
            onChange={this.searchCb('sourceId')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Provider</div>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            isSearchable
            classes={classes}
            styles={selectStyles}
            onInputChange={this.handleInputChange}
            loadOptions={this.getOptions('provider')}
            onChange={this.searchCb('providerIds')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Tag</div>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            isSearchable
            classes={classes}
            styles={selectStyles}
            onInputChange={this.handleInputChange}
            loadOptions={this.getOptions('tag')}
            onChange={this.searchCb('tags')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Location</div>
          <TextField
            margin="none"
            select
            variant="outlined"
            className={classes.select}
            value={gameFilter.filters.location}
            onChange={this.handleChangeSelect('location')}
            SelectProps={{ classes: { outlined: classes.menu } }}
          >
            <MenuItem value={-1}>All</MenuItem>
            {AREAS.map(({ value, label }) => {
              return (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              )
            })}
          </TextField>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Status</div>
          <TextField
            margin="none"
            select
            variant="outlined"
            className={classes.select}
            value={gameFilter.filters.state}
            onChange={this.handleChangeSelect('state')}
            SelectProps={{ classes: { outlined: classes.menu } }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={1}>Online</MenuItem>
            <MenuItem value={0}>Offline</MenuItem>
          </TextField>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Priority</div>
          <TextField
            margin="none"
            select
            variant="outlined"
            className={classes.select}
            value={gameFilter.filters.boutiques}
            onChange={this.handleChangeSelect('boutiques')}
            SelectProps={{ classes: { outlined: classes.menu } }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>不加</MenuItem>
            {[
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
              29,
              30
            ].map(item => {
              return (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              )
            })}
          </TextField>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Publish Time</div>
          <div className={classes.between}>
            <DateTimePicker
              dateFormat="MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={gameFilter.filters.publishTimeStart}
              onChange={this.handleDateChange('publishTimeStart')}
            />
            <Typography style={{ margin: '0 5px' }}>to</Typography>
            <DateTimePicker
              dateFormat="MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={gameFilter.filters.publishTimeEnd}
              onChange={this.handleDateChange('publishTimeEnd')}
            />
          </div>
        </div>
      </div>
    )
  }
}
GameFilter.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles, { withTheme: true })(GameFilter)

import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, MenuItem } from '@material-ui/core'
import DateTimePicker from 'react-datetime'
import AsyncSelect from 'react-select/lib/Async'

import { intl } from '@src/IntlGlobalProvider'
import commonApi from '@src/models/CommonApi/index.js'

const styles = theme => ({
  root: {
    width: 250,
    minWidth: 250,
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    background: '#fff',
    padding: theme.spacing.unit
  },
  filterHeader: {
    display: 'flex',
    position: 'sticky',
    top: -8,
    zIndex: 999,
    justifyContent: 'space-between',
    borderBottom: '1px solid #999',
    alignItems: 'center',
    height: 50,
    backgroundColor: 'white',
    marginBottom: '10px'
  },
  filterItem: {
    marginBottom: theme.spacing.unit
  },
  titleContainer: {
    height: '21px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1B7F',
    fontWeight: 600
  },
  searchBtn: {
    color: '#5069f5',
    border: '1px solid #5069f5'
  },
  providerSelect: {
    width: '95%'
  },
  textField: {
    height: 30,
    '@global': {
      input: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        height: 36
      }
    }
  },
  margin: {
    height: 38,
    width: '100%'
  },
  between: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    width: '95%'
  },
  menu: {
    padding: '11px 8px'
  }
})

@inject('gameReview')
@observer
class GameFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.timer = null
  }

  componentDidMount () {
    this.props.gameReview.resetFilter()
  }

  searchGames = () => {
    const { gameReview } = this.props
    gameReview.resetPageNum()
    gameReview.getGameList()
  }

  setFilter = (attr, value) => {
    const { gameReview } = this.props
    gameReview.updateFilter(attr, value)
  }

  getOptions = keyValue => async inputValue => {
    console.log('999')
    if (typeof inputValue === 'object') {
      inputValue = ''
    }

    let res = await commonApi.searchFilters({
      type: keyValue,
      name: inputValue
    })
    const result = res.data && res.data.result
    let optionList = []
    if (result) {
      if (Array.isArray(result)) {
        result.forEach(value => {
          let item = {}
          if (typeof value === 'object') {
            item.value = value.sid || value.pid
            item.label = value.name
          } else if (typeof value === 'string') {
            item.value = value
            item.label = value
          }
          optionList.push(item)
        })
      } else {
        result.list.forEach(value => {
          let item = {}
          item.value = value.id
          item.label = value.name
          optionList.push(item)
        })
      }
    }
    return optionList
  }

  updateFilter = attr => value => {
    const { gameReview } = this.props
    let res = ''
    if (attr === 'sourceId') {
      res = value.map(d => d.label)
    }
    if (attr === 'providerIds') {
      res = value.map(d => d.value)
    }
    if (attr === 'tags') {
      res = value.map(d => d.label)
    }
    gameReview.updateFilter(attr, res)
  }

  handleChangeSelect = params => event => {
    const { gameReview } = this.props
    if (params === 'state') {
      gameReview.updateFilter('state', event.target.value)
    }
    if (params === 'location') {
      gameReview.updateFilter('location', event.target.value)
    }
  }

  handleDateChange = key => date => {
    const { gameReview } = this.props
    gameReview.updateFilter(key, new Date(date).getTime())
  }

  render () {
    const { classes, theme, gameReview } = this.props
    const selectStyles = theme
    return (
      <div className={classes.root}>
        <div className={classes.filterHeader}>
          <Typography variant="h6" style={{ color: '#000' }}>
            Filters
          </Typography>
          <Button className={classes.searchBtn} onClick={this.searchGames}>
            {intl.formatMessage({ id: 'search' })}
          </Button>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Game ID</div>
          <TextField
            margin="none"
            variant="outlined"
            className={classes.textField}
            value={gameReview.filters.docId}
            onChange={event => {
              this.setFilter('docId', event.target.value)
            }}
            type="text"
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Game Name</div>
          <TextField
            margin="none"
            variant="outlined"
            className={classes.textField}
            onChange={event => {
              this.setFilter('title', event.target.value)
            }}
            type="text"
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Publisher</div>
          <AsyncSelect
            cacheOptions
            isMulti
            isSearchable
            styles={selectStyles}
            className={classes.providerSelect}
            loadOptions={this.getOptions('source')}
            onChange={this.updateFilter('sourceId')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Provider</div>
          <AsyncSelect
            cacheOptions
            isMulti
            // isSearchable
            styles={selectStyles}
            className={classes.providerSelect}
            loadOptions={this.getOptions('provider')}
            onChange={this.updateFilter('providerIds')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Tag</div>
          <AsyncSelect
            cacheOptions
            isMulti
            isSearchable
            styles={selectStyles}
            className={classes.providerSelect}
            loadOptions={this.getOptions('tag')}
            onChange={this.updateFilter('tags')}
          />
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Location</div>
          <TextField
            margin="none"
            select
            variant="outlined"
            className={classes.select}
            value={gameReview.filters.location}
            onChange={this.handleChangeSelect('location')}
            SelectProps={{ classes: { outlined: classes.menu } }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value="top_picks">TOP PICKS</MenuItem>
            <MenuItem value="new_games">NEW GAMES</MenuItem>
            <MenuItem value="favorite_recommend">FAVORITE RECOMMEND</MenuItem>
            <MenuItem value="recent_recommend">RECENT RECOMMEND</MenuItem>
            <MenuItem value="detail_recommend">DETAIL RECOMMEND</MenuItem>
            <MenuItem value="mobile_all">MOBILE ALL</MenuItem>
          </TextField>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Status</div>
          <TextField
            className={classes.select}
            select
            variant="outlined"
            value={gameReview.filters.state}
            onChange={this.handleChangeSelect('state')}
            SelectProps={{ classes: { outlined: classes.menu } }}
          >
            <MenuItem value={-1}>Select...</MenuItem>
            <MenuItem value={0}>驳回</MenuItem>
            <MenuItem value={1}>未审核</MenuItem>
          </TextField>
        </div>

        <div className={classes.filterItem}>
          <div className={classes.titleContainer}>Publish Time</div>
          <div className={classes.between}>
            <DateTimePicker
              dateFormat="MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={gameReview.filters.publishTimeStart}
              clearable
              onChange={this.handleDateChange('publishTimeStart')}
            />
            <Typography style={{ margin: '0 5px' }}>to</Typography>
            <DateTimePicker
              dateFormat="MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={gameReview.filters.publishTimeEnd}
              onChange={this.handleDateChange('publishTimeEnd')}
            />
          </div>
        </div>
      </div>
    )
  }
}

GameFilter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(GameFilter)

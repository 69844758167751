import { action } from 'mobx'
import { fetchData } from '@src/utils/tools'
import Toast from '@src/components/Toast'

var API = {
  batchOffline: data => {
    return fetchData({
      url: 'util/article/underCarriage',
      data: data,
      type: 'POST'
    })
  }
}

class BatchOffline {
  @action
  offline = async data => {
    let tmp = new FormData()
    tmp.append('file', data)
    let result = await API.batchOffline(tmp)
    if (result.data) {
      if (result.data.status) {
        Toast.success('成功...')
      } else {
        Toast.error('失败...')
      }
    }
  }
}

const batchOffline = new BatchOffline()
export default batchOffline

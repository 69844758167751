const objProto = Object.prototype
const types = [
  'Object',
  'Array',
  'Function',
  'String',
  'Number',
  'Boolean',
  'Undefined',
  'Null',
  'Symbol'
]
/**
 * 判断变量类型的工具函数
 Types.isObject
 Types.isArray
 Types.isFunction
 Types.isString
 Types.isNumber
 Types.isBoolean
 Types.isUndefined
 Types.isNull
 Types.isSymbol
 */
export const Types = types.reduce((utilObj, type) => {
  utilObj[`is${type}`] = function (obj) {
    return objProto.toString.call(obj) === `[object ${type}]`
  }
  return utilObj
}, {})

export function noop () {}

export function hasOwn (obj, key) {
  return objProto.hasOwnProperty.call(obj, key)
}

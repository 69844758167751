import React from 'react'
import PropTypes from 'prop-types'
import { searchItem } from '@src/utils/tools'

import './SecondaryDropdown.css'

let path = ''

class MultiMenu extends React.Component {
  constructor (props) {
    super(props)
  }

  handleChange = (event, menu) => {
    if (menu.submenus && !path) {
      return
    }

    let contentType = menu.name
    if (!path) {
      path = menu.path
    }

    let result = searchItem(this.props.initMenus.submenus, {name: contentType})
    if (result.item) {
      this.props.ChangeHandle(contentType, path)
      path = ''
    }
  }

  render () {
    const { menus, ChangeHandle, initMenus } = this.props

    return (
      <ul className={menus.className}>
        {
          menus.submenus.map((menu) => {
            return (
              menu.hasOwnProperty('hide') && menu['hide']
                ? null
                : <li
                    key={menu.name}
                    className="slj-menu-item"
                    onClick={(e) => {this.handleChange(e, menu)}}
                  >
                    {menu.name}
                    {
                      menu.submenus && menu.submenus.length
                        && <MultiMenu initMenus={initMenus} menus={menu} ChangeHandle={ChangeHandle} />
                    }
                  </li>
            )
          })
        }
      </ul>
    )
  }
}

class SecondaryDropdown extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <MultiMenu initMenus={this.props.menus} menus={this.props.menus} ChangeHandle={this.props.ChangeHandle} />
    )
  }
}

SecondaryDropdown.propTypes = {
  menus: PropTypes.object.isRequired,
  ChangeHandle: PropTypes.func.isRequired
}

export default SecondaryDropdown

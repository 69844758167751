import { observable, action } from 'mobx'
import { fetchData } from '../../utils/tools'
import authInfo from '../authInfo'
import contentManage from './ContentManage'
class VideoFilterAPI {
  searchFilters = data => {
    return fetchData({
      url:
        `${data.type}/match?name=${encodeURIComponent(data.name)}` +
        `&region=${
          authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}
        &pn=1&ps=50`,
      method: 'GET'
    })
  }
  searchEditorCategory = data => {
    return fetchData({
      url:
        `${data.type}/edit/match?name=${data.name}` +
        `&region=${
          authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}
        &pn=1&ps=50`,
      method: 'GET'
    })
  }
  searchKeywordsFilters = data => {
    return fetchData({
      url:
        `tag/match/keywords?name=` + encodeURIComponent(data) + `&pn=1&ps=50`,
      method: 'GET'
    })
  }
  searchPlayListNameFilters = (type, data) => {
    return fetchData({
      url:
        `tag/match/${type}?name=${encodeURIComponent(data)}` +
        `&region=${
          authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}
      &pn=1&ps=50`,
      method: 'GET'
    })
  }
  searchKeywords = name => {
    return fetchData({
      url: `tag/match/keywords?name=${name}&pn=1&ps=50`,
      method: 'GET'
    })
  }
  searchFiltersEdit = data => {
    return fetchData({
      url:
        `${data.type}/edit/match?name=${data.name}` +
        `&region=${
          authInfo.region.code
        }&type=${contentManage.contentType.toUpperCase()}`,
      method: 'GET'
    })
  }
  getCountryLanguage = data => {
    return fetchData({
      url: `region/language/list?region=${data}`,
      method: 'GET'
    })
  }
}

const filters = {
  docId: '',
  title: '',
  sources: [],
  lans: [],
  playUrl: '',
  url: '',
  publishTimeStart: null,
  publishTimeEnd: null,
  storeTimeStart: null,
  storeTimeEnd: null,
  updateTimeStart: null,
  updateTimeEnd: null,
  cpCates: [],
  cpTags: [],
  cates: [],
  tags: [],
  providerIds: [],
  ad: -1,
  keywords: [],
  hashTags: [],
  playlistName: [],
  minDuration: '',
  maxDuration: '',
  minCpClick: '',
  maxCpClick: '',
  minClick: '',
  maxClick: '',
  minViews: '',
  maxViews: '',
  minLikes: '',
  maxLikes: '',
  minShare: '',
  maxShare: '',
  minLikeRate: '',
  maxLikeRate: '',
  minFinishRate: '',
  maxFinishRate: '',
  minDownloads: '',
  maxDownloads: '',
  sortType: 'DESC',
  sortField: 'publishTime',
  boutiques: -1,
  editor: '',
  searchType: 'and',
  excludeSearchType: 'or',
  excludeTags: [],
  tagStatus: -1,
  cpName: 'all',
  categoryType: 'all',
  sourceId: [],
  osid: ''
}

class VideoFilter {
  constructor (api) {
    this.videoFilterAPI = api
  }

  @observable
  browserFilters = Object.assign({}, filters, {
    edit: 0,
    style: 0,
    language: 'en',
    state: 1,
    pornDegree: 0,
    originPornDegree: 0,
    ctrState: -1,
    auditType: -1
  })

  @observable
  languages = []

  @action
  updateFilter = (attr, value) => {
    this.browserFilters[attr] = value
  }

  @action
  searchFilters = (name, type, filter) => {
    if (type === 'keywords') {
      return this.videoFilterAPI.searchKeywordsFilters(name, type)
    }
    if (type === 'playlistName' || type === 'hashTags') {
      if (type === 'playlistName') {
        type = 'playlist'
      } else {
        type = 'hashtags'
      }
      return this.videoFilterAPI.searchPlayListNameFilters(type, name)
    }
    if (type === 'category' && filter === 'cates') {
      return this.videoFilterAPI.searchEditorCategory({ name, type })
    }
    return this.videoFilterAPI.searchFilters({ name, type })
  }

  @action
  searchKeywords = name => {
    return this.videoFilterAPI.searchKeywords(name)
  }

  @action
  searchFiltersEdit = (name, type) => {
    return this.videoFilterAPI.searchFiltersEdit({ name, type })
  }

  @action
  getLanguages = async () => {
    return this.videoFilterAPI.getLanguage()
  }

  @action
  getCountryLanguage = async () => {
    let data = {
      region: authInfo.region.code
    }
    let result = await this.videoFilterAPI.getCountryLanguage(data.region)
    if (!!result.data && result.data.status) {
      let arr = []
      result.data.result.forEach(element => {
        let datas = {
          value: element,
          label: element
        }
        arr.push(datas)
      })
      arr.unshift(
        { value: 'all', label: 'All' },
        { value: 'others', label: 'Others' }
      )
      this.languages = arr
    }
  }

  @action
  resetFilters = () => {
    this.browserFilters = Object.assign({}, filters, {
      edit: 0,
      style: 0,
      language: 'en',
      state: 1,
      pornDegree: 0,
      originPornDegree: 0,
      ctrState: -1,
      auditType: -1
    })
  }

  @action
  emptyFilters = () => {
    this.browserFilters = Object.assign({}, filters, {
      edit: -1,
      style: -1,
      state: -1,
      language: '',
      pornDegree: 0,
      originPornDegree: 0,
      ctrState: -1,
      auditType: -1
    })
  }
}

const videoFilterAPI = new VideoFilterAPI()
const videoFilter = new VideoFilter(videoFilterAPI)

export default videoFilter
